import React, { Fragment, useState } from "react";
import { Row, Col, Nav, Tab, Modal, Button, Alert } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

//My Components
import { LanguageContext } from "../../contexts/LanguageProvider";

export const HowToModal = (props) => {
    const handleClose = () => setShow(false);
    const [show, setShow] = useState(false);

    return (
        <Fragment>
            <Button className="link" variant="primary" onClick={() => setShow(true)}>
                <FormattedMessage id="howToModal.howToInstall" defaultMessage="How to install" />
            </Button>
            <Modal show={show || props.show} onHide={() => setShow(false)} dialogClassName="w-75" aria-labelledby="how-to-install-modal" centered>
                <Button className="modal-button" onClick={handleClose}>
                    <i className="far fa-times"></i>
                </Button>

                <Modal.Body>
                    <h1 className="mt-3 text-center">
                        <FormattedMessage id="howToModal.howToInstallsubHeader" defaultMessage="How to Install E-signature" />
                        <span className="dot"></span>
                    </h1>

                    <LanguageContext.Consumer>
                        {(language) => (
                            <Tab.Container id="how-to-modal" defaultActiveKey="windows">
                                <Row>
                                    <Col sm={{ span: 10, offset: 1 }}>
                                        <Alert variant="info">
                                            <FormattedMessage id="howToModal.AssistanceMessage" defaultMessage="Still need assistance with getting your email signature on your email client?" />

                                            <a
                                                href={
                                                    language.locale === "fr"
                                                        ? "https://support.dominionlending.ca/hc/fr/requests/new?ticket_form_id=360002825014"
                                                        : "https://support.dominionlending.ca/hc/en-us/requests/new?ticket_form_id=360002825014"
                                                }
                                                className="float-right"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <FormattedMessage id="howToModal.submitaRequest" defaultMessage="Submit a request" />
                                            </a>
                                        </Alert>
                                        <Nav variant="pills" justify className="mt-5">
                                            <Nav.Item>
                                                <Nav.Link eventKey="windows">Windows</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="mac">Mac</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="web">Web</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="iphone">iPhone</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="android">Android</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Col>
                                    <Col sm={{ span: 10, offset: 1 }} className="mt-4">
                                        <Tab.Content>
                                            <Tab.Pane eventKey="windows">
                                                <Row>
                                                    <Col sm={4} className="text-center">
                                                        <a
                                                            className="email-client-box"
                                                            href={
                                                                language.locale === "fr"
                                                                    ? "https://support.dominionlending.ca/hc/fr/articles/360061769574"
                                                                    : "https://support.dominionlending.ca/hc/en-us/articles/360063299533"
                                                            }
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <img src={require("../../images/outlook-2019.svg").default} alt="Outlook 2019" />
                                                            <span>Outlook 2019/2016</span>
                                                        </a>
                                                    </Col>

                                                    <Col sm={4} className="text-center">
                                                        <a
                                                            className="email-client-box"
                                                            href={
                                                                language.locale === "fr"
                                                                    ? "https://support.dominionlending.ca/hc/fr/articles/360061769614"
                                                                    : "https://support.dominionlending.ca/hc/en-us/articles/360063299593"
                                                            }
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <img src={require("../../images/outlook-2016.svg").default} alt="Outlook 2013" />
                                                            <span>Outlook 2013</span>
                                                        </a>
                                                    </Col>

                                                    <Col sm={4} className="text-center">
                                                        <a
                                                            className="email-client-box"
                                                            href={
                                                                language.locale === "fr"
                                                                    ? "https://support.dominionlending.ca/hc/fr/articles/360061769654"
                                                                    : "https://support.dominionlending.ca/hc/en-us/articles/360063299693"
                                                            }
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <img src={require("../../images/thunderbird-mail.svg").default} alt="Thunderbird" />
                                                            <span>Thunderbird</span>
                                                        </a>
                                                    </Col>
                                                </Row>
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="mac">
                                                <Row>
                                                    <Col sm={4} className="text-center">
                                                        <a
                                                            className="email-client-box"
                                                            href={
                                                                language.locale === "fr"
                                                                    ? "https://support.dominionlending.ca/hc/fr/articles/1500003308302"
                                                                    : "https://support.dominionlending.ca/hc/en-us/articles/1500002768961"
                                                            }
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <img src={require("../../images/apple-mail.svg").default} alt="Apple Mail" />
                                                            <span>Apple Mail</span>
                                                        </a>
                                                    </Col>

                                                    <Col sm={4} className="text-center">
                                                        <a
                                                            className="email-client-box"
                                                            href={
                                                                language.locale === "fr"
                                                                    ? "https://support.dominionlending.ca/hc/fr/articles/1500003308342"
                                                                    : "https://support.dominionlending.ca/hc/en-us/articles/1500002926562"
                                                            }
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <img src={require("../../images/outlook-2019.svg").default} alt="Outlook" />
                                                            <span>Outlook</span>
                                                        </a>
                                                    </Col>

                                                    <Col sm={4} className="text-center">
                                                        <a
                                                            className="email-client-box"
                                                            href={
                                                                language.locale === "fr"
                                                                    ? "https://support.dominionlending.ca/hc/fr/articles/360061769734"
                                                                    : "https://support.dominionlending.ca/hc/en-us/articles/1500002769621"
                                                            }
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <img src={require("../../images/thunderbird-mail.svg").default} alt="Thunderbird" />
                                                            <span>Thunderbird</span>
                                                        </a>
                                                    </Col>
                                                </Row>
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="web">
                                                <Row>
                                                    <Col sm={4} className="text-center">
                                                        <a
                                                            className="email-client-box"
                                                            href={
                                                                language.locale === "fr"
                                                                    ? "https://support.dominionlending.ca/hc/fr/articles/1500003172321"
                                                                    : "https://support.dominionlending.ca/hc/en-us/articles/360061387634"
                                                            }
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <img src={require("../../images/rackspace-mail.svg").default} alt="Rackspace Webmail" />
                                                            <span>Rackspace Webmail</span>
                                                        </a>
                                                    </Col>

                                                    <Col sm={4} className="text-center">
                                                        <a
                                                            className="email-client-box"
                                                            href={
                                                                language.locale === "fr"
                                                                    ? "https://support.dominionlending.ca/hc/fr/articles/360063590873"
                                                                    : "https://support.dominionlending.ca/hc/en-us/articles/360063203273"
                                                            }
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <img src={require("../../images/gmail.svg").default} alt="Gmail" />
                                                            <span>Gmail Webmail</span>
                                                        </a>
                                                    </Col>

                                                    <Col sm={4} className="text-center">
                                                        <a
                                                            className="email-client-box"
                                                            href={
                                                                language.locale === "fr"
                                                                    ? "https://support.dominionlending.ca/hc/fr/articles/360061769854"
                                                                    : "https://support.dominionlending.ca/hc/en-us/articles/360061388474"
                                                            }
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <img src={require("../../images/outlook-2019.svg").default} alt="Outlook Live Webmail" />
                                                            <span>Outlook Webmail</span>
                                                        </a>
                                                    </Col>
                                                </Row>
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="iphone">
                                                <Row>
                                                    <Col sm={4} className="text-center">
                                                        <a
                                                            className="email-client-box"
                                                            href={
                                                                language.locale === "fr"
                                                                    ? "https://support.dominionlending.ca/hc/fr/articles/360063590893"
                                                                    : "https://support.dominionlending.ca/hc/en-us/articles/360061469614"
                                                            }
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <img src={require("../../images/apple-mail.svg").default} alt="Apple Mail" />
                                                            <span>Apple Mail</span>
                                                        </a>
                                                    </Col>

                                                    <Col sm={4} className="text-center">
                                                        <a
                                                            className="email-client-box"
                                                            href={
                                                                language.locale === "fr"
                                                                    ? "https://support.dominionlending.ca/hc/fr/articles/1500003308422"
                                                                    : "https://support.dominionlending.ca/hc/en-us/articles/1500003020442"
                                                            }
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <img src={require("../../images/gmail.svg").default} alt="Gmail" />
                                                            <span>Gmail</span>
                                                        </a>
                                                    </Col>

                                                    <Col sm={4} className="text-center">
                                                        <a
                                                            className="email-client-box"
                                                            href={
                                                                language.locale === "fr"
                                                                    ? "https://support.dominionlending.ca/hc/fr/articles/1500003308462"
                                                                    : "https://support.dominionlending.ca/hc/en-us/articles/360063299733"
                                                            }
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <img src={require("../../images/outlook-2019.svg").default} alt="Outlook" />
                                                            <span>Outlook</span>
                                                        </a>
                                                    </Col>
                                                </Row>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="android">
                                                <Row>
                                                    <Col sm={4} className="text-center">
                                                        <a
                                                            className="email-client-box"
                                                            href={
                                                                language.locale === "fr"
                                                                    ? "https://support.dominionlending.ca/hc/fr/articles/1500003308422"
                                                                    : "https://support.dominionlending.ca/hc/en-us/articles/1500003020442"
                                                            }
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <img src={require("../../images/gmail.svg").default} alt="Gmail" />
                                                            <span>Gmail</span>
                                                        </a>
                                                    </Col>

                                                    <Col sm={4} className="text-center">
                                                        <a
                                                            className="email-client-box"
                                                            href={
                                                                language.locale === "fr"
                                                                    ? "https://support.dominionlending.ca/hc/fr/articles/1500003308462"
                                                                    : "https://support.dominionlending.ca/hc/en-us/articles/360063299733"
                                                            }
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <img src={require("../../images/outlook-2019.svg").default} alt="Outlook" />
                                                            <span>Outlook</span>
                                                        </a>
                                                    </Col>
                                                </Row>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        )}
                    </LanguageContext.Consumer>
                </Modal.Body>
            </Modal>
        </Fragment>
    );
};

export default HowToModal;
