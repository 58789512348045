import React, { Component } from "react";
import { Row, Col, Dropdown } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

//My Components
import { LanguageContext } from "../contexts/LanguageProvider";
import LanguageSwitch from "./utilities/LanguageSwitch";
import MainNav from "./navs/MainNav";
import TemplateContext from "../contexts/TemplateContext";
import UserContext from "../contexts/UserContext";
import DefaultUserProfileImage from "../images/user-profile.svg";

class Header extends Component {
    static contextType = TemplateContext;

    constructor(props) {
        super(props);
        this.state = { isToggleOn: true };

        this.mobileModalClick = this.mobileModalClick.bind(this);
    }

    mobileModalClick() {
        this.setState((state) => ({
            isToggleOn: !state.isToggleOn,
        }));
    }

    render() {
        return (
            <UserContext.Consumer>
                {({ user }) => (
                    <header id="header" className={this.state.isToggleOn ? "offset-lg-3 col-lg-9" : "offset-lg-3 col-lg-9 mobile-modal"}>
                        <Row>
                            <Col>
                                <MainNav componentClass="d-lg-none" />
                            </Col>
                            <LanguageContext.Consumer>
                                {(language) => (
                                    <Dropdown onClick={this.mobileModalClick}>
                                        <Dropdown.Toggle
                                            id="user-menu"
                                            className={user.type === "franchise-user" ? "user-profile franchise" : "user-profile"}
                                            style={{ background: user.info.profileImageUrl ? `url(${user.info.profileImageUrl})` : `url(${DefaultUserProfileImage})` }}
                                        ></Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Row>
                                                <Col lg="4" className="d-lg-none">
                                                    <div
                                                        className={user.type === "franchise-user" ? "user-profile franchise" : "user-profile"}
                                                        style={{ background: user.info.profileImageUrl ? `url(${user.info.profileImageUrl})` : `url(${DefaultUserProfileImage})` }}
                                                    ></div>
                                                </Col>

                                                <Col lg="12">
                                                    <h5>{user.type === "franchise-user" ? user.info.name : user.info.fullName}</h5>
                                                    {(user.type === "broker-user" || user.type === "head-office-user") && <span>{user.info.jobTitle}</span>}
                                                </Col>
                                            </Row>
                                            <Dropdown.Item
                                                href={language.locale === "fr" ? "https://support.dominionlending.ca/hc/fr" : "https://support.dominionlending.ca/hc/en-us"}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <FormattedMessage id="header.supportMenuItem" defaultMessage="FAQ/Support" />
                                            </Dropdown.Item>
                                            <LanguageSwitch />
                                            <Dropdown.Item
                                                href={language.locale === "fr" ? "https://secure.dominionintranet.ca/bienvenue/" : "https://secure.dominionintranet.ca/welcome/"}
                                                rel="noopener noreferrer"
                                            >
                                                <FormattedMessage id="header.backToIntranetMenuItem" defaultMessage="Back to Intranet" />
                                                <i className="fas fa-arrow-to-right ml-2" />
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                )}
                            </LanguageContext.Consumer>
                        </Row>
                    </header>
                )}
            </UserContext.Consumer>
        );
    }
}

export default Header;
