import React, { useState } from "react";
import { IntlProvider } from "react-intl";
import English from "../lang/en.json";
import French from "../lang/fr.json";
import UserContext from "../contexts/UserContext";

export const LanguageContext = React.createContext();

export const LanguageProvider = (props) => {
    const local = window.location.href.indexOf("/fr/") > -1 || navigator.language in ["fr", "fr-CA", "fr-FR"] ? "fr" : "en";
    //const local = navigator.language in ["fr", "fr-CA", "fr-FR"] ? "fr" : "en";

    let lang;
    if (local === "fr") {
        lang = French;
    } else {
        lang = English;
    }

    const [locale, setLocale] = useState(local);
    const [messages, setMessages] = useState(lang);

    function selectLang(e) {
        const newLocale = e.target.value;
        setLocale(newLocale);
        if (newLocale === "fr") {
            setMessages(French);
        } else {
            setMessages(English);
        }
    }

    return (
        <UserContext.Consumer>
            {({ user, updateUser }) => (
                <LanguageContext.Provider value={{ locale, selectLang }}>
                    <IntlProvider messages={messages} locale={locale} defaultLocale="en">
                        {props.children}
                    </IntlProvider>
                </LanguageContext.Provider>
            )}
        </UserContext.Consumer>
    );
};

export default LanguageProvider;
