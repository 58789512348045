import React, { Component } from "react";
import { Nav } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

//My Components
import UserContext from "../../contexts/UserContext";
import TemplateContext from "../../contexts/TemplateContext";
import { LanguageContext } from "../../contexts/LanguageProvider";
import initializeTemplate from "../utilities/TemplateInitialization";
import { TemplateTypesImagesEn } from "../utilities/TemplateTypes";
import { TemplateTypesImagesFr } from "../utilities/TemplateTypes";

class TemplatesNav extends Component {
    static contextType = TemplateContext;

    handleDefaultTemplateSelect(templateType, user) {
        let { template, updateTemplate } = this.context;
        template = initializeTemplate(user, templateType, template);
        updateTemplate(template);
    }

    render() {
        return (
            <LanguageContext.Consumer>
                {(language) => (
                    <UserContext.Consumer>
                        {({ user, updateUser }) => (
                            <Nav
                                id="user-templates-nav"
                                className="flex-column"
                                defaultActiveKey={user.type === "franchise-user" ? "FranchiseTemplateOne" : "BrokerTemplateOne"}
                                onSelect={(templateType) => this.handleDefaultTemplateSelect(templateType, user)}
                            >
                                {user.type === "franchise-user"
                                    ? ["FranchiseTemplateOne", "FranchiseTemplateTwo", "FranchiseTemplateFour"].map((templateType) => (
                                          <Nav.Item key={templateType}>
                                              <Nav.Link eventKey={templateType}>
                                                  <div className="w-100 email-signature-template">
                                                      <img
                                                          src={language.locale === "fr" ? TemplateTypesImagesFr[templateType] : TemplateTypesImagesEn[templateType]}
                                                          alt="Franchise Template Preview"
                                                      />
                                                      <i className="fas fa-check-circle" />
                                                      <span>
                                                          <FormattedMessage id="templateNav.customTemplate" defaultMessage="Custom Template" />
                                                      </span>
                                                  </div>
                                              </Nav.Link>
                                          </Nav.Item>
                                      ))
                                    : null}

                                {user.type === "broker-user"
                                    ? ["BrokerTemplateOne", "BrokerTemplateTwo", "BrokerTemplateThree"].map((templateType) => (
                                          <Nav.Item key={templateType}>
                                              <Nav.Link eventKey={templateType}>
                                                  <div className="w-100 email-signature-template">
                                                      <img src={language.locale === "fr" ? TemplateTypesImagesFr[templateType] : TemplateTypesImagesEn[templateType]} alt="Broker Template Preview" />
                                                      <i className="fas fa-check-circle" />
                                                  </div>
                                              </Nav.Link>
                                          </Nav.Item>
                                      ))
                                    : null}

                                {user.type === "head-office-user"
                                    ? ["HeadOfficeTemplate"].map((templateType) => (
                                          <Nav.Item key={templateType}>
                                              <Nav.Link eventKey={templateType}>
                                                  <div className="w-100 email-signature-template">
                                                      <img
                                                          src={language.locale === "fr" ? TemplateTypesImagesFr[templateType] : TemplateTypesImagesEn[templateType]}
                                                          alt="Head Office Template One Preview"
                                                      />
                                                      <i className="fas fa-check-circle" />
                                                  </div>
                                              </Nav.Link>
                                          </Nav.Item>
                                      ))
                                    : null}
                            </Nav>
                        )}
                    </UserContext.Consumer>
                )}
            </LanguageContext.Consumer>
        );
    }
}

export default TemplatesNav;
