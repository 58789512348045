import React, { useContext, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDropzone } from "react-dropzone";
import axios from "axios";
import imageCompression from "browser-image-compression";
import { getCurrentTimeStr } from "./utilities/HelperFunctions";
import TemplateContext from "../contexts/TemplateContext";
import UserContext from "../contexts/UserContext";

function UploadBanner(props) {
    const [errorMsg, setErrorMsg] = useState("");
    const { user } = useContext(UserContext);
    const { template, updateTemplate } = useContext(TemplateContext);
    const { getRootProps, getInputProps, open } = useDropzone({
        accept: "image/jpeg, image/png, image/jpg",
        noClick: true,
        noKeyboard: true,
        maxSize: 5000000,
        maxFiles: 1,
        onDrop: (acceptedFiles, fileRejections) => {
            if (fileRejections.length > 0) {
                fileRejections.forEach((file) => {
                    file.errors.forEach((error) => {
                        setErrorMsg(error.message);
                    });
                });
                return false;
            }
            setErrorMsg("");

            const handleImageUpload = async () => {
                const imageFile = acceptedFiles[0];
                console.log("originalFile instanceof Blob", imageFile instanceof Blob);
                console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

                const options = {
                    maxSizeMB: 5,
                    maxWidthOrHeight: 1300,
                    useWebWorker: true,
                };
                try {
                    const compressedFile = await imageCompression(imageFile, options);
                    console.log("compressedFile instanceof Blob", compressedFile instanceof Blob);
                    console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);

                    await StoreFile(compressedFile);
                } catch (error) {
                    console.log(error);
                }
            };

            handleImageUpload();
        },
    });

    const StoreFile = (file) => {
        let url = process.env.REACT_APP_BACKEND_URL + "/signature/template/image/upload";
        const dateStr = getCurrentTimeStr();
        const imageType = props.imageType;
        let imageTitle = "";
        if (imageType === "bannerUrl") {
            imageTitle = "banner";
        } else {
            setErrorMsg("Invalid image type!");
            return;
        }
        const imageName = [imageTitle.replace(/ /g, "-"), props.imageType, user.type, user.info.id, template.id, dateStr].join("-");
        const formData = new FormData();
        formData.append("image", file);
        formData.append("imageName", imageName);
        axios({
            url: url,
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data: formData,
        })
            .then((response) => {
                if (response.data.error) {
                    for (const field in response.data.error) {
                        setErrorMsg(response.data.error[field]);
                    }
                } else {
                    if (user.type === "franchise-user" && !["logoUrl", "bannerUrl"].includes(imageType)) {
                        template.member[imageType] = response.data;
                    } else {
                        template.features[imageType] = response.data;
                    }
                    updateTemplate(template);
                }
            })
            .catch((error) => {
                console.log(error);
                setErrorMsg("Upload failed, Please try again");
            });
    };

    return (
        <section className="dropzone-container mb-4">
            <div {...getRootProps({ className: "dropzone" })}>
                <input {...getInputProps()} />
                <p>
                    <FormattedMessage id="upload.dropzoneLabel" defaultMessage="Drop image here" />
                    <span className="text-center">
                        <FormattedMessage id="upload.orLabel" defaultMessage="or" />
                    </span>
                </p>
                <button type="button" className="btn btn-primary" onClick={open}>
                    <FormattedMessage id="upload.dropzoneButton" defaultMessage="Select File" />
                </button>
            </div>
            <div className={errorMsg ? "mt-3 alert alert-danger" : "alert-danger"}>{errorMsg}</div>
        </section>
    );
}

export default UploadBanner;
