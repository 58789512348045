import React, { Fragment, useContext } from "react";
import TemplateContext from "../../../contexts/TemplateContext";
import UserContext from "../../../contexts/UserContext";
import { LanguageContext } from "../../../contexts/LanguageProvider";

export const FranchiseTemplateOne = () => {
    const { template } = useContext(TemplateContext);
    const { user } = useContext(UserContext);
    const features = template.features;
    const franchiseLogo = user.type === "broker-user" ? features.franchise.logoUrl : features.logoUrl;
    const franchiseWebsite = user.type === "broker-user" ? features.franchise.website : features.website;
    const franchiseLicenseNumber = user.type === "broker-user" ? user.info.franchise.licenseNumber : user.info.licenseNumber;
    const member = user.type === "broker-user" ? features : template.member;
    //const colorScheme = features.franchise ? features.franchise.colorScheme ?? {} : {};
    let colorScheme = null;
    if (user.type === "broker-user") {
        colorScheme = features.franchise.colorScheme;
    } else {
        colorScheme = features.colorScheme;
    }

    return (
        <Fragment>
            <LanguageContext.Consumer>
                {(language) => (
                    <table
                        id="main-table"
                        cellSpacing="0"
                        cellPadding="0"
                        border="0"
                        width="650"
                        style={{
                            margin: "0 !important",
                            padding: "0",
                            borderCollapse: "collapse",
                            msoTableLspace: "0pt !important",
                            msoTableRspace: "0pt !important",
                            lineHeight: "100%",
                            wordBreak: "break-word",
                        }}
                    >
                        <tbody>
                            <tr
                                style={{
                                    borderSpacing: "0 !important",
                                    padding: "0 !important",
                                    margin: "0 !important",
                                }}
                            >
                                <td
                                    width="650"
                                    style={{
                                        margin: "0",
                                        padding: "0",
                                        msoTableLspace: "0pt !important",
                                        msoTableRspace: "0pt !important",
                                    }}
                                >
                                    <table
                                        cellSpacing="0"
                                        cellPadding="0"
                                        border="0"
                                        width="650"
                                        bgcolor={colorScheme.secondary ? colorScheme.secondary : "#ffffff"}
                                        style={{
                                            margin: "0 !important",
                                            padding: "0 !important",
                                            background: `${colorScheme.secondary ? colorScheme.secondary : "#ffffff"}`,
                                            borderCollapse: "collapse",
                                            msoTableLspace: "0pt !important",
                                            msoTableRspace: "0pt !important",
                                        }}
                                    >
                                        <tbody>
                                            <tr
                                                style={{
                                                    borderSpacing: "0 !important",
                                                    padding: "0 !important",
                                                    margin: "0 !important",
                                                }}
                                            >
                                                <td
                                                    valign="top"
                                                    width="325"
                                                    bgcolor={colorScheme.secondary ? colorScheme.secondary : "#ffffff"}
                                                    style={{
                                                        margin: "0",
                                                        padding: "0",
                                                        msoTableLspace: "0pt !important",
                                                        msoTableRspace: "0pt !important",
                                                    }}
                                                >
                                                    <table
                                                        cellSpacing="7"
                                                        cellPadding="0"
                                                        border="0"
                                                        width="325"
                                                        bgcolor={colorScheme.secondary ? colorScheme.secondary : "#ffffff"}
                                                        style={{
                                                            margin: "0 !important",
                                                            borderCollapse: "separate",
                                                            msoTableLspace: "0pt !important",
                                                            msoTableRspace: "0pt !important",
                                                        }}
                                                    >
                                                        <tbody>
                                                            <tr
                                                                style={{
                                                                    borderSpacing: "0 !important",
                                                                    padding: "0 !important",
                                                                    margin: "0 !important",
                                                                }}
                                                            >
                                                                {member.profileImageUrl || member.awardImageUrl ? (
                                                                    <td
                                                                        valign="top"
                                                                        width="60"
                                                                        style={{
                                                                            verticalAlign: "top",
                                                                            margin: "0",
                                                                            padding: "0",
                                                                            msoTableLspace: "0pt !important",
                                                                            msoTableRspace: "0pt !important",
                                                                        }}
                                                                    >
                                                                        {member.profileImageUrl ? (
                                                                            <p
                                                                                className="MsoNormal"
                                                                                style={{
                                                                                    padding: "0 !important",
                                                                                    msoStyleUnhide: "no",
                                                                                    msoStyleQformat: "yes",
                                                                                    msoStyleParent: "",
                                                                                    margin: "0cm",
                                                                                    marginBottom: ".0001pt",
                                                                                    msoPagination: "widow-orphan",
                                                                                    msoBidiThemeFont: "minor-bidi",
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={member.profileImageUrl}
                                                                                    width="60"
                                                                                    border="0"
                                                                                    alt={member.fullName ? member.fullName : language.locale === "fr" ? "Nommé" : "Full Name"}
                                                                                    style={{
                                                                                        margin: "0",
                                                                                        display: "block",
                                                                                        outline: "none",
                                                                                        textDecoration: "none",
                                                                                        MsInterpolationMode: "bicubic",
                                                                                        verticalAlign: "middle",
                                                                                    }}
                                                                                />
                                                                            </p>
                                                                        ) : null}
                                                                        {member.awardImageUrl ? (
                                                                            <p
                                                                                className="MsoNormal"
                                                                                style={{
                                                                                    margin: member.profileImageUrl ? "5px 0 0 0" : "0",
                                                                                    padding: "0 !important",
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={member.awardImageUrl}
                                                                                    width="60"
                                                                                    border="0"
                                                                                    alt={language.locale === "fr" ? "Prix du Courtier" : "Broker Award"}
                                                                                    style={{
                                                                                        margin: member.profileImageUrl ? "5px 0 0 0" : "0",
                                                                                        display: "block",
                                                                                        outline: "none",
                                                                                        textDecoration: "none",
                                                                                        MsInterpolationMode: "bicubic",
                                                                                        verticalAlign: "middle",
                                                                                    }}
                                                                                />
                                                                            </p>
                                                                        ) : null}
                                                                    </td>
                                                                ) : null}

                                                                <td
                                                                    valign="top"
                                                                    width="245"
                                                                    style={{
                                                                        margin: "0",
                                                                        padding: "0",
                                                                        verticalAlign: "top",
                                                                        msoTableLspace: "0pt !important",
                                                                        msoTableRspace: "0pt !important",
                                                                    }}
                                                                >
                                                                    <p
                                                                        className="MsoNormal"
                                                                        style={{
                                                                            fontSize: "12pt",
                                                                            fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                            color: `${colorScheme.headers ? colorScheme.headers : "#005baa"}`,
                                                                            textTransform: "capitalize",
                                                                            padding: "0 !important",
                                                                            fontWeight: "900",
                                                                            msoStyleUnhide: "no",
                                                                            msoStyleQformat: "yes",
                                                                            msoStyleParent: "",
                                                                            margin: "0cm",
                                                                            marginBottom: ".0001pt",
                                                                            msoPagination: "widow-orphan",
                                                                            msoBidiThemeFont: "minor-bidi",
                                                                        }}
                                                                    >
                                                                        <b>{member.fullName ? member.fullName : language.locale === "fr" ? "Nommé" : "Full Name"}</b>
                                                                        <br />
                                                                        <b
                                                                            style={{
                                                                                fontSize: "10pt",
                                                                                fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                                color: `${colorScheme.text ? colorScheme.text : "#1f1f1f"}`,
                                                                                margin: "0",
                                                                                fontWeight: "normal",
                                                                            }}
                                                                        >
                                                                            {member.jobTitle ? member.jobTitle : language.locale === "fr" ? "Titre du Poste" : "Position Title"}
                                                                        </b>

                                                                        {member.licenseNumber ? (
                                                                            <Fragment>
                                                                                <br />
                                                                                <b
                                                                                    style={{
                                                                                        fontSize: "8.5pt",
                                                                                        fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                                        color: `${colorScheme.text ? colorScheme.text : "#1f1f1f"}`,
                                                                                        margin: "0",
                                                                                        fontWeight: "900",
                                                                                    }}
                                                                                >
                                                                                    {language.locale === "fr" ? "Permis " : "License "}
                                                                                    {member.licenseNumber}
                                                                                </b>
                                                                            </Fragment>
                                                                        ) : null}
                                                                    </p>

                                                                    {member.phone || member.mobile || member.fax || member.id !== "" ? (
                                                                        <p
                                                                            className="MsoNormal"
                                                                            style={{
                                                                                fontSize: "8.5pt",
                                                                                fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                                color: `${colorScheme.text ? colorScheme.text : "#1f1f1f"}`,
                                                                                padding: "0 !important",
                                                                                msoStyleUnhide: "no",
                                                                                msoStyleQformat: "yes",
                                                                                msoStyleParent: "",
                                                                                margin: "0.2cm 0cm 0cm",
                                                                                marginBottom: ".0001pt",
                                                                                msoPagination: "widow-orphan",
                                                                                msoBidiThemeFont: "minor-bidi",
                                                                            }}
                                                                        >
                                                                            {member.phone ? (
                                                                                <Fragment>
                                                                                    <b
                                                                                        style={{
                                                                                            color: `${colorScheme.text ? colorScheme.text : "#1f1f1f"}`,
                                                                                        }}
                                                                                    >
                                                                                        {language.locale === "fr" ? "T" : "P"}:
                                                                                    </b>
                                                                                    <a
                                                                                        href={"tel:" + member.phone}
                                                                                        rel="noopener noreferrer"
                                                                                        style={{
                                                                                            color: `${colorScheme.links ? colorScheme.links : "#205CCA"}`,
                                                                                            textDecoration: "none",
                                                                                            padding: "0",
                                                                                        }}
                                                                                    >
                                                                                        {member.phone}
                                                                                    </a>
                                                                                    <br />
                                                                                </Fragment>
                                                                            ) : null}

                                                                            {member.mobile ? (
                                                                                <Fragment>
                                                                                    <b
                                                                                        style={{
                                                                                            color: `${colorScheme.text ? colorScheme.text : "#1f1f1f"}`,
                                                                                        }}
                                                                                    >
                                                                                        {language.locale === "fr" ? "C" : "M"}:
                                                                                    </b>
                                                                                    <a
                                                                                        href={"tel:" + member.mobile}
                                                                                        rel="noopener noreferrer"
                                                                                        style={{
                                                                                            color: `${colorScheme.links ? colorScheme.links : "#205CCA"}`,
                                                                                            textDecoration: "none",
                                                                                            padding: "0",
                                                                                        }}
                                                                                    >
                                                                                        {member.mobile}
                                                                                    </a>
                                                                                    <br />
                                                                                </Fragment>
                                                                            ) : null}

                                                                            {member.fax ? (
                                                                                <Fragment>
                                                                                    <b
                                                                                        style={{
                                                                                            color: `${colorScheme.text ? colorScheme.text : "#1f1f1f"}`,
                                                                                        }}
                                                                                    >
                                                                                        {language.locale === "fr" ? "Téléc" : "F"}:
                                                                                    </b>
                                                                                    {member.fax}
                                                                                </Fragment>
                                                                            ) : null}
                                                                        </p>
                                                                    ) : null}

                                                                    {member.email || member.website || member.id !== "" ? (
                                                                        <p
                                                                            className="MsoNormal"
                                                                            style={{
                                                                                fontSize: "8.5pt",
                                                                                fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                                color: `${colorScheme.text ? colorScheme.text : "#1f1f1f"}`,
                                                                                padding: "0 !important",
                                                                                msoStyleUnhide: "no",
                                                                                msoStyleQformat: "yes",
                                                                                msoStyleParent: "",
                                                                                margin: "0.2cm 0cm 0cm",
                                                                                marginBottom: ".0001pt",
                                                                                msoPagination: "widow-orphan",
                                                                                msoBidiThemeFont: "minor-bidi",
                                                                            }}
                                                                        >
                                                                            {member.email ? (
                                                                                <Fragment>
                                                                                    <a
                                                                                        href={"mailto:" + member.email}
                                                                                        rel="noopener noreferrer"
                                                                                        style={{
                                                                                            color: `${colorScheme.links ? colorScheme.links : "#205CCA"}`,
                                                                                            textDecoration: "none",
                                                                                            padding: "0",
                                                                                        }}
                                                                                    >
                                                                                        {member.email}
                                                                                    </a>
                                                                                    <br />
                                                                                </Fragment>
                                                                            ) : null}

                                                                            {member.website ? (
                                                                                <a
                                                                                    href={"https://" + member.website}
                                                                                    rel="noopener noreferrer"
                                                                                    style={{
                                                                                        color: `${colorScheme.links ? colorScheme.links : "#205CCA"}`,
                                                                                        textDecoration: "none",
                                                                                        padding: "0",
                                                                                    }}
                                                                                    target="_blank"
                                                                                >
                                                                                    {member.website}
                                                                                </a>
                                                                            ) : null}
                                                                        </p>
                                                                    ) : null}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td
                                                    valign="top"
                                                    width="325"
                                                    bgcolor={colorScheme.primary ? colorScheme.primary : "#005baa"}
                                                    style={{
                                                        margin: "0",
                                                        padding: "0",
                                                        verticalAlign: "top",
                                                        msoTableLspace: "0pt !important",
                                                        msoTableRspace: "0pt !important",
                                                        background: `${colorScheme.primary ? colorScheme.primary : "#005baa"}`,
                                                    }}
                                                >
                                                    <table
                                                        cellSpacing="7"
                                                        cellPadding="0"
                                                        border="0"
                                                        width="325"
                                                        bgcolor={colorScheme.primary ? colorScheme.primary : "#005baa"}
                                                        style={{
                                                            margin: "0 !important",
                                                            padding: "0 !important",
                                                            borderCollapse: "separate",
                                                            msoTableLspace: "0pt !important",
                                                            msoTableRspace: "0pt !important",
                                                            background: `${colorScheme.primary ? colorScheme.primary : "#005baa"}`,
                                                        }}
                                                    >
                                                        <tbody>
                                                            <tr
                                                                style={{
                                                                    borderSpacing: "0 !important",
                                                                    padding: "0 !important",
                                                                    margin: "0 !important",
                                                                }}
                                                            >
                                                                {member.subTeamLogoUrl || franchiseLogo ? (
                                                                    <td
                                                                        valign="top"
                                                                        width="60"
                                                                        style={{
                                                                            margin: "0",
                                                                            padding: "0",
                                                                            verticalAlign: "top",
                                                                            msoTableLspace: "0pt !important",
                                                                            msoTableRspace: "0pt !important",
                                                                        }}
                                                                    >
                                                                        {member.subTeamLogoUrl ? (
                                                                            <p
                                                                                className="MsoNormal"
                                                                                style={{
                                                                                    padding: "0 !important",
                                                                                    msoStyleUnhide: "no",
                                                                                    msoStyleQformat: "yes",
                                                                                    msoStyleParent: "",
                                                                                    margin: "0cm",
                                                                                    marginBottom: ".0001pt",
                                                                                    msoPagination: "widow-orphan",
                                                                                    msoBidiThemeFont: "minor-bidi",
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={member.subTeamLogoUrl}
                                                                                    width="60"
                                                                                    border="0"
                                                                                    alt={member.subTeamName}
                                                                                    style={{
                                                                                        margin: "0",
                                                                                        display: "block",
                                                                                        outline: "none",
                                                                                        border: "none",
                                                                                        textDecoration: "none",
                                                                                        MsInterpolationMode: "bicubic",
                                                                                        verticalAlign: "middle",
                                                                                    }}
                                                                                />
                                                                            </p>
                                                                        ) : null}
                                                                        {franchiseLogo ? (
                                                                            <p
                                                                                className="MsoNormal"
                                                                                style={{
                                                                                    padding: "0 !important",
                                                                                    msoStyleUnhide: "no",
                                                                                    msoStyleQformat: "yes",
                                                                                    msoStyleParent: "",
                                                                                    margin: "0cm",
                                                                                    marginBottom: ".0001pt",
                                                                                    msoPagination: "widow-orphan",
                                                                                    msoBidiThemeFont: "minor-bidi",
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={franchiseLogo}
                                                                                    width="60"
                                                                                    border="0"
                                                                                    alt={user.type === "broker-user" ? user.info.franchise.name : user.info.name}
                                                                                    style={{
                                                                                        margin: member.subTeamLogoUrl ? "5px 0 0 0" : "0",
                                                                                        display: "block",
                                                                                        outline: "none",
                                                                                        border: "none",
                                                                                        textDecoration: "none",
                                                                                        MsInterpolationMode: "bicubic",
                                                                                        verticalAlign: "middle",
                                                                                    }}
                                                                                />
                                                                            </p>
                                                                        ) : null}
                                                                    </td>
                                                                ) : null}

                                                                <td
                                                                    valign="top"
                                                                    width="251"
                                                                    style={{
                                                                        margin: "0",
                                                                        padding: "0",
                                                                        verticalAlign: "top",
                                                                        msoTableLspace: "0pt !important",
                                                                        msoTableRspace: "0pt !important",
                                                                    }}
                                                                >
                                                                    <p
                                                                        className="MsoNormal"
                                                                        style={{
                                                                            fontSize: "12pt",
                                                                            fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                            color: "#ffffff",
                                                                            textTransform: "capitalize",
                                                                            fontWeight: "900",
                                                                            padding: "0 !important",
                                                                            lineHeight: "14pt",
                                                                            msoStyleUnhide: "no",
                                                                            msoStyleQformat: "yes",
                                                                            msoStyleParent: "",
                                                                            margin: "0cm",
                                                                            marginBottom: ".0001pt",
                                                                            msoPagination: "widow-orphan",
                                                                            msoBidiThemeFont: "minor-bidi",
                                                                        }}
                                                                    >
                                                                        {member.subTeamName ? (
                                                                            <Fragment>
                                                                                <b>{member.subTeamName}</b>
                                                                                <br />
                                                                            </Fragment>
                                                                        ) : (
                                                                            <Fragment>
                                                                                <b>{user.type === "broker-user" ? user.info.franchise.name : user.info.name}</b>
                                                                                <br />
                                                                            </Fragment>
                                                                        )}

                                                                        {member.subTeamName ? (
                                                                            <Fragment>
                                                                                <b
                                                                                    style={{
                                                                                        fontSize: "10pt",
                                                                                        fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                                        color: "#fff",
                                                                                        margin: "0",
                                                                                        fontWeight: "normal",
                                                                                    }}
                                                                                >
                                                                                    {user.type === "broker-user" ? user.info.franchise.name : user.info.name}
                                                                                </b>
                                                                                <br />
                                                                            </Fragment>
                                                                        ) : null}

                                                                        {franchiseLicenseNumber ? (
                                                                            <b
                                                                                style={{
                                                                                    fontSize: "8.5pt",
                                                                                    fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                                    color: "#fff",
                                                                                    margin: "0",
                                                                                    fontWeight: "900",
                                                                                }}
                                                                            >
                                                                                {franchiseLicenseNumber}
                                                                            </b>
                                                                        ) : null}
                                                                    </p>

                                                                    {template.features.office && (
                                                                        <p
                                                                            className="MsoNormal"
                                                                            style={{
                                                                                fontSize: "8.5pt",
                                                                                fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                                color: "#fff",
                                                                                padding: "0 !important",
                                                                                fontWeight: "normal",
                                                                                msoStyleUnhide: "no",
                                                                                msoStyleQformat: "yes",
                                                                                msoStyleParent: "",
                                                                                margin: "0.2cm 0cm 0cm",
                                                                                marginBottom: "0.2cm",
                                                                                msoPagination: "widow-orphan",
                                                                                msoBidiThemeFont: "minor-bidi",
                                                                            }}
                                                                        >
                                                                            {features.office.addressLineTwo && features.office.addressLineTwo + "-"}
                                                                            {features.office.streetAddress}
                                                                            <br />
                                                                            {features.office.city}, {features.office.provinceCode}
                                                                            <br />
                                                                            {features.office.postalCode}
                                                                        </p>
                                                                    )}

                                                                    <p
                                                                        className="MsoNormal"
                                                                        style={{
                                                                            fontSize: "8.5pt",
                                                                            fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                            padding: "0 !important",
                                                                            fontWeight: "normal",
                                                                            msoStyleUnhide: "no",
                                                                            msoStyleQformat: "yes",
                                                                            msoStyleParent: "",
                                                                            margin: "0.2cm 0cm 0cm",
                                                                            marginBottom: ".0001pt",
                                                                            msoPagination: "widow-orphan",
                                                                            msoBidiThemeFont: "minor-bidi",
                                                                        }}
                                                                    >
                                                                        {franchiseWebsite ? (
                                                                            <Fragment>
                                                                                <a
                                                                                    href={"https://" + franchiseWebsite}
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                    style={{
                                                                                        color: `${colorScheme.links ? colorScheme.links : "#24C4F0"}`,
                                                                                        textDecoration: "none",
                                                                                        padding: "0",
                                                                                    }}
                                                                                >
                                                                                    {franchiseWebsite}
                                                                                </a>
                                                                                <br />
                                                                            </Fragment>
                                                                        ) : null}
                                                                        <i
                                                                            style={{
                                                                                fontSize: "8pt",
                                                                                fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                                color: "#fff",
                                                                                margin: "0",
                                                                                fontWeight: "200",
                                                                            }}
                                                                        >
                                                                            {language.locale === "fr" ? "Chaque franchise est autonome et indépendante" : "Independently Owned & Operated"}
                                                                        </i>
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr
                                                style={{
                                                    borderSpacing: "0 !important",
                                                    padding: "0 !important",
                                                    margin: "0 !important",
                                                    fontSize: "0px",
                                                    lineHeight: "0px",
                                                    borderCollapse: "collapse",
                                                    background: `${colorScheme.primary ? colorScheme.primary : "#005baa"}`,
                                                }}
                                            >
                                                <td
                                                    valign="top"
                                                    width="325"
                                                    bgcolor={colorScheme.secondary ? colorScheme.secondary : "#ffffff"}
                                                    style={{
                                                        margin: "0",
                                                        padding: "0",
                                                        verticalAlign: "top",
                                                        msoTableLspace: "0pt !important",
                                                        msoTableRspace: "0pt !important",
                                                        background: `${colorScheme.secondary ? colorScheme.secondary : "#ffffff"}`,
                                                    }}
                                                >
                                                    <table
                                                        cellSpacing="7"
                                                        cellPadding="0"
                                                        border="0"
                                                        width="252"
                                                        bgcolor={colorScheme.secondary ? colorScheme.secondary : "#ffffff"}
                                                        style={{
                                                            margin: "0 !important",
                                                            padding: "0 !important",
                                                            borderCollapse: "separate",
                                                            msoTableLspace: "0pt !important",
                                                            msoTableRspace: "0pt !important",
                                                            background: `${colorScheme.secondary ? colorScheme.secondary : "#ffffff"}`,
                                                        }}
                                                    >
                                                        <tbody>
                                                            <tr
                                                                style={{
                                                                    borderSpacing: "0 !important",
                                                                    padding: "0 !important",
                                                                    margin: "0 !important",
                                                                }}
                                                            >
                                                                {member.applicationUrl ? (
                                                                    <td
                                                                        valign="middle"
                                                                        width="100"
                                                                        style={{
                                                                            margin: "0",
                                                                            padding: "0",
                                                                            msoTableLspace: "0pt !important",
                                                                            msoTableRspace: "0pt !important",
                                                                        }}
                                                                    >
                                                                        <a
                                                                            href={member.applicationUrl}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                            style={{
                                                                                padding: "0",
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={
                                                                                    language.locale === "fr"
                                                                                        ? "https://centralhost.dominionlending.ca/signatures/franchise/template-1/apply-now-btn-fr.png"
                                                                                        : "https://centralhost.dominionlending.ca/signatures/franchise/template-1/apply-now-btn-en.png"
                                                                                }
                                                                                width="95"
                                                                                height="25"
                                                                                border="0"
                                                                                alt={language.locale === "fr" ? "Présenter une Demande" : "Apply Now"}
                                                                                style={{
                                                                                    display: "block",
                                                                                    border: "none",
                                                                                    outline: "none",
                                                                                    textDecoration: "none",
                                                                                    MsInterpolationMode: "bicubic",
                                                                                    verticalAlign: "middle",
                                                                                }}
                                                                            />
                                                                        </a>
                                                                    </td>
                                                                ) : null}
                                                                {member.mortgageToolboxUrl ? (
                                                                    <td
                                                                        valign="middle"
                                                                        width="137"
                                                                        style={{
                                                                            margin: "0",
                                                                            padding: "0",
                                                                            msoTableLspace: "0pt !important",
                                                                            msoTableRspace: "0pt !important",
                                                                        }}
                                                                    >
                                                                        <a
                                                                            href={member.mortgageToolboxUrl}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                            style={{
                                                                                padding: "0",
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={
                                                                                    language.locale === "fr"
                                                                                        ? "https://centralhost.dominionlending.ca/signatures/franchise/template-1/pre-qualified-btn-fr.png"
                                                                                        : "https://centralhost.dominionlending.ca/signatures/franchise/template-1/pre-qualified-btn-en.png"
                                                                                }
                                                                                width="136"
                                                                                height="25"
                                                                                border="0"
                                                                                alt={language.locale === "fr" ? "Obtenir une Préapprobation" : "Get Pre-qualified"}
                                                                                style={{
                                                                                    display: "block",
                                                                                    border: "none",
                                                                                    outline: "none",
                                                                                    textDecoration: "none",
                                                                                    MsInterpolationMode: "bicubic",
                                                                                    verticalAlign: "middle",
                                                                                }}
                                                                            />
                                                                        </a>
                                                                    </td>
                                                                ) : null}
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td
                                                    valign="top"
                                                    width="325"
                                                    bgcolor={colorScheme.primary ? colorScheme.primary : "#005baa"}
                                                    style={{
                                                        margin: "0",
                                                        padding: "0",
                                                        verticalAlign: "top",
                                                        background: `${colorScheme.primary ? colorScheme.primary : "#005baa"}`,
                                                    }}
                                                >
                                                    <table
                                                        cellSpacing="7"
                                                        cellPadding="0"
                                                        border="0"
                                                        width="325"
                                                        bgcolor={colorScheme.primary ? colorScheme.primary : "#005baa"}
                                                        style={{
                                                            padding: "0 !important",
                                                            borderCollapse: "separate",
                                                            msoTableLspace: "0pt !important",
                                                            msoTableRspace: "0pt !important",
                                                            background: `${colorScheme.primary ? colorScheme.primary : "#005baa"}`,
                                                        }}
                                                    >
                                                        <tbody>
                                                            <tr
                                                                style={{
                                                                    borderSpacing: "0 !important",
                                                                    padding: "0 !important",
                                                                    margin: "0 !important",
                                                                }}
                                                            >
                                                                <td
                                                                    key="dominion"
                                                                    width="60"
                                                                    style={{
                                                                        margin: "0",
                                                                        padding: "0",
                                                                        msoTableLspace: "0pt !important",
                                                                        msoTableRspace: "0pt !important",
                                                                    }}
                                                                >
                                                                    <a
                                                                        href={language.locale === "fr" ? "https://www.hypothecairesdominion.ca/" : "https://dominionlending.ca/"}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        style={{
                                                                            padding: "0",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={
                                                                                language.locale === "fr"
                                                                                    ? "https://centralhost.dominionlending.ca/signatures/franchise/template-1/dlc-logo-fr.png"
                                                                                    : "https://centralhost.dominionlending.ca/signatures/franchise/template-1/dlc-logo-en.png"
                                                                            }
                                                                            width="58"
                                                                            height="25"
                                                                            border="0"
                                                                            alt={language.locale === "fr" ? "Centres Hypothécaires Dominion" : "Dominion Lending Centres"}
                                                                            style={{
                                                                                display: "block",
                                                                                border: "none",
                                                                                outline: "none",
                                                                                textDecoration: "none",
                                                                                MsInterpolationMode: "bicubic",
                                                                                verticalAlign: "middle",
                                                                            }}
                                                                        />
                                                                    </a>
                                                                </td>
                                                                <td style={{ width: "100%" }}></td>
                                                                {["facebook", "twitter", "instagram", "linkedin", "youtube", "pinterest"].map((socialMedia) =>
                                                                    features.socialMediaUrls[socialMedia] ? (
                                                                        <td
                                                                            key={socialMedia}
                                                                            width="26"
                                                                            height="26"
                                                                            style={{
                                                                                margin: "0",
                                                                                padding: "0",
                                                                                msoTableLspace: "0pt !important",
                                                                                msoTableRspace: "0pt !important",
                                                                            }}
                                                                        >
                                                                            <a
                                                                                href={features.socialMediaUrls[socialMedia]}
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                                style={{
                                                                                    padding: "0",
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={"https://centralhost.dominionlending.ca/signatures/franchise/template-1/" + socialMedia + ".png"}
                                                                                    width="25"
                                                                                    height="25"
                                                                                    border="0"
                                                                                    alt={socialMedia}
                                                                                    style={{
                                                                                        display: "block",
                                                                                        border: "none",
                                                                                        outline: "none",
                                                                                        textDecoration: "none",
                                                                                        MsInterpolationMode: "bicubic",
                                                                                        verticalAlign: "middle",
                                                                                    }}
                                                                                />
                                                                            </a>
                                                                        </td>
                                                                    ) : null
                                                                )}
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>

                            <tr
                                style={{
                                    margin: "0",
                                    padding: "0",
                                    fontSize: "0px",
                                    lineHeight: "0px",
                                    borderCollapse: "collapse",
                                    background: "#efefef",
                                }}
                            >
                                <td
                                    width="650"
                                    style={{
                                        margin: "0",
                                        padding: "0",
                                        msoTableLspace: "0pt !important",
                                        msoTableRspace: "0pt !important",
                                    }}
                                >
                                    <table
                                        cellSpacing="10"
                                        cellPadding="0"
                                        border="0"
                                        width="650"
                                        bgcolor="#efefef"
                                        style={{
                                            margin: "0 !important",
                                            background: "#efefef",
                                            borderCollapse: "collapse",
                                            border: "none",
                                            msoTableLspace: "0pt !important",
                                            msoTableRspace: "0pt !important",
                                        }}
                                    >
                                        <tbody>
                                            <tr
                                                style={{
                                                    borderSpacing: "0 !important",
                                                    padding: "0 !important",
                                                    margin: "0 !important",
                                                    display: "block",
                                                    fontSize: "0px",
                                                    lineHeight: "0px",
                                                    borderCollapse: "collapse",
                                                }}
                                            >
                                                <td
                                                    width="650"
                                                    valign="left"
                                                    style={{
                                                        fontSize: "7.5pt",
                                                        fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                        color: `${colorScheme.text ? colorScheme.text : "#1f1f1f"}`,
                                                        fontWeight: "400",
                                                        margin: "0",
                                                        padding: "0",
                                                        msoTableLspace: "0pt !important",
                                                        msoTableRspace: "0pt !important",
                                                    }}
                                                >
                                                    {member.bannerUrl || features.bannerUrl ? (
                                                        <a
                                                            href={features.customBannerUrl ? features.customBannerUrl : member.customBannerUrl}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            style={{
                                                                textDecoration: "none",
                                                            }}
                                                        >
                                                            <img
                                                                src={features.bannerUrl ? features.bannerUrl : member.bannerUrl}
                                                                width="650"
                                                                border="0"
                                                                alt={language.locale === "fr" ? "Bannière Personnalisée" : "Custom Banner"}
                                                                style={{
                                                                    width: "100%",
                                                                    display: "block",
                                                                    outline: "none",
                                                                    textDecoration: "none",
                                                                    MsInterpolationMode: "bicubic",
                                                                    verticalAlign: "middle",
                                                                }}
                                                            />
                                                        </a>
                                                    ) : null}

                                                    <p
                                                        className="MsoNormal"
                                                        style={{
                                                            padding: "0 !important",
                                                            fontSize: "7.5pt",
                                                            fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                            color: `${colorScheme.text ? colorScheme.text : "#1f1f1f"}`,
                                                            fontWeight: "400",
                                                            lineHeight: "9pt",
                                                            msoStyleUnhide: "no",
                                                            msoStyleQformat: "yes",
                                                            msoStyleParent: "",
                                                            margin: "10px",
                                                            msoPagination: "widow-orphan",
                                                            msoBidiThemeFont: "minor-bidi",
                                                        }}
                                                    >
                                                        {features.confidentialityWarning ? (
                                                            <Fragment>
                                                                <b>{language.locale === "fr" ? "Avertissement : " : "Confidentiality Warning: "}</b>
                                                                {features.confidentialityWarning}{" "}
                                                            </Fragment>
                                                        ) : null}
                                                        {language.locale === "fr"
                                                            ? "À noter : Cet e-mail a été envoyé après la Canadienne législation antispam directives, si vous souhaitez ne plus recevoir la correspondance électronique à partir de cette adresse e-mail, veuillez cliquer ici pour"
                                                            : "Please Note: This email was sent following the Canadian Anti-Spam Legislation guidelines. If you wish to no longer receive electronic correspondence from this email address, please confirm by clicking here to"}{" "}
                                                        <a
                                                            href={"mailto:" + member.email + "?subject=unsubscribe"}
                                                            rel="noopener noreferrer"
                                                            style={{
                                                                color: `${colorScheme.links ? colorScheme.links : "#205CCA"}`,
                                                                textDecoration: "none",
                                                                padding: "0",
                                                            }}
                                                        >
                                                            {language.locale === "fr" ? "annuler un abonnement" : "unsubscribe"}
                                                        </a>
                                                        .
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                )}
            </LanguageContext.Consumer>
        </Fragment>
    );
};
