import React from "react";
import "./App.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Page from "./components/Page";

class App extends React.Component {
    render() {
        return (
            <Router>
                <Switch>
                    <Route path="/:userType/:userLang/:userId" children={<Page />} />
                    <Route
                        path="/"
                        component={() => {
                            window.location = "https://secure.dominionintranet.ca/login/login.php";
                            return null;
                        }}
                    />
                    <Route
                        path="/unauthenticated"
                        component={() => {
                            window.location = "https://secure.dominionintranet.ca/login/login.php";
                            return null;
                        }}
                    />
                </Switch>
            </Router>
        );
    }
}

export default App;
