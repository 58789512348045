import React, { Component, Fragment } from "react";
import { Row, Col, Alert } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

//My Components
import Upload from "../Upload";
import ContactInfoFormBroker from "../forms/broker/BrokerContactInfoForm";
import FranchiseContactInfoForm from "../forms/franchise/FranchiseContactInfoForm";
import SubTeamInfoForm from "../forms/SubTeamInfoForm";
import UserContext from "../../contexts/UserContext";
import BrokerAddressInfoForm from "../forms/broker/BrokerAddressInfoForm";
import TemplateContext from "../../contexts/TemplateContext";

//Default Images
import DefaultUserProfile from "../../images/user-profile-default.png";

class ContactInfoTab extends Component {
    static contextType = TemplateContext;

    constructor(props) {
        super(props);
        this.state = {
            toggle: false,
            userMembers: [],
            isLoaded: false,
        };
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(user) {
        this.setState((state) => ({
            toggle: !state.toggle,
        }));
        let { template, updateTemplate } = this.context;

        if (this.state.toggle !== true) {
            template.features.profileImageUrl = DefaultUserProfile;
        } else {
            template.features.profileImageUrl = null;
        }

        if (user.type === "franchise-user") {
            if (this.state.toggle !== true) {
                template.member.profileImageUrl = DefaultUserProfile;
            } else {
                template.member.profileImageUrl = null;
            }
        }
        updateTemplate(template);
    }

    render() {
        return (
            <UserContext.Consumer>
                {({ user, updateUser }) => (
                    <Fragment>
                        {(user.type === "franchise-user" || user.type === "broker-user") && (
                            <Fragment>
                                <Row className="sub-header">
                                    <Col>
                                        <FormattedMessage id="imageForm.profileImageSubHeader" defaultMessage="Profile Image" />
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                defaultChecked={this.state.toggle}
                                                onClick={(e) => {
                                                    this.handleClick(user);
                                                }}
                                            />
                                            <span className="slider round" />
                                        </label>
                                    </Col>
                                </Row>

                                <Row className={this.state.toggle ? null : "d-none"}>
                                    <Col>
                                        <Alert variant="info">
                                            <FormattedMessage id="helperToolTip.fileFormats" defaultMessage="Accepted file formats are JPG, JPEG, or PNG with an upload limit of 5MB." />
                                        </Alert>
                                        <Upload imageType="profileImageUrl" />
                                    </Col>
                                </Row>
                            </Fragment>
                        )}

                        {(user.type === "broker-user" || user.type === "head-office-user") && <ContactInfoFormBroker />}

                        {user.type === "broker-user" && <BrokerAddressInfoForm />}

                        {user.type === "franchise-user" && <FranchiseContactInfoForm />}

                        {(user.type === "franchise-user" || user.type === "broker-user") && <SubTeamInfoForm />}
                    </Fragment>
                )}
            </UserContext.Consumer>
        );
    }
}

export default ContactInfoTab;
