import React, { Component, Fragment } from "react";
import { Nav } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

//My Components
import UserContext from "../../contexts/UserContext";

class MainNav extends Component {
    render() {
        return (
            <UserContext.Consumer>
                {({ user, updateUser }) => (
                    <Fragment>
                        <Nav id="main-nav" justify className={this.props.componentClass} variant="pills">
                            {(user.type === "franchise-user" || user.type === "broker-user") && (
                                <Nav.Item id="templates-nav-item">
                                    <Nav.Link eventKey="templates">
                                        <FormattedMessage id="sidebar.templatesHeader" defaultMessage="Templates" />
                                    </Nav.Link>
                                </Nav.Item>
                            )}

                            {user.type === "franchise-user" && (
                                <Nav.Item id="franchise-nav-item">
                                    <Nav.Link eventKey="franchise">
                                        <FormattedMessage id="sidebar.franchiseHeader" defaultMessage="Franchise" />
                                    </Nav.Link>
                                </Nav.Item>
                            )}

                            <Nav.Item id="contact-nav-item">
                                <Nav.Link eventKey="contact">
                                    <FormattedMessage id="sidebar.contactHeader" defaultMessage="Contact" />
                                </Nav.Link>
                            </Nav.Item>

                            <Nav.Item id="addon-nav-item">
                                <Nav.Link eventKey="add-on">
                                    <FormattedMessage id="sidebar.addonHeader" defaultMessage="Add-on" />
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Fragment>
                )}
            </UserContext.Consumer>
        );
    }
}

export default MainNav;
