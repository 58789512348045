import React from "react";
//import TemplateContext from "../../../contexts/TemplateContext";
//import UserContext from "../../../contexts/UserContext";

export const FranchiseTemplateThree = () => {
    //const { template } = useContext(TemplateContext);
    //const { user } = useContext(UserContext);
    //const features = template.features;
    //const member = user.type === "broker-user" ? template : template.member;
    const data = "";

    return (
        <table cellSpacing="0" border="0" width="485">
            <tbody>
                <tr>
                    <td bgcolor="green">
                        <table
                            cellSpacing="5"
                            cellPadding="0"
                            border="0"
                            width="485"
                            style={{
                                borderCollapse: "separate",
                            }}
                        >
                            <tbody>
                                <tr
                                    style={{
                                        fontFamily: "Arial",
                                    }}
                                >
                                    <td valign="left">
                                        <h2
                                            style={{
                                                fontSize: "14pt",
                                                fontFamily: "Arial",
                                                color: "#29c4f0",
                                                textTransform: "uppercase",
                                                margin: "2px 0 5px 0",
                                                fontWeight: "400",
                                            }}
                                        >
                                            {data.fullName ? data.fullName : "John Doe"}
                                        </h2>

                                        <font
                                            style={{
                                                fontSize: "11.5pt",
                                                fontFamily: "Arial",
                                                color: "#00377a",
                                                margin: "0",
                                                fontWeight: "200",
                                            }}
                                        >
                                            {data.jobTitle ? data.jobTitle : "Mortgage Professional"}
                                        </font>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <table
                            cellSpacing="5"
                            cellPadding="0"
                            border="0"
                            width="485"
                            style={{
                                borderCollapse: "separate",
                            }}
                        >
                            <tbody>
                                <tr
                                    style={{
                                        fontSize: "8.5pt",
                                        fontFamily: "Arial",
                                        fontWeight: "400",
                                        color: "#00377a",
                                        margin: "0",
                                    }}
                                >
                                    <td style={{ margin: 0 }}>
                                        <font
                                            style={{
                                                fontSize: "8.5pt",
                                                fontFamily: "Arial",
                                                color: "#00377a",
                                                margin: "0",
                                            }}
                                        >
                                            <strong
                                                style={{
                                                    fontWeight: "900",
                                                }}
                                            >
                                                E.
                                            </strong>

                                            <a
                                                href="mailto:amanda@dlc.ca"
                                                style={{
                                                    color: "#00377a",
                                                    textDecoration: "underline",
                                                }}
                                            >
                                                {data.email ? data.email : null}
                                            </a>
                                        </font>
                                    </td>
                                </tr>

                                <tr
                                    style={{
                                        fontSize: "8.5pt",
                                        fontFamily: "Arial",
                                        fontWeight: "400",
                                        color: "#00377a",
                                        margin: "0",
                                    }}
                                >
                                    <td>
                                        <font
                                            style={{
                                                fontSize: "8.5pt",
                                                fontFamily: "Arial",
                                                color: "#00377a",
                                                margin: "0",
                                            }}
                                        >
                                            <strong
                                                style={{
                                                    fontWeight: "900",
                                                }}
                                            >
                                                W.
                                            </strong>

                                            <a
                                                href="https://dominionlending.ca/"
                                                rel="noopener noreferrer"
                                                style={{
                                                    color: "#00377a",
                                                    textDecoration: "underline",
                                                }}
                                                target="_blank"
                                            >
                                                dominionlending.ca
                                            </a>
                                        </font>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>

                <tr>
                    <td>
                        <table
                            cellSpacing="5"
                            cellPadding="0"
                            border="0"
                            width="485"
                            style={{
                                borderCollapse: "separate",
                            }}
                        >
                            <tbody>
                                <tr>
                                    <td valign="left">
                                        <a href="https://dominionlending.ca/" rel="noopener noreferrer" target="_blank">
                                            <img alt="Dominion Lending Centres" border="0" width="127" height="60" src="https://centralhost.dominionlending.ca/signatures/dlc-logo.jpg" />
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>

                <tr>
                    <td>
                        <table
                            cellSpacing="5"
                            cellPadding="0"
                            border="0"
                            width="100"
                            style={{
                                borderCollapse: "separate",
                            }}
                        >
                            <tbody>
                                <tr>
                                    <td valign="left">
                                        <a href="https://www.facebook.com/DominionLendingCentresCanada" rel="noopener noreferrer" target="_blank">
                                            <img
                                                alt="Like Us on Facebook Dominion Lending Centres"
                                                border="0"
                                                width="24"
                                                height="20"
                                                src="https://centralhost.dominionlending.ca/signatures/social/facebook-icon.png"
                                            />
                                        </a>
                                    </td>

                                    <td valign="left">
                                        <a href="https://www.linkedin.com/company/dominion-lending-centres" rel="noopener noreferrer" target="_blank">
                                            <img
                                                alt="LinkedIn Dominion Lending Centres"
                                                border="0"
                                                width="24"
                                                height="20"
                                                src="https://centralhost.dominionlending.ca/signatures/social/linkedin-icon.png"
                                            />
                                        </a>
                                    </td>

                                    <td valign="left">
                                        <a href="https://twitter.com/DLCCanadaInc" rel="noopener noreferrer" target="_blank">
                                            <img
                                                alt="Follow us on Twitter Dominion Lending Centres"
                                                border="0"
                                                width="24"
                                                height="20"
                                                src="https://centralhost.dominionlending.ca/signatures/social/twitter-icon.png"
                                            />
                                        </a>
                                    </td>

                                    <td valign="left">
                                        <a href="https://www.youtube.com/DLCcanada" rel="noopener noreferrer" target="_blank">
                                            <img
                                                alt="Subscribe on YouTube Dominion Lending Centres"
                                                border="0"
                                                width="24"
                                                height="20"
                                                src="https://centralhost.dominionlending.ca/signatures/social/youtube-icon.png"
                                            />
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>

                <tr>
                    <td>
                        <table
                            cellSpacing="5"
                            cellPadding="0"
                            border="0"
                            width="485"
                            style={{
                                borderCollapse: "separate",
                            }}
                        >
                            <tbody>
                                <tr>
                                    <td
                                        style={{
                                            fontSize: "6.0pt",
                                            fontFamily: "Arial",
                                            color: "#00377a",
                                        }}
                                        valign="left"
                                    >
                                        <a
                                            href="https://support.dominionlending.ca/hc/fr"
                                            rel="noopener noreferrer"
                                            style={{
                                                fontSize: "9.0pt",
                                                fontFamily: "Arial",
                                                color: "#00377a",
                                                fontWeight: "900",
                                                textDecoration: "underline",
                                            }}
                                            target="_blank"
                                        >
                                            support.dominionlending.ca
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>

                <tr>
                    <td>
                        <table
                            cellSpacing="5"
                            cellPadding="0"
                            border="0"
                            width="485"
                            style={{
                                borderCollapse: "separate",
                            }}
                        >
                            <tbody>
                                <tr>
                                    <td
                                        style={{
                                            fontSize: "6.0pt",
                                            fontFamily: "Arial",
                                            color: "#00377a",
                                            fontWeight: "400",
                                        }}
                                        valign="left"
                                    >
                                        <font style={{ margin: "0" }}>
                                            Confidentiality Warning: This e-mail contains information intended only for the use of the individual or entity named above. If the reader of this e-mail is
                                            not the intended recipient or the employee or agent responsible for delivering it to the intended recipient, any dissemination, publication, or copying of
                                            this e-mail is strictly prohibited. The sender does not accept any responsibility for any loss, disruption or damage to your data or computer system that
                                            may occur while using data contained in, or transmitted with, this e-mail. If you have received this email in error, please inform us via return email.
                                            Thank you for your cooperation.
                                        </font>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};
