import React from "react";
import { Row, Tab, Container } from "react-bootstrap";
import { Route, withRouter } from "react-router-dom";
import axios from "axios";

//My Components
import MobileHeader from "./MobileHeader";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Main from "./Main";
import UserContext from "../contexts/UserContext";
import TemplateContext from "../contexts/TemplateContext";
import initializeTemplate from "./utilities/TemplateInitialization";

class Page extends React.Component {
    constructor(props) {
        super(props);

        this.updateTemplate = (template) => {
            this.setState({
                template: template,
            });
        };

        this.updateUser = (user) => {
            this.setState({
                user: user,
            });
        };

        // Retrieve user info from URL params and set them in Page state.
        this.state = {
            user: {
                type: this.props.match.params.userType,
                legacyId: this.props.match.params.userId,
                lang: this.props.match.params.userLang,
                info: {},
            },
            updateUser: this.updateUser,
            template: {},
            updateTemplate: this.updateTemplate,
            isLoaded: false,
        };
    }

    componentDidMount() {
        let that = this;

        this.getBearerToken(function () {
            var localStorageApiToken = window.localStorage.getItem("api_token");
            axios.defaults.headers.common["Authorization"] = "Bearer " + localStorageApiToken;
            that.fillUserData();
        });
    }

    getBearerToken(cb) {
        var ssoToken = this.getParameterByName("sso_token");
        var userType = this.getParameterByName("user_type");

        var uri = process.env.REACT_APP_BACKEND_URL + "/api/token";

        axios({
            url: uri,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            data: {
                sso_token: ssoToken,
                user_type: userType,
                legacy_id: this.state.user.legacyId,
            },
        })
            .then((response) => {
                var accessToken = response.data.access_token;
                window.localStorage.setItem("api_token", accessToken);
                //axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
            })
            .catch((error) => {})
            .then(() => {
                cb();
            });
    }

    fillUserData() {
        const userParam = this.state.user.type === "franchise-user" ? "franchise" : "user";
        const legacyId = this.state.user.legacyId;

        let url = process.env.REACT_APP_BACKEND_URL + "/signature/" + userParam + "/legacy_id/";

        axios
            .get(url + legacyId)
            .then((response) => {
                this.state.user.info = response.data;
                this.state.template = initializeTemplate(this.state.user, null);
                //console.log("---INITIAL INFO---");
                //sconsole.log(this.state.user);
                //console.log(this.state.template);
                //console.log("--------------");
                this.setState({
                    isLoaded: true,
                });
            })
            .catch((error) => {
                if (error === undefined || error.response === undefined) {
                    alert("Oops! Something went wrong");
                    //window.location.href = "/unauthenticated";
                    return;
                }

                if (error.response.status === 401) {
                    alert("You are not authenticated");
                    window.location.href = "/unauthenticated";
                }
            });
    }

    getParameterByName(name, url = window.location.href) {
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return "";
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    validateUser() {
        const userTypes = ["franchise-user", "head-office-user", "broker-user"];
        return userTypes.includes(this.state.user.type) && this.state.user.info !== false;
    }

    render() {
        if (!this.state.isLoaded) {
            return (
                <div className="text-center">
                    <div className="loader"></div>
                </div>
            );
        }
        if (!this.validateUser()) {
            return (
                <Route
                    component={() => {
                        window.location = "https://secure.dominionintranet.ca/login/login.php";
                        return null;
                    }}
                />
            );
        }
        return (
            <UserContext.Provider
                value={{
                    user: this.state.user,
                    updateUser: this.state.updateUser,
                }}
            >
                <TemplateContext.Provider
                    value={{
                        template: this.state.template,
                        updateTemplate: this.state.updateTemplate,
                    }}
                >
                    <div className="App">
                        <Tab.Container defaultActiveKey={this.state.user.type === "head-office-user" ? "contact" : "templates"}>
                            <Container fluid>
                                <Row>
                                    <MobileHeader />
                                    <Sidebar />
                                    <Header />
                                    <Main />
                                </Row>
                            </Container>
                        </Tab.Container>
                    </div>
                </TemplateContext.Provider>
            </UserContext.Provider>
        );
    }
}

//export default withRouter();
export default withRouter(Page);
