export function getCurrentTimeStr() {
    const dateObj = new Date();
    const month = dateObj.getUTCMonth() + 1;
    const day = dateObj.getUTCDate();
    const year = dateObj.getUTCFullYear();
    const time = dateObj.getTime();
    return [year, month, day, time].join("-");
}

export function CopyAsSelect(node) {
    node = document.getElementById(node);

    if (document.body.createTextRange) {
        const range = document.body.createTextRange();
        range.moveToElementText(node);
        range.select();
    } else if (window.getSelection) {
        const selection = window.getSelection();
        const range = document.createRange();
        range.selectNodeContents(node);
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand("copy");
    } else {
        console.warn("Could not select text in node: Unsupported browser.");
    }
}

export function DownloadHtml(id) {
    const targetId = document.getElementById(id);
    if (targetId !== null) {
        const markup = targetId.innerHTML;
        const emailSignature = btoa(unescape(encodeURIComponent(markup))),
            a = document.createElement("a"),
            e = new MouseEvent("click");
        a.download = "email-signature.html";
        a.href = "data:text/html;base64," + emailSignature;
        a.dispatchEvent(e);
    }
}

export function CopySourceCode(id) {
    const targetId = document.getElementById(id);
    if (targetId !== null) {
        const markup = targetId.innerHTML;
        const tempInput = document.createElement("input");
        const body = document.getElementsByTagName("body")[0];
        body.appendChild(tempInput);
        tempInput.setAttribute("value", markup);
        tempInput.select();
        document.execCommand("copy");
        body.removeChild(tempInput);
    }
}
