import React, { Component, Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import { Formik, Field } from "formik";

//My Components
import UserContext from "../../../contexts/UserContext";
import TemplateContext from "../../../contexts/TemplateContext";
import HelperToolTip from "../../utilities/HelperToolTip";

class BrokerAddressInfoForm extends Component {
    static contextType = TemplateContext;

    constructor(props) {
        super(props);
        this.state = { value: "" };
    }

    handleOfficeChange(e, user) {
        let { template, updateTemplate } = this.context;
        let officeId = e.target.value;
        if (!officeId) {
            return;
        } else if (officeId === "0") {
            template.features.office = "";
            updateTemplate(template);
        }

        template.features.office = JSON.parse(JSON.stringify(user.info.franchise.offices[officeId]));
        updateTemplate(template);
    }

    render() {
        let { template } = this.context;
        return (
            <UserContext.Consumer>
                {({ user, updateUser }) => (
                    <Fragment>
                        <Row className="sub-header">
                            <Col>
                                <FormattedMessage id="addressInfoForm.addressInfoSubHeader" defaultMessage="Address Information" />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Formik
                                    initialValues={{
                                        officeId: template.features.office.id ? template.features.office.id : "",
                                    }}
                                    enableReinitialize={true}
                                >
                                    {(props) => {
                                        const { touched, errors, handleChange, handleBlur, handleSubmit } = props;
                                        return (
                                            <form className="mb-4" onSubmit={handleSubmit}>
                                                <Fragment>
                                                    <label className="mt-2" htmlFor="office-id">
                                                        <FormattedMessage id="addressInfoForm.officeAddressLabel" defaultMessage="Office Address" />
                                                        <HelperToolTip translationId="contactFranchiseInfoForm.licenseNameLabel" defaultMessage="License #">
                                                            <FormattedMessage
                                                                id="helperToolTip.brokerInfoFormOfficeAddress"
                                                                defaultMessage="All available office addresses are provided by the franchise owner. If the address you prefer is not in the list, please contact your franchise owner to add the new address."
                                                            />
                                                        </HelperToolTip>
                                                    </label>
                                                    <Field
                                                        as="select"
                                                        id="office-id"
                                                        name="officeId"
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            this.handleOfficeChange(e, user);
                                                        }}
                                                        onBlur={handleBlur}
                                                        className={errors.officeId && touched.officeId ? "select-input error" : "select-input"}
                                                    >
                                                        <FormattedMessage id="addressContactInfoForm.noAddressField">{(txt) => <option value="0">{txt}</option>}</FormattedMessage>

                                                        {Object.entries(user.info.franchise.offices).map(([officeId, office]) => (
                                                            <option key={office.id} value={office.id}>
                                                                {office.addressLineTwo
                                                                    ? office.addressLineTwo + " "
                                                                    : "" + office.streetAddress + " " + office.city + " " + office.provinceCode + " " + office.postalCode}
                                                            </option>
                                                        ))}
                                                    </Field>
                                                </Fragment>
                                            </form>
                                        );
                                    }}
                                </Formik>
                            </Col>
                        </Row>
                    </Fragment>
                )}
            </UserContext.Consumer>
        );
    }
}

export default injectIntl(BrokerAddressInfoForm);
