function initializeTemplate(user, templateType, prefillTemplate = null) {
    const userInfo = user.info;
    const confidentialityWarning = "";
    let template = {
        id: 0,
    };
    if (user.type === "franchise-user") {
        const office = userInfo.offices[Object.keys(userInfo.offices)[0]];
        template.features = {
            type: templateType ? templateType : "FranchiseTemplateOne",
            logoUrl: "",
            website: userInfo.website,
            office: {
                id: office.id,
                addressLineTwo: office.addressLineTwo,
                streetAddress: office.streetAddress,
                city: office.city,
                provinceCode: office.provinceCode,
                postalCode: office.postalCode,
            },
            colorScheme: { primary: "", secondary: "", headers: "", text: "", links: "" },
            socialMediaUrls: { facebook: "", instagram: "", linkedin: "", pinterest: "", twitter: "", youtube: "" },
            bannerUrl: "",
            confidentialityWarning: confidentialityWarning,
        };

        if (prefillTemplate !== null && typeof prefillTemplate !== "undefined") {
            // copy
            template.member = {
                profileImageUrl: prefillTemplate.member.profileImageUrl,
                fullName: prefillTemplate.member.fullName,
                jobTitle: prefillTemplate.member.jobTitle,
                licenseNumber: prefillTemplate.member.licenseNumber,
                phone: prefillTemplate.member.phone,
                mobile: prefillTemplate.member.mobile,
                fax: prefillTemplate.member.fax,
                email: prefillTemplate.member.email,
                website: prefillTemplate.member.website,
                subTeamName: prefillTemplate.member.subTeamName,
                subTeamLogoUrl: prefillTemplate.member.subTeamLogoUrl,
                applicationUrl: prefillTemplate.member.applicationUrl,
                awardImageUrl: prefillTemplate.member.awardImageUrl,
                mortgageToolboxUrl: prefillTemplate.member.mortgageToolboxUrl,
                bannerUrl: prefillTemplate.member.bannerUrl,
            };
        } else {
            // member contains the selected member (i.e. user or broker) of the franchise for the signature template.
            template.member = {
                profileImageUrl: "",
                fullName: "",
                jobTitle: "",
                licenseNumber: "",
                phone: "",
                mobile: "",
                fax: "",
                email: "",
                website: "",
                subTeamName: "",
                subTeamLogoUrl: "",
                applicationUrl: "",
                awardImageUrl: "",
                mortgageToolboxUrl: "",
                bannerUrl: "",
            };
        }
        return template;
    }

    if (prefillTemplate !== null && typeof prefillTemplate !== "undefined") {
        template.features = {
            type: templateType ? templateType : user.type === "broker-user" ? "BrokerTemplateOne" : "HeadOfficeTemplate",
            profileImageUrl: prefillTemplate.features.profileImageUrl,
            fullName: prefillTemplate.features.fullName,
            jobTitle: prefillTemplate.features.jobTitle,
            licenseNumber: prefillTemplate.features.licenseNumber,
            phone: prefillTemplate.features.phone,
            mobile: prefillTemplate.features.mobile,
            fax: prefillTemplate.features.fax,
            email: prefillTemplate.features.email,
            website: prefillTemplate.features.website,
            office: {
                id: prefillTemplate.features.office.id,
                addressLineTwo: prefillTemplate.features.office.addressLineTwo,
                streetAddress: prefillTemplate.features.office.streetAddress,
                city: prefillTemplate.features.office.city,
                provinceCode: prefillTemplate.features.office.provinceCode,
                postalCode: prefillTemplate.features.office.postalCode,
            },
            subTeamName: "",
            subTeamLogoUrl: "",
            socialMediaUrls: {},
            applicationUrl: "https://secure.dominionlending.ca/?app=" + userInfo.brokerLegacyId + "&lang=" + user.lang,
            awardImageUrl: "",
            mortgageToolboxUrl: "https://dlcapp.ca/id/" + user.legacyId + "?lang=" + user.lang,
            bannerUrl: "",
            confidentialityWarning: confidentialityWarning,
            // The object is useful when saving on top of a franchise template.
            franchise: {},
        };
    } else {
        template.features = {
            type: templateType ? templateType : user.type === "broker-user" ? "BrokerTemplateOne" : "HeadOfficeTemplate",
            profileImageUrl: userInfo.profileImageUrl,
            fullName: userInfo.fullName,
            jobTitle: userInfo.jobTitle,
            licenseNumber: userInfo.licenseNumber,
            phone: userInfo.phone,
            mobile: userInfo.mobile,
            fax: userInfo.fax,
            email: userInfo.email,
            website: userInfo.website,
            office: {
                id: userInfo.office.id,
                addressLineTwo: userInfo.office.addressLineTwo,
                streetAddress: userInfo.office.streetAddress,
                city: userInfo.office.city,
                provinceCode: userInfo.office.provinceCode,
                postalCode: userInfo.office.postalCode,
            },
            subTeamName: "",
            subTeamLogoUrl: "",
            socialMediaUrls: {},
            applicationUrl: "https://secure.dominionlending.ca/?app=" + userInfo.brokerLegacyId + "&lang=" + user.lang,
            awardImageUrl: "",
            mortgageToolboxUrl: "https://dlcapp.ca/id/" + user.legacyId + "?lang=" + user.lang,
            bannerUrl: "",
            confidentialityWarning: confidentialityWarning,
            // The object is useful when saving on top of a franchise template.
            franchise: {},
        };
    }

    return template;
}

export default initializeTemplate;
