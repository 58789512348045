import React, { Component, Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import MaskedInput from "react-text-mask";

//My Components
import TemplateContext from "../../../contexts/TemplateContext";
import UserContext from "../../../contexts/UserContext";
import { updateMembersRequest } from "../../api-requests/MemberRequests";
import HelperToolTip from "../../utilities/HelperToolTip";

//Masked Input Regex
const phoneNumberMask = ["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
const faxNumberMask = ["+", "1", " ", "(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];

class BaseFranchiseContactInfoForm extends Component {
    static contextType = TemplateContext;

    constructor(props) {
        super(props);
        this.handleErrorChange = this.handleErrorChange.bind(this);
        this.state = {
            toggle: true,
            initialize: false,
        };
    }

    componentDidMount() {
        let { template } = this.context;
        let user = this.props.user;
        let updateUser = this.props.updateUser;
        let entityId = template.features.office.id;
        let entityType = "office";
        if (entityId === 0) {
            entityType = "franchise";
            entityId = user.info.id;
        }
        updateMembersRequest(entityId, entityType, user, updateUser);
    }

    handleMemberChange(e, user) {
        let { template, updateTemplate } = this.context;
        let memberId = e.target.value;
        let selectedMember = {};
        this.setState({ initialize: true });
        if (memberId in user.info.members) {
            selectedMember = JSON.parse(JSON.stringify(user.info.members[memberId]));
        }
        template.member = selectedMember;
        updateTemplate(template);
    }

    handleTemplateMemberFeatureChange(e) {
        let { template, updateTemplate } = this.context;
        template.member[e.target.name] = e.target.value;
        updateTemplate(template);
    }

    handleErrorChange(e) {
        let { template, updateTemplate } = this.context;
        this.setState({ initialize: false });
        template.member[e.target.name] = "";
        updateTemplate(template);
    }

    render() {
        let { template } = this.context;
        let member = template.member;
        const features = template.features;
        const { intl } = this.props;
        return (
            <UserContext.Consumer>
                {({ user }) => (
                    <Fragment>
                        <Row className="sub-header">
                            <Col>
                                <FormattedMessage id="contactInfoForm.contactInfoSubHeader" defaultMessage="Contact Information" />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Formik
                                    initialValues={{
                                        memberId: member.id ? member.id : 0,
                                        fullName: member.fullName ? member.fullName : "",
                                        jobTitle: member.jobTitle ? member.jobTitle : "",
                                        licenseNumber: member.licenseNumber ? member.licenseNumber : "",
                                        phone: member.phone ? member.phone : "",
                                        mobile: member.mobile ? member.mobile : "",
                                        fax: member.fax ? member.fax : "",
                                        email: member.email ? member.email : "",
                                        website: member.website ? member.website : "",
                                    }}
                                    enableReinitialize={this.state.initialize === true ? true : false}
                                    validationSchema={Yup.object().shape({
                                        fullName: Yup.string()
                                            .matches(
                                                /^[a-zA-ZÀ-ÿ-\s!@#$&()\\-`.+,\"]*$/,
                                                intl.formatMessage({
                                                    id: "contactInfoForm.numbersValidation",
                                                    defaultMessage: "No numbers",
                                                })
                                            )
                                            .required(
                                                intl.formatMessage({
                                                    id: "contactInfoForm.requiredValidation",
                                                    defaultMessage: "Required",
                                                })
                                            ),
                                        jobTitle: Yup.string()
                                            .matches(
                                                /^[a-zA-ZÀ-ÿ-\s!@#$&()\\-`.+,\"]*$/,
                                                intl.formatMessage({
                                                    id: "contactInfoForm.numbersValidation",
                                                    defaultMessage: "No numbers",
                                                })
                                            )
                                            .required(
                                                intl.formatMessage({
                                                    id: "contactInfoForm.requiredValidation",
                                                    defaultMessage: "Required",
                                                })
                                            ),
                                        phone: Yup.string()
                                            .matches(
                                                /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                                                intl.formatMessage({
                                                    id: "contactInfoForm.phoneValidation",
                                                    defaultMessage: "Invalid phone number",
                                                })
                                            )
                                            .notRequired(),
                                        mobile: Yup.string()
                                            .matches(
                                                /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                                                intl.formatMessage({
                                                    id: "contactInfoForm.phoneValidation",
                                                    defaultMessage: "Invalid phone number",
                                                })
                                            )
                                            .notRequired(),
                                        fax: Yup.string()
                                            .matches(
                                                /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                                                intl.formatMessage({
                                                    id: "contactInfoForm.faxValidation",
                                                    defaultMessage: "Invalid fax number",
                                                })
                                            )
                                            .notRequired(),
                                        website: Yup.string()
                                            .matches(
                                                /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                                                intl.formatMessage({
                                                    id: "contactInfoForm.websiteValidation",
                                                    defaultMessage: "Invalid website url",
                                                })
                                            )
                                            .notRequired(),
                                        email: Yup.string()
                                            .email(
                                                intl.formatMessage({
                                                    id: "contactInfoForm.emailValidation",
                                                    defaultMessage: "Email must be a valid email",
                                                })
                                            )
                                            .notRequired(),
                                    })}
                                >
                                    {(props) => {
                                        const { values, touched, errors, handleChange, handleBlur, handleSubmit } = props;
                                        return (
                                            <form className="mb-4" onSubmit={handleSubmit}>
                                                <label className="mt-2" htmlFor="member-id">
                                                    <FormattedMessage id="contactInfoForm.memberListLabel" defaultMessage="List of Team Members" />
                                                    <HelperToolTip translationId="contactInfoForm.memberListLabel" defaultMessage="List of Team Members">
                                                        <FormattedMessage
                                                            id="helperToolTip.franchiseContactFormMemberList"
                                                            defaultMessage="Avaliable team members in this list are determined by address selected in the franchise tab."
                                                        />
                                                    </HelperToolTip>
                                                </label>
                                                <select
                                                    id="member-id"
                                                    type="select"
                                                    name="memberId"
                                                    value={values.memberId}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        this.handleMemberChange(e, user);
                                                    }}
                                                    onBlur={handleBlur}
                                                    disabled={this.state.toggle ? null : "disabled"}
                                                    className={errors.memberId && touched.memberId ? "select-input error" : "select-input"}
                                                >
                                                    <FormattedMessage id="addressContactInfoForm.selectPlaceHolderLabel" tagName="option" defaultMessage="Please Choose">
                                                        {(message) => <option value="0">{message}</option>}
                                                    </FormattedMessage>
                                                    {user.info.members &&
                                                        Object.entries(user.info.members).map(([memberId, member]) => (
                                                            <option key={member.id} value={member.id}>
                                                                {member.fullName}
                                                            </option>
                                                        ))}
                                                </select>
                                                <label className="mt-4" htmlFor="full-name">
                                                    <FormattedMessage id="contactInfoForm.fullNameLabel" defaultMessage="Full Name" />
                                                </label>
                                                <input
                                                    id="full-name"
                                                    type="text"
                                                    name="fullName"
                                                    maxLength="40"
                                                    value={values.fullName}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        this.handleTemplateMemberFeatureChange(e, user);
                                                    }}
                                                    onBlur={(e) => {
                                                        handleBlur(e);

                                                        if (errors.fullName) {
                                                            this.handleErrorChange(e);
                                                        }
                                                    }}
                                                    disabled={this.state.toggle ? null : "disabled"}
                                                    className={errors.fullName && touched.fullName ? "text-input error" : "text-input"}
                                                />
                                                {errors.fullName && touched.fullName ? <div className="input-feedback">{errors.fullName}</div> : null}
                                                <label className="mt-4" htmlFor="job-title">
                                                    <FormattedMessage id="contactInfoForm.jobTitleLabel" defaultMessage="Job Title" />
                                                </label>
                                                <input
                                                    id="job-title"
                                                    type="text"
                                                    name="jobTitle"
                                                    maxLength="50"
                                                    value={values.jobTitle}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        this.handleTemplateMemberFeatureChange(e);
                                                    }}
                                                    onBlur={(e) => {
                                                        handleBlur(e);

                                                        if (errors.jobTitle) {
                                                            this.handleErrorChange(e);
                                                        }
                                                    }}
                                                    disabled={this.state.toggle ? null : "disabled"}
                                                    className={errors.jobTitle && touched.jobTitle ? "text-input error" : "text-input"}
                                                />
                                                {errors.jobTitle && touched.jobTitle ? <div className="input-feedback">{errors.jobTitle}</div> : null}

                                                {(features.office.provinceCode === "ON" || features.office.provinceCode === "SK") && (
                                                    <Fragment>
                                                        <label className="mt-4" htmlFor="broker-license">
                                                            <FormattedMessage id="contactFranchiseInfoForm.licenseNameLabel" defaultMessage="License #" />
                                                            <HelperToolTip translationId="contactFranchiseInfoForm.licenseNameLabel" defaultMessage="License #">
                                                                <FormattedMessage
                                                                    id="helperToolTip.franchiseContactFormLicense"
                                                                    defaultMessage="Please Note: Mortgage professional's license can not be edited in the email signature tool. If a change is required, please do so in the intranet profile."
                                                                />
                                                            </HelperToolTip>
                                                        </label>
                                                        <input
                                                            id="broker-license"
                                                            type="text"
                                                            name="licenseNumber"
                                                            maxLength="20"
                                                            value={values.licenseNumber}
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                this.handleTemplateMemberFeatureChange(e);
                                                            }}
                                                            onBlur={handleBlur}
                                                            readOnly={true}
                                                            disabled={this.state.toggle ? null : "disabled"}
                                                        />
                                                    </Fragment>
                                                )}

                                                {features.office.provinceCode === "BC" && (
                                                    <Fragment>
                                                        <label className="mt-4" htmlFor="broker-license">
                                                            <FormattedMessage id="contactFranchiseInfoForm.licenseNameLabel" defaultMessage="License #" />
                                                        </label>
                                                        <input
                                                            id="broker-license"
                                                            type="text"
                                                            name="licenseNumber"
                                                            maxLength="20"
                                                            value={values.licenseNumber}
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                this.handleTemplateMemberFeatureChange(e);
                                                            }}
                                                            onBlur={handleBlur}
                                                            disabled={this.state.toggle ? null : "disabled"}
                                                        />
                                                    </Fragment>
                                                )}

                                                <label className="mt-4" htmlFor="office-phone">
                                                    <FormattedMessage id="contactInfoForm.officePhoneLabel" defaultMessage="Office Phone" />
                                                </label>
                                                <Field name="phone">
                                                    {({ field }) => (
                                                        <MaskedInput
                                                            {...field}
                                                            mask={phoneNumberMask}
                                                            id="office-phone"
                                                            placeholder="(###) ###-####"
                                                            type="text"
                                                            value={values.phone}
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                this.handleTemplateMemberFeatureChange(e);
                                                            }}
                                                            onBlur={(e) => {
                                                                handleBlur(e);

                                                                if (errors.phone) {
                                                                    this.handleErrorChange(e);
                                                                }
                                                            }}
                                                            disabled={this.state.toggle ? null : "disabled"}
                                                            className={errors.phone && touched.phone ? "text-input error" : "text-input"}
                                                        />
                                                    )}
                                                </Field>
                                                {errors.phone && touched.phone && <div className="input-feedback">{errors.phone}</div>}
                                                <label className="mt-4" htmlFor="cell-phone">
                                                    <FormattedMessage id="contactInfoForm.cellPhoneLabel" defaultMessage="Cell Phone" />
                                                </label>
                                                <Field name="mobile">
                                                    {({ field }) => (
                                                        <MaskedInput
                                                            {...field}
                                                            mask={phoneNumberMask}
                                                            id="cell-phone"
                                                            placeholder="(###) ###-####"
                                                            type="text"
                                                            value={values.mobile}
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                this.handleTemplateMemberFeatureChange(e);
                                                            }}
                                                            onBlur={(e) => {
                                                                handleBlur(e);

                                                                if (errors.mobile) {
                                                                    this.handleErrorChange(e);
                                                                }
                                                            }}
                                                            disabled={this.state.toggle ? null : "disabled"}
                                                            className={errors.mobile && touched.mobile ? "text-input error" : "text-input"}
                                                        />
                                                    )}
                                                </Field>
                                                {errors.mobile && touched.mobile && <div className="input-feedback">{errors.mobile}</div>}
                                                <label className="mt-4" htmlFor="fax">
                                                    <FormattedMessage id="contactInfoForm.faxLabel" defaultMessage="Fax" />
                                                </label>
                                                <Field name="fax">
                                                    {({ field }) => (
                                                        <MaskedInput
                                                            {...field}
                                                            mask={faxNumberMask}
                                                            id="fax"
                                                            placeholder="+1 (###) ###-####"
                                                            type="text"
                                                            value={values.fax}
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                this.handleTemplateMemberFeatureChange(e);
                                                            }}
                                                            onBlur={(e) => {
                                                                handleBlur(e);

                                                                if (errors.fax) {
                                                                    this.handleErrorChange(e);
                                                                }
                                                            }}
                                                            disabled={this.state.toggle ? null : "disabled"}
                                                            className={errors.fax && touched.fax ? "text-input error" : "text-input"}
                                                        />
                                                    )}
                                                </Field>
                                                {errors.fax && touched.fax && <div className="input-feedback">{errors.fax}</div>}
                                                <label className="mt-4" htmlFor="email">
                                                    <FormattedMessage id="contactInfoForm.emailLabel" defaultMessage="Email" />
                                                </label>
                                                <input
                                                    id="email"
                                                    type="email"
                                                    name="email"
                                                    maxLength="50"
                                                    value={values.email}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        this.handleTemplateMemberFeatureChange(e);
                                                    }}
                                                    onBlur={(e) => {
                                                        handleBlur(e);

                                                        if (errors.email) {
                                                            this.handleErrorChange(e);
                                                        }
                                                    }}
                                                    disabled={this.state.toggle ? null : "disabled"}
                                                    className={errors.email && touched.email ? "text-input error" : "text-input"}
                                                />
                                                {errors.email && touched.email && <div className="input-feedback">{errors.email}</div>}
                                                <label className="mt-4" htmlFor="broker-website">
                                                    <FormattedMessage id="contactFranchiseInfoForm.websiteLabel" defaultMessage="Website" />
                                                </label>
                                                <input
                                                    id="broker-website"
                                                    type="text"
                                                    name="website"
                                                    maxLength="50"
                                                    value={values.website}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        this.handleTemplateMemberFeatureChange(e);
                                                    }}
                                                    onBlur={(e) => {
                                                        handleBlur(e);

                                                        if (errors.website) {
                                                            this.handleErrorChange(e);
                                                        }
                                                    }}
                                                    disabled={this.state.toggle ? null : "disabled"}
                                                    className={errors.website && touched.website ? "text-input error" : "text-input"}
                                                />
                                                {errors.website && touched.website && <div className="input-feedback">{errors.website}</div>}
                                            </form>
                                        );
                                    }}
                                </Formik>
                            </Col>
                        </Row>
                    </Fragment>
                )}
            </UserContext.Consumer>
        );
    }
}

const FranchiseContactInfoForm = (props) => <UserContext.Consumer>{({ user, updateUser }) => <BaseFranchiseContactInfoForm {...props} user={user} updateUser={updateUser} />}</UserContext.Consumer>;

export default injectIntl(FranchiseContactInfoForm);
