import React, { Component, Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import { Formik } from "formik";
import * as Yup from "yup";

//My Components
import UserContext from "../../../contexts/UserContext";
import TemplateContext from "../../../contexts/TemplateContext";
import { updateMembersRequest } from "../../api-requests/MemberRequests";
import HelperToolTip from "../../utilities/HelperToolTip";

class officeIdInfoForm extends Component {
    static contextType = TemplateContext;

    handleOfficeChange(e, values, user, updateUser) {
        let { template, updateTemplate } = this.context;
        let entityId = e.target.value;
        let entityType = "office";
        if (entityId === 0) {
            entityType = "franchise";
            template.features.office = {
                id: entityId,
                addressLineTwo: values.addressLineTwo,
                streetAddress: values.streetAddress,
                city: values.city,
                provinceCode: values.provinceCode,
                postalCode: values.postalCode,
            };
            entityId = user.info.id;
        } else if (entityId === "") {
            template.features.office = "";
            updateTemplate(template);
        } else {
            template.features.office = JSON.parse(JSON.stringify(user.info.offices[entityId]));
            updateTemplate(template);
        }

        updateMembersRequest(entityId, entityType, user, updateUser);
    }

    render() {
        let { template } = this.context;
        let officeInfo = template.features.office;

        return (
            <UserContext.Consumer>
                {({ user, updateUser }) => (
                    <Fragment>
                        <Row className="sub-header">
                            <Col>
                                <FormattedMessage id="addressInfoForm.addressInfoSubHeader" defaultMessage="Address Information" />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Formik
                                    initialValues={{
                                        officeId: officeInfo.id ? officeInfo.id : "",
                                        streetAddress: officeInfo.streetAddress ? officeInfo.streetAddress : "",
                                        addressLineTwo: officeInfo.addressLineTwo ? officeInfo.addressLineTwo : "",
                                        city: officeInfo.city ? officeInfo.city : "",
                                        provinceCode: officeInfo.provinceCode ? officeInfo.provinceCode : "",
                                        postalCode: officeInfo.postalCode ? officeInfo.postalCode : "",
                                    }}
                                    enableReinitialize={true}
                                    validationSchema={Yup.object().shape({
                                        officeId: Yup.string().required(),
                                    })}
                                >
                                    {(props) => {
                                        const { values, touched, errors, handleChange, handleBlur, handleSubmit } = props;
                                        return (
                                            <form className="mb-4" onSubmit={handleSubmit}>
                                                <Fragment>
                                                    <label className="mt-2" htmlFor="office-id">
                                                        <FormattedMessage id="addressInfoForm.officeAddressLabel" defaultMessage="Office Address" />
                                                        <HelperToolTip translationId="addressInfoForm.officeAddressLabel" defaultMessage="Office Address">
                                                            <FormattedMessage
                                                                id="helperToolTip.franchiseAddressFormFranchiseAddress"
                                                                defaultMessage="The office address selected determines which list of team members will be available in the team member list on the contact tab."
                                                            />
                                                        </HelperToolTip>
                                                    </label>
                                                    <select
                                                        id="office-id"
                                                        type="select"
                                                        name="officeId"
                                                        value={values.officeId}
                                                        onChange={(e) => {
                                                            handleChange(e);
                                                            this.handleOfficeChange(e, values, user, updateUser);
                                                        }}
                                                        onBlur={handleBlur}
                                                        className={errors.officeId && touched.officeId ? "select-input error" : "select-input"}
                                                    >
                                                        <FormattedMessage id="addressContactInfoForm.selectPlaceHolderLabel">{(txt) => <option value="0">{txt}</option>}</FormattedMessage>

                                                        <FormattedMessage id="addressContactInfoForm.noAddressField">{(txt) => <option value="">{txt}</option>}</FormattedMessage>

                                                        {Object.entries(user.info.offices).map(([officeId, office]) => (
                                                            <option key={office.id} value={office.id}>
                                                                {office.addressLineTwo
                                                                    ? office.addressLineTwo + " "
                                                                    : "" + office.streetAddress + " " + office.city + " " + office.provinceCode + " " + office.postalCode}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </Fragment>
                                            </form>
                                        );
                                    }}
                                </Formik>
                            </Col>
                        </Row>
                    </Fragment>
                )}
            </UserContext.Consumer>
        );
    }
}

export default injectIntl(officeIdInfoForm);
