import React, { Component, Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Formik } from "formik";

//My Components
import Upload from "../Upload";
import TemplateContext from "../../contexts/TemplateContext";
import UserContext from "../../contexts/UserContext";
import { LanguageContext } from "../../contexts/LanguageProvider";
import HelperToolTip from "../utilities/HelperToolTip";

//Default Images
import DefaultSubTeamLogoEn from "../../images/sub-team-logo-default.png";
import DefaultSubTeamLogoFr from "../../images/sub-team-logo-default-fr.png";
import ResponsiveLogoHelper from "../../images/responsive-logos.gif";

class SubTeamInfoForm extends Component {

    static contextType = TemplateContext;

    constructor(props) {
        super(props);
        this.state = {
            toggle: false,
        };
        this.handleClick = this.handleClick.bind(this);
    }
    handleClick(user, language) {
        this.setState((state) => ({
            toggle: !state.toggle,
        }));
        let { template, updateTemplate } = this.context;
        template.features.subTeamName = null;

        if (this.state.toggle !== true) {
            if (language.locale === "fr") {
                template.features.subTeamLogoUrl = DefaultSubTeamLogoFr;
            } else {
                template.features.subTeamLogoUrl = DefaultSubTeamLogoEn;
            }
        } else {
            template.features.subTeamLogoUrl = null;
        }

        if (user.type === "franchise-user") {
            template.member.subTeamName = null;

            if (this.state.toggle !== true) {
                if (language.locale === "fr") {
                    template.member.subTeamLogoUrl = DefaultSubTeamLogoFr;
                } else {
                    template.member.subTeamLogoUrl = DefaultSubTeamLogoEn;
                }
            } else {
                template.member.subTeamLogoUrl = null;
            }
        }
        updateTemplate(template);
    }

    handleSubTeamNameChange(e, user) {
        let { template, updateTemplate } = this.context;
        if (user.type === "franchise-user") {
            template.member.subTeamName = e.target.value;
        } else {
            template.features.subTeamName = e.target.value;
        }
        updateTemplate(template);
    }

    render() {
        let { template } = this.context;

        return (
            <UserContext.Consumer>
                {({ user, updateUser }) => (
                    <LanguageContext.Consumer>
                        {(language) => (
                            <Fragment>

                                <Row className={(user.info.hasOwnProperty('franchise') && user.info.franchise.hasOwnProperty('legacy_id') && user.info.franchise.legacy_id === 83) ? "d-none" : "sub-header"}>
                                    <Col>
                                        <FormattedMessage id="subTeamInfoForm.SubTeamInfoSubHeader" defaultMessage="Sub-team Information" />
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                defaultChecked={this.state.toggle}
                                                onClick={(e) => {
                                                    this.handleClick(user, language);
                                                }}
                                            />
                                            <span className="slider round" />
                                        </label>
                                    </Col>
                                </Row>

                                <Row className={this.state.toggle ? null : "d-none"}>
                                    <Col>
                                        <Formik
                                            initialValues={{
                                                subTeamName: user.type === "franchise-user" ? template.member.subTeamName ?? "" : template.features.subTeamName ?? "",
                                            }}
                                            enableReinitialize={true}
                                        >
                                            {(props) => {
                                                const { values, touched, errors, handleChange, handleBlur, handleSubmit } = props;
                                                return (
                                                    <form className="mb-4" onSubmit={handleSubmit}>
                                                        <label className="mt-2" htmlFor="sub-team-name">
                                                            <FormattedMessage id="subTeamInfoForm.NameLabel" defaultMessage="Sub-team Name" />
                                                        </label>
                                                        <input
                                                            id="sub-team-name"
                                                            type="text"
                                                            name="subTeamName"
                                                            maxLength="50"
                                                            value={values.subTeamName}
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                this.handleSubTeamNameChange(e, user);
                                                            }}
                                                            onBlur={handleBlur}
                                                            disabled={this.state.toggle ? null : "disabled"}
                                                            className={errors.subTeamName && touched.subTeamName ? "text-input error" : "text-input"}
                                                        />
                                                        {errors.subTeamName && touched.subTeamName && <div className="input-feedback">{errors.subTeamName}</div>}

                                                        <label className="mt-4" htmlFor="sub-team-logo">
                                                            <FormattedMessage id="subTeamInfoForm.LogoLabel" defaultMessage="Logo" />
                                                            <HelperToolTip translationId="subTeamInfoForm.LogoLabel" defaultMessage="Logo">
                                                                <FormattedMessage
                                                                    id="helperToolTip.subTeamFormLogo"
                                                                    defaultMessage="Accepted file formats are JPG, JPEG, or PNG with an upload limit of 1MB. For best results, please make sure to use your responsive logo (icon). For example, use the logo style for mobile device:"
                                                                />
                                                                <img src={ResponsiveLogoHelper} width="200" style={{ width: "100%" }} alt="Responsive Logo" />
                                                            </HelperToolTip>
                                                        </label>

                                                        <Upload imageType="subTeamLogoUrl" />
                                                    </form>
                                                );
                                            }}
                                        </Formik>
                                    </Col>
                                </Row>
                            </Fragment>
                        )}
                    </LanguageContext.Consumer>
                )}
            </UserContext.Consumer>
        );
    }
}

export default SubTeamInfoForm;
