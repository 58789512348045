import React, { Component } from "react";
import { OverlayTrigger, Popover, Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

class HelperToolTip extends Component {
    render() {
        const helperPopUp = (
            <Popover className="popover">
                <Popover.Title as="h4">
                    <FormattedMessage id={this.props.translationId} defaultMessage={this.props.defaultMessage} />
                </Popover.Title>
                <Popover.Content>{this.props.children}</Popover.Content>
            </Popover>
        );
        return (
            <OverlayTrigger placement="bottom" overlay={helperPopUp}>
                <Button className="helper-button" variant="primary">
                    <i className="fas fa-question-circle"></i>
                </Button>
            </OverlayTrigger>
        );
    }
}

export default HelperToolTip;
