import React, { Fragment, useContext, useState } from "react";
import { Modal, Button, Container, Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import axios from "axios";

//My Components
import UserContext from "../../contexts/UserContext";

export const DeleteTemplateModal = (props) => {
    const { user, updateUser } = useContext(UserContext);
    const selectedTemplateId = props.selectedTemplateId;

    const [errorMsg, setErrorMsg] = useState("");
    const [smShow, setSmShow] = useState(false);
    const handleClose = () => setSmShow(false);
    const handleShow = () => setSmShow(true);
    const handleDelete = () => {
        const formData = new FormData();
        formData.append("id", selectedTemplateId);
        let url = process.env.REACT_APP_BACKEND_URL + "/signature/template/delete";

        axios({
            url: url,
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data: formData,
        })
            .then((response) => {
                if (response.data.error) {
                    for (const field in response.data.error) {
                        setErrorMsg(response.data.error[field]);
                    }
                } else {
                    delete user.info.signatureTemplates[selectedTemplateId];
                    updateUser(user);
                }
            })
            .catch((error) => {
                console.log(error);
                setErrorMsg("Deletion failed, Please try again");
            });
    };

    return (
        <Fragment>
            <div className="status delete" onClick={handleShow}>
                <span>
                    <FormattedMessage id="addOnComponent.deleteLabel" defaultMessage="Delete" />
                </span>

                <i className="fas fa-minus-circle"></i>
            </div>

            <Modal className="delete-template-modal" size="sm" show={smShow} onHide={handleClose} backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered>
                <Button className="modal-button" onClick={handleClose}>
                    <i className="far fa-times"></i>
                </Button>
                <Modal.Body className="show-grid">
                    <Container>
                        <Row>
                            <Col md={12}>
                                <i className="far fa-check-circle"></i>
                                <p>
                                    <FormattedMessage id="deleteTemplateModal.areYouSure" defaultMessage="Are you sure you want to delete template ID" />: {selectedTemplateId}?
                                </p>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="modal-btns" md={12}>
                                <Button className="btn btn-negative" onClick={handleClose}>
                                    <FormattedMessage id="deleteTemplateModal.cancelBtn" defaultMessage="Cancel" />
                                </Button>
                                <Button onClick={handleDelete} variant="primary">
                                    <FormattedMessage id="deleteTemplateModal.deleteBtn" defaultMessage="Delete Template" />
                                </Button>
                            </Col>
                            <Col className="alert-danger">{errorMsg}</Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </Fragment>
    );
};

export default DeleteTemplateModal;
