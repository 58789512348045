import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import LanguageProvider from "./contexts/LanguageProvider";

ReactDOM.render(
    <LanguageProvider>
        <App />
    </LanguageProvider>,
    document.getElementById("root")
);

serviceWorker.unregister();
