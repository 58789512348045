import React, { Fragment, useContext } from "react";
import { LanguageContext } from "../../contexts/LanguageProvider";

export const LanguageSwitch = () => {
    const context = useContext(LanguageContext);
    return (
        <Fragment>
            {context.locale !== "fr" ? (
                <button onClick={context.selectLang} value="fr" className="dropdown-item">
                    French
                </button>
            ) : (
                <button onClick={context.selectLang} value="en" className="dropdown-item">
                    English
                </button>
            )}
        </Fragment>
    );
};

export default LanguageSwitch;
