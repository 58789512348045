import React, { Component } from "react";
import { Col, Container, Row, Tab } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

//My Components
import { LanguageContext } from "../contexts/LanguageProvider";
import MainNav from "./navs/MainNav";
import TemplatesTab from "./tabs/TemplatesTab";
import ContactInfoTab from "./tabs/ContactInfoTab";
import FranchiseInfoTab from "./tabs/FranchiseInfoTab";
import AddOnTab from "./tabs/AddOnTab";
import UserContext from "../contexts/UserContext";

class Sidebar extends Component {
    render() {
        return (
            <LanguageContext.Consumer>
                {(language) => (
                    <aside id="sidebar" className="col-lg-3 order-lg-1 order-2">
                        <div id="logo-container">
                            <div id="company-logo" className={language.locale === "fr" ? "fr" : "en"}></div>
                        </div>
                        <MainNav />
                        <UserContext.Consumer>
                            {({ user, updateUser }) => (
                                <Tab.Content id="main-tabs" className={user.type}>
                                    <Tab.Pane eventKey="templates">
                                        <Container>
                                            <Row>
                                                <Col>
                                                    <h2>
                                                        <FormattedMessage id="sidebar.templatesHeader" defaultMessage="Templates" />
                                                    </h2>
                                                </Col>
                                            </Row>
                                            <TemplatesTab />
                                        </Container>
                                    </Tab.Pane>
                                    {user.type === "franchise-user" && (
                                        <Tab.Pane eventKey="franchise">
                                            <Container>
                                                <Row>
                                                    <Col>
                                                        <h2>
                                                            <FormattedMessage id="sidebar.franchiseHeader" defaultMessage="Franchise" />
                                                        </h2>
                                                    </Col>
                                                </Row>
                                                <FranchiseInfoTab />
                                            </Container>
                                        </Tab.Pane>
                                    )}
                                    <Tab.Pane eventKey="contact">
                                        <Container>
                                            <Row>
                                                <Col>
                                                    <h2>
                                                        <FormattedMessage id="sidebar.contactHeader" defaultMessage="Contact" />
                                                    </h2>
                                                </Col>
                                            </Row>
                                            <ContactInfoTab />
                                        </Container>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="add-on">
                                        <Container>
                                            <Row>
                                                <Col>
                                                    <h2>
                                                        <FormattedMessage id="sidebar.addonHeader" defaultMessage="Add-on" />
                                                    </h2>
                                                </Col>
                                            </Row>
                                            <AddOnTab />
                                        </Container>
                                    </Tab.Pane>
                                </Tab.Content>
                            )}
                        </UserContext.Consumer>

                        <a
                            id="back-to-intranet"
                            className="d-lg-block d-md-none d-none"
                            href={language.locale === "fr" ? "https://secure.dominionintranet.ca/bienvenue/" : "https://secure.dominionintranet.ca/welcome/"}
                            rel="noopener noreferrer"
                        >
                            <i className="fas fa-arrow-to-left"></i> <FormattedMessage id="sidebar.backToIntranet" defaultMessage="Back to Intranet" />
                        </a>
                    </aside>
                )}
            </LanguageContext.Consumer>
        );
    }
}

export default Sidebar;
