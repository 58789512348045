import React, { Component, Fragment } from "react";
import { Row, Col, Nav, Tab } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

//My Components
import UserContext from "../../contexts/UserContext";
import TemplatesNav from "../navs/TemplatesNav";
import TemplateContext from "../../contexts/TemplateContext";
import DeleteTemplateModal from "../utilities/DeleteTemplateModal";

class TemplatesTab extends Component {
    static contextType = TemplateContext;

    handleUserTemplateSelect(templateId, user) {
        let { template, updateTemplate } = this.context;
        // Deep copy the user's selected signature template to avoid changing the original object.
        const selectedTemplate = JSON.parse(JSON.stringify(user.info.signatureTemplates[templateId]));
        template.features = selectedTemplate.features;
        template.id = selectedTemplate.id;
        template.isFranchiseChild = false;
        updateTemplate(template);
    }

    /**
     * Update non-franchise user template with its selected franchise template info.
     */
    handleUserFranchiseTemplateSelect(FranchiseTemplateId, user) {
        let { template, updateTemplate } = this.context;
        // Deep copy the user franchise's selected signature template (not pass by reference) to avoid changing the original object.
        const franchiseTemplate = JSON.parse(JSON.stringify(user.info.franchise.signatureTemplates[FranchiseTemplateId]));

        // Reset id to 0 to avoid updating franchise template in broker profile.
        template.id = 0;

        // Setting template-related Features
        template.features.type = franchiseTemplate.features.type;
        template.features.bannerUrl = franchiseTemplate.features.bannerUrl;
        template.features.socialMediaUrls = franchiseTemplate.features.socialMediaUrls;
        template.features.confidentialityWarning = franchiseTemplate.features.confidentialityWarning;
        template.features.office = franchiseTemplate.features.office;
        template.features.customBannerUrl = franchiseTemplate.features.customBannerUrl;

        //Setting franchise-related features
        template.features.franchise.colorScheme = franchiseTemplate.features.colorScheme;
        template.features.franchise.website = franchiseTemplate.features.website;
        template.features.franchise.logoUrl = franchiseTemplate.features.logoUrl;
        template.isFranchiseChild = true;
        updateTemplate(template);
    }

    render() {
        return (
            <UserContext.Consumer>
                {({ user, updateUser }) => (
                    <Fragment>
                        <Row>
                            <Col>
                                <Tab.Container defaultActiveKey="new-templates">
                                    <Nav id="templates-nav" className="mt-2 mb-2" justify variant="pills">
                                        <Nav.Item>
                                            <Nav.Link eventKey="new-templates">
                                                <FormattedMessage id="templateTabs.newButton" defaultMessage="New" />
                                            </Nav.Link>
                                        </Nav.Item>

                                        <Nav.Item>
                                            <Nav.Link eventKey="saved-templates">
                                                <FormattedMessage id="templateTabs.savedButton" defaultMessage="Saved" />
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>

                                    <Tab.Content id="templates-tabs">
                                        <Tab.Pane eventKey="new-templates">
                                            <TemplatesNav />
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="saved-templates">
                                            <Nav className="flex-column" onSelect={(templateId) => this.handleUserTemplateSelect(templateId, user)}>
                                                <h4>
                                                    <FormattedMessage id="templateTabs.personalTemplates" defaultMessage="Personal Templates" />
                                                </h4>
                                                {user.info.signatureTemplates &&
                                                    Object.entries(user.info.signatureTemplates).map(([templateId, signatureTemplate]) => (
                                                        <Nav.Item key={templateId}>
                                                            <Nav.Link eventKey={templateId}>
                                                                <div className="saved-email-signature-template">
                                                                    <Row>
                                                                        <Col>
                                                                            <b>
                                                                                <FormattedMessage id="templateTabs.templateId" defaultMessage="Tempalte ID" />:
                                                                            </b>{" "}
                                                                            {templateId}
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className="updated">
                                                                            <span>{signatureTemplate.updatedAt}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <DeleteTemplateModal selectedTemplateId={signatureTemplate.id} />
                                                                </div>
                                                            </Nav.Link>
                                                        </Nav.Item>
                                                    ))}
                                            </Nav>
                                            {user.type !== "franchise-user" && (
                                                <Nav className="flex-column" onSelect={(templateId) => this.handleUserFranchiseTemplateSelect(templateId, user)}>
                                                    <h4 className="mt-2">
                                                        <FormattedMessage id="templateTabs.franchiseTemplates" defaultMessage="Franchise Templates" />
                                                    </h4>
                                                    {user.info.franchise.signatureTemplates &&
                                                        Object.entries(user.info.franchise.signatureTemplates).map(([templateId, signatureTemplate]) => (
                                                            <Nav.Item key={templateId}>
                                                                <Nav.Link eventKey={templateId}>
                                                                    <div className="saved-email-signature-template">
                                                                        <Row>
                                                                            <Col>
                                                                                <b>
                                                                                    <FormattedMessage id="templateTabs.templateId" defaultMessage="Tempalte ID" />:
                                                                                </b>{" "}
                                                                                {templateId}
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col className="updated">
                                                                                <span>{signatureTemplate.updatedAt}</span>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </Nav.Link>
                                                            </Nav.Item>
                                                        ))}
                                                </Nav>
                                            )}
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </Col>
                        </Row>
                    </Fragment>
                )}
            </UserContext.Consumer>
        );
    }
}

export default TemplatesTab;
