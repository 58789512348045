import React, { Fragment, useContext } from "react";
import TemplateContext from "../../../contexts/TemplateContext";
import { LanguageContext } from "../../../contexts/LanguageProvider";

export const HeadOfficeTemplate = () => {
    const { template } = useContext(TemplateContext);
    const features = template.features;

    return (
        <Fragment>
            <LanguageContext.Consumer>
                {(language) => (
                    <table cellSpacing="0" border="0" width="485" style={{ wordBreak: "break-all" }}>
                        <tbody>
                            <tr>
                                <td>
                                    <table
                                        cellSpacing="5"
                                        cellPadding="0"
                                        border="0"
                                        width="485"
                                        style={{
                                            borderCollapse: "separate",
                                        }}
                                    >
                                        <tbody>
                                            <tr
                                                style={{
                                                    fontFamily: "Arial",
                                                }}
                                            >
                                                <td valign="left">
                                                    <font
                                                        style={{
                                                            fontSize: "14pt",
                                                            fontFamily: "Arial",
                                                            color: "#29c4f0",
                                                            textTransform: "uppercase",
                                                            margin: "2px 0 5px 0",
                                                            fontWeight: "400",
                                                        }}
                                                    >
                                                        {features.fullName ? features.fullName : "Full Name"}
                                                    </font>
                                                    <br />

                                                    <font
                                                        style={{
                                                            fontSize: "11.5pt",
                                                            fontFamily: "Arial",
                                                            color: "#00377a",
                                                            margin: "0",
                                                            fontWeight: "200",
                                                        }}
                                                    >
                                                        {features.jobTitle ? features.jobTitle : "Position Title"}
                                                    </font>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <table
                                        cellSpacing="5"
                                        cellPadding="0"
                                        border="0"
                                        width="485"
                                        style={{
                                            borderCollapse: "separate",
                                        }}
                                    >
                                        <tbody>
                                            {features.phone && (
                                                <tr
                                                    style={{
                                                        fontSize: "8.5pt",
                                                        fontFamily: "Arial",
                                                        fontWeight: "400",
                                                        color: "#00377a",
                                                        margin: "0",
                                                    }}
                                                >
                                                    <td style={{ margin: 0 }}>
                                                        <font
                                                            style={{
                                                                fontSize: "8.5pt",
                                                                fontFamily: "Arial",
                                                                color: "#00377a",
                                                                margin: "0",
                                                            }}
                                                        >
                                                            <strong
                                                                style={{
                                                                    fontWeight: "900",
                                                                }}
                                                            >
                                                                T.
                                                            </strong>

                                                            <a
                                                                href={"tel:" + features.phone + (features.extension ? "," + features.extension : "")}
                                                                rel="noopener noreferrer"
                                                                style={{
                                                                    color: "#00377a",
                                                                    textDecoration: "underline",
                                                                }}
                                                            >
                                                                {features.phone} {features.extension ? " x " + features.extension : null}
                                                            </a>
                                                        </font>
                                                    </td>
                                                </tr>
                                            )}

                                            {features.mobile && (
                                                <tr
                                                    style={{
                                                        fontSize: "8.5pt",
                                                        fontFamily: "Arial",
                                                        fontWeight: "400",
                                                        color: "#00377a",
                                                        margin: "0",
                                                    }}
                                                >
                                                    <td style={{ margin: 0 }}>
                                                        <font
                                                            style={{
                                                                fontSize: "8.5pt",
                                                                fontFamily: "Arial",
                                                                color: "#00377a",
                                                                margin: "0",
                                                            }}
                                                        >
                                                            <strong
                                                                style={{
                                                                    fontWeight: "900",
                                                                }}
                                                            >
                                                                C.
                                                            </strong>

                                                            <a
                                                                href={"tel:" + features.mobile}
                                                                rel="noopener noreferrer"
                                                                style={{
                                                                    color: "#00377a",
                                                                    textDecoration: "underline",
                                                                }}
                                                            >
                                                                {features.mobile}
                                                            </a>
                                                        </font>
                                                    </td>
                                                </tr>
                                            )}

                                            {features.email && (
                                                <tr
                                                    style={{
                                                        fontSize: "8.5pt",
                                                        fontFamily: "Arial",
                                                        fontWeight: "400",
                                                        color: "#00377a",
                                                        margin: "0",
                                                    }}
                                                >
                                                    <td style={{ margin: 0 }}>
                                                        <font
                                                            style={{
                                                                fontSize: "8.5pt",
                                                                fontFamily: "Arial",
                                                                color: "#00377a",
                                                                margin: "0",
                                                            }}
                                                        >
                                                            <strong
                                                                style={{
                                                                    fontWeight: "900",
                                                                }}
                                                            >
                                                                E.
                                                            </strong>

                                                            <a
                                                                href={"mailto:" + features.email}
                                                                rel="noopener noreferrer"
                                                                style={{
                                                                    color: "#00377a",
                                                                    textDecoration: "underline",
                                                                }}
                                                            >
                                                                {features.email}
                                                            </a>
                                                        </font>
                                                    </td>
                                                </tr>
                                            )}

                                            <tr
                                                style={{
                                                    fontSize: "8.5pt",
                                                    fontFamily: "Arial",
                                                    fontWeight: "400",
                                                    color: "#00377a",
                                                    margin: "0",
                                                }}
                                            >
                                                <td>
                                                    <font
                                                        style={{
                                                            fontSize: "8.5pt",
                                                            fontFamily: "Arial",
                                                            color: "#00377a",
                                                            margin: "0",
                                                        }}
                                                    >
                                                        <strong
                                                            style={{
                                                                fontWeight: "900",
                                                            }}
                                                        >
                                                            W.
                                                        </strong>

                                                        <a
                                                            href={language.locale === "fr" ? "https://www.hypothecairesdominion.ca/" : "https://dominionlending.ca/"}
                                                            rel="noopener noreferrer"
                                                            style={{
                                                                color: "#00377a",
                                                                textDecoration: "underline",
                                                            }}
                                                            target="_blank"
                                                        >
                                                            {language.locale === "fr" ? "hypothecairesdominion.ca" : "dominionlending.ca"}
                                                        </a>
                                                    </font>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <table
                                        cellSpacing="5"
                                        cellPadding="0"
                                        border="0"
                                        width="485"
                                        style={{
                                            borderCollapse: "separate",
                                        }}
                                    >
                                        <tbody>
                                            <tr>
                                                <td valign="left">
                                                    <a
                                                        href={language.locale === "fr" ? "https://www.hypothecairesdominion.ca/" : "https://dominionlending.ca/"}
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                    >
                                                        <img
                                                            alt={language.locale === "fr" ? "Centres Hypothécaires Dominion" : "Dominion Lending Centres"}
                                                            border="0"
                                                            width="127"
                                                            height="60"
                                                            src={
                                                                language.locale === "fr"
                                                                    ? "https://centralhost.dominionlending.ca/signatures/head-office/dlc-logo-fr.jpg"
                                                                    : "https://centralhost.dominionlending.ca/signatures/head-office/dlc-logo-en.jpg"
                                                            }
                                                        />
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <table
                                        cellSpacing="5"
                                        cellPadding="0"
                                        border="0"
                                        width="100"
                                        style={{
                                            borderCollapse: "separate",
                                        }}
                                    >
                                        <tbody>
                                            <tr>
                                                <td width="25" valign="left">
                                                    <a href="https://www.facebook.com/DominionLendingCentresCanada" rel="noopener noreferrer" target="_blank">
                                                        <img
                                                            alt={language.locale === "fr" ? "Aimez-nous sur Facebook Centres Hypothécaires Dominion" : "Like Us on Facebook Dominion Lending Centres"}
                                                            border="0"
                                                            width="24"
                                                            height="20"
                                                            src="https://centralhost.dominionlending.ca/signatures/head-office/facebook-icon.png"
                                                        />
                                                    </a>
                                                </td>

                                                <td width="25" valign="left">
                                                    <a href="https://www.linkedin.com/company/dominion-lending-centres" rel="noopener noreferrer" target="_blank">
                                                        <img
                                                            alt={language.locale === "fr" ? "LinkedIn Centres Hypothécaires Dominion" : "LinkedIn Dominion Lending Centres"}
                                                            border="0"
                                                            width="24"
                                                            height="20"
                                                            src="https://centralhost.dominionlending.ca/signatures/head-office/linkedin-icon.png"
                                                        />
                                                    </a>
                                                </td>

                                                <td width="25" valign="left">
                                                    <a href="https://twitter.com/DLCCanadaInc" rel="noopener noreferrer" target="_blank">
                                                        <img
                                                            alt={language.locale === "fr" ? "Suivez-nous sur Twitter Centres Hypothécaires Dominion" : "Follow us on Twitter Dominion Lending Centres"}
                                                            border="0"
                                                            width="24"
                                                            height="20"
                                                            src="https://centralhost.dominionlending.ca/signatures/head-office/twitter-icon.png"
                                                        />
                                                    </a>
                                                </td>

                                                <td width="25" valign="left">
                                                    <a href="https://www.youtube.com/DLCcanada" rel="noopener noreferrer" target="_blank">
                                                        <img
                                                            alt={language.locale === "fr" ? "Abonnez-vous aux centres de crédit YouTube Dominion" : "Subscribe on YouTube Dominion Lending Centres"}
                                                            border="0"
                                                            width="24"
                                                            height="20"
                                                            src="https://centralhost.dominionlending.ca/signatures/head-office/youtube-icon.png"
                                                        />
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <table
                                        cellSpacing="5"
                                        cellPadding="0"
                                        border="0"
                                        width="485"
                                        style={{
                                            borderCollapse: "separate",
                                        }}
                                    >
                                        <tbody>
                                            <tr>
                                                <td
                                                    style={{
                                                        fontSize: "6.0pt",
                                                        fontFamily: "Arial",
                                                        color: "#00377a",
                                                    }}
                                                    valign="left"
                                                >
                                                    <a
                                                        href={language.locale === "fr" ? "https://joignezvousanous.com" : "https://joinusnow.ca"}
                                                        rel="noopener noreferrer"
                                                        style={{
                                                            fontSize: "9.0pt",
                                                            fontFamily: "Arial",
                                                            color: "#00377a",
                                                            fontWeight: "900",
                                                            textDecoration: "underline",
                                                        }}
                                                        target="_blank"
                                                    >
                                                        {language.locale === "fr" ? "JoignezVousAnous.com" : "JoinUsNow.ca"}
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <table
                                        cellSpacing="5"
                                        cellPadding="0"
                                        border="0"
                                        width="485"
                                        style={{
                                            borderCollapse: "separate",
                                        }}
                                    >
                                        <tbody>
                                            <tr>
                                                <td
                                                    style={{
                                                        fontSize: "6.0pt",
                                                        fontFamily: "Arial",
                                                        color: "#00377a",
                                                        fontWeight: "400",
                                                    }}
                                                    valign="left"
                                                >
                                                    {features.bannerUrl && (
                                                        <a
                                                            href={features.customBannerUrl}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            style={{
                                                                textDecoration: "none",
                                                            }}
                                                        >
                                                            <img
                                                                src={features.bannerUrl}
                                                                width="650"
                                                                border="0"
                                                                alt={language.locale === "fr" ? "Bannière Personnalisée" : "Custom Banner"}
                                                                style={{
                                                                    width: "100%",
                                                                    display: "block",
                                                                    outline: "none",
                                                                    textDecoration: "none",
                                                                    MsInterpolationMode: "bicubic",
                                                                    verticalAlign: "middle",
                                                                }}
                                                            />
                                                        </a>
                                                    )}
                                                    <font style={{ margin: "0" }}>
                                                        {language.locale === "fr"
                                                            ? "Avis de confidentialité : ce courriel contient des renseignements qui sont à l’usage exclusif de la personne ou de l’entité nommée ci-dessus. Si le lecteur de ce courriel n’est pas le destinataire ou l’employé ou l’agent responsable de le transmettre au destinataire prévu, il vous est strictement interdit de distribuer, de publier ou de copier le présent courriel. L’expéditeur n’assume aucune responsabilité liée à la perte, à l’interruption ou à tout dommage à vos données ou à votre ordinateur pouvant survenir à la suite de l’utilisation des données contenues dans ce courriel ou transmises avec ce dernier. Si vous avez reçu ce courriel par erreur, veuillez nous en informer en répondant au présent message. Nous vous remercions de votre collaboration."
                                                            : "Confidentiality Warning: This e-mail contains information intended only for the use of the individual or entity named above. If the reader of this e-mail is not the intended recipient or the employee or agent responsible for delivering it to the intended recipient, any dissemination, publication, or copying of this e-mail is strictly prohibited. The sender does not accept any responsibility for any loss, disruption or damage to your data or computer system that may occur while using data contained in, or transmitted with, this e-mail. If you have received this email in error, please inform us via return email. Thank you for your cooperation."}
                                                    </font>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                )}
            </LanguageContext.Consumer>
        </Fragment>
    );
};
