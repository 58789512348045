import React from "react";
import { Row, Col } from "react-bootstrap";

//My Components
import EmailSignatureDemo from "./EmailSignatureDemo";

export const Main = () => {
    return (
        <main className="col-lg-9 order-lg-2 order-1">
            <Row className="h-100">
                <Col className="my-auto">
                    <EmailSignatureDemo />
                </Col>
            </Row>
        </main>
    );
};

export default Main;
