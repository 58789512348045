import React, { Fragment, useContext } from "react";
import TemplateContext from "../../../contexts/TemplateContext";
import UserContext from "../../../contexts/UserContext";
import { LanguageContext } from "../../../contexts/LanguageProvider";

export const BrokerTemplateOne = () => {
    const { template } = useContext(TemplateContext);
    const { user } = useContext(UserContext);
    const features = template.features;

    return (
        <Fragment>
            <LanguageContext.Consumer>
                {(language) => (
                    <table
                        id="main-table"
                        cellSpacing="0"
                        cellPadding="0"
                        border="0"
                        width="650"
                        style={{
                            margin: "0 !important",
                            padding: "0",
                            borderCollapse: "collapse",
                            msoTableLspace: "0pt !important",
                            msoTableRspace: "0pt !important",
                            lineHeight: "100%",
                            wordBreak: "break-word",
                        }}
                    >
                        <tbody>
                            <tr
                                style={{
                                    borderSpacing: "0 !important",
                                    padding: "0 !important",
                                    margin: "0 !important",
                                }}
                            >
                                <td
                                    width="650"
                                    style={{
                                        margin: "0",
                                        padding: "0",
                                        msoTableLspace: "0pt !important",
                                        msoTableRspace: "0pt !important",
                                    }}
                                >
                                    <table
                                        cellSpacing="0"
                                        border="0"
                                        width="650"
                                        bgcolor="#ffffff"
                                        style={{
                                            margin: "0 !important",
                                            background: "#ffffff",
                                            borderCollapse: "collapse",
                                            msoTableLspace: "0pt !important",
                                            msoTableRspace: "0pt !important",
                                        }}
                                    >
                                        <tbody>
                                            <tr
                                                style={{
                                                    borderSpacing: "0 !important",
                                                    padding: "0 !important",
                                                    margin: "0 !important",
                                                }}
                                            >
                                                <td
                                                    valign="top"
                                                    width="650"
                                                    bgcolor="#ffffff"
                                                    style={{
                                                        margin: "0",
                                                        padding: "0",
                                                        verticalAlign: "top",
                                                        msoTableLspace: "0pt !important",
                                                        msoTableRspace: "0pt !important",
                                                    }}
                                                >
                                                    <table
                                                        cellSpacing="7"
                                                        cellPadding="0"
                                                        border="0"
                                                        width="325"
                                                        bgcolor="#ffffff"
                                                        style={{
                                                            margin: "0 !important",
                                                            borderCollapse: "separate",
                                                            msoTableLspace: "0pt !important",
                                                            msoTableRspace: "0pt !important",
                                                        }}
                                                    >
                                                        <tbody>
                                                            <tr
                                                                style={{
                                                                    borderSpacing: "0 !important",
                                                                    padding: "0 !important",
                                                                    margin: "0 !important",
                                                                }}
                                                            >
                                                                {features.profileImageUrl || features.awardImageUrl ? (
                                                                    <td
                                                                        valign="top"
                                                                        width="60"
                                                                        style={{
                                                                            margin: "0",
                                                                            padding: "0",
                                                                            verticalAlign: "top",
                                                                            msoTableLspace: "0pt !important",
                                                                            msoTableRspace: "0pt !important",
                                                                        }}
                                                                    >
                                                                        {features.profileImageUrl && (
                                                                            <p
                                                                                className="MsoNormal"
                                                                                style={{
                                                                                    padding: "0 !important",
                                                                                    msoStyleUnhide: "no",
                                                                                    msoStyleQformat: "yes",
                                                                                    msoStyleParent: "",
                                                                                    margin: "0cm",
                                                                                    marginBottom: ".0001pt",
                                                                                    msoPagination: "widow-orphan",
                                                                                    msoBidiThemeFont: "minor-bidi",
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={features.profileImageUrl}
                                                                                    width="60"
                                                                                    border="0"
                                                                                    alt={features.fullName ? features.fullName : language.locale === "fr" ? "Nommé" : "Full Name"}
                                                                                    style={{
                                                                                        margin: "0",
                                                                                        display: "block",
                                                                                        outline: "none",
                                                                                        textDecoration: "none",
                                                                                        MsInterpolationMode: "bicubic",
                                                                                        verticalAlign: "middle",
                                                                                    }}
                                                                                />
                                                                            </p>
                                                                        )}
                                                                        {features.awardImageUrl ? (
                                                                            <p
                                                                                className="MsoNormal"
                                                                                style={{
                                                                                    margin: features.profileImageUrl ? "5px 0 0 0" : "0",
                                                                                    padding: "0 !important",
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={features.awardImageUrl}
                                                                                    width="60"
                                                                                    border="0"
                                                                                    alt={language.locale === "fr" ? "Prix du Courtier" : "Broker Award"}
                                                                                    style={{
                                                                                        margin: features.profileImageUrl ? "5px 0 0 0" : "0",
                                                                                        display: "block",
                                                                                        outline: "none",
                                                                                        textDecoration: "none",
                                                                                        MsInterpolationMode: "bicubic",
                                                                                        verticalAlign: "middle",
                                                                                    }}
                                                                                />
                                                                            </p>
                                                                        ) : null}
                                                                    </td>
                                                                ) : null}

                                                                <td
                                                                    valign="top"
                                                                    width="245"
                                                                    style={{
                                                                        margin: "0",
                                                                        padding: "0",
                                                                        verticalAlign: "top",
                                                                        msoTableLspace: "0pt !important",
                                                                        msoTableRspace: "0pt !important",
                                                                    }}
                                                                >
                                                                    <p
                                                                        className="MsoNormal"
                                                                        style={{
                                                                            fontSize: "12pt",
                                                                            fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                            color: "#005baa",
                                                                            textTransform: "capitalize",
                                                                            padding: "0 !important",
                                                                            fontWeight: "900",
                                                                            msoStyleUnhide: "no",
                                                                            msoStyleQformat: "yes",
                                                                            msoStyleParent: "",
                                                                            margin: "0cm",
                                                                            marginBottom: ".0001pt",
                                                                            msoPagination: "widow-orphan",
                                                                            msoBidiThemeFont: "minor-bidi",
                                                                        }}
                                                                    >
                                                                        <b>{features.fullName ? features.fullName : language.locale === "fr" ? "Nommé" : "Full Name"}</b>
                                                                        <br />
                                                                        <b
                                                                            style={{
                                                                                fontSize: "10pt",
                                                                                fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                                color: "#1f1f1f",
                                                                                margin: "0",
                                                                                fontWeight: "normal",
                                                                            }}
                                                                        >
                                                                            {features.jobTitle ? features.jobTitle : language.locale === "fr" ? "Titre du Poste" : "Position Title"}
                                                                        </b>
                                                                        <br />
                                                                        {features.licenseNumber ? (
                                                                            <b
                                                                                style={{
                                                                                    fontSize: "8.5pt",
                                                                                    fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                                    color: "#1f1f1f",
                                                                                    margin: "0",
                                                                                    fontWeight: "900",
                                                                                }}
                                                                            >
                                                                                {language.locale === "fr" ? "Permis" : "License"} {features.licenseNumber}
                                                                            </b>
                                                                        ) : null}
                                                                    </p>

                                                                    {(features.phone || features.mobile || features.fax) && (
                                                                        <p
                                                                            className="MsoNormal"
                                                                            style={{
                                                                                fontSize: "8.5pt",
                                                                                fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                                color: "#1f1f1f",
                                                                                padding: "0 !important",
                                                                                msoStyleUnhide: "no",
                                                                                msoStyleQformat: "yes",
                                                                                msoStyleParent: "",
                                                                                margin: "0.2cm 0cm 0cm",
                                                                                marginBottom: ".0001pt",
                                                                                msoPagination: "widow-orphan",
                                                                                msoBidiThemeFont: "minor-bidi",
                                                                            }}
                                                                        >
                                                                            {features.phone && (
                                                                                <>
                                                                                    <b
                                                                                        style={{
                                                                                            color: "#1f1f1f",
                                                                                            fontWeight: "bold",
                                                                                        }}
                                                                                    >
                                                                                        {language.locale === "fr" ? "T" : "P"}:{" "}
                                                                                    </b>
                                                                                    <a
                                                                                        href={"tel:" + features.phone}
                                                                                        rel="noopener noreferrer"
                                                                                        style={{
                                                                                            color: "#205CCA",
                                                                                            textDecoration: "none",
                                                                                            padding: "0",
                                                                                        }}
                                                                                    >
                                                                                        {features.phone}
                                                                                    </a>
                                                                                    <br />
                                                                                </>
                                                                            )}
                                                                            {features.mobile && (
                                                                                <>
                                                                                    <b
                                                                                        style={{
                                                                                            color: "#1f1f1f",
                                                                                            fontWeight: "bold",
                                                                                        }}
                                                                                    >
                                                                                        {language.locale === "fr" ? "C" : "M"}:{" "}
                                                                                    </b>
                                                                                    <a
                                                                                        href={"tel:" + features.mobile}
                                                                                        rel="noopener noreferrer"
                                                                                        style={{
                                                                                            color: "#205CCA",
                                                                                            textDecoration: "none",
                                                                                            padding: "0",
                                                                                        }}
                                                                                    >
                                                                                        {features.mobile}
                                                                                    </a>
                                                                                    <br />
                                                                                </>
                                                                            )}
                                                                            {features.fax && (
                                                                                <>
                                                                                    <b
                                                                                        style={{
                                                                                            color: "#1f1f1f",
                                                                                            fontWeight: "bold",
                                                                                        }}
                                                                                    >
                                                                                        {language.locale === "fr" ? "Téléc" : "F"}:{" "}
                                                                                    </b>
                                                                                    <a
                                                                                        href={"tel:" + features.fax}
                                                                                        rel="noopener noreferrer"
                                                                                        style={{
                                                                                            color: "#205CCA",
                                                                                            textDecoration: "none",
                                                                                            padding: "0",
                                                                                        }}
                                                                                    >
                                                                                        {features.fax}
                                                                                    </a>
                                                                                </>
                                                                            )}
                                                                        </p>
                                                                    )}

                                                                    {(features.email || features.website) && (
                                                                        <p
                                                                            className="MsoNormal"
                                                                            style={{
                                                                                fontSize: "8.5pt",
                                                                                fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                                color: "#1f1f1f",
                                                                                padding: "0 !important",
                                                                                msoStyleUnhide: "no",
                                                                                msoStyleQformat: "yes",
                                                                                msoStyleParent: "",
                                                                                margin: "0.2cm 0cm 0cm",
                                                                                marginBottom: ".0001pt",
                                                                                msoPagination: "widow-orphan",
                                                                                msoBidiThemeFont: "minor-bidi",
                                                                            }}
                                                                        >
                                                                            {features.email && (
                                                                                <Fragment>
                                                                                    <a
                                                                                        href={"mailto:" + features.email}
                                                                                        rel="noopener noreferrer"
                                                                                        style={{
                                                                                            color: "#205CCA",
                                                                                            textDecoration: "none",
                                                                                            padding: "0",
                                                                                        }}
                                                                                    >
                                                                                        {features.email}
                                                                                    </a>
                                                                                    <br />
                                                                                </Fragment>
                                                                            )}

                                                                            {features.website && (
                                                                                <a
                                                                                    href={"https://" + features.website}
                                                                                    rel="noopener noreferrer"
                                                                                    style={{
                                                                                        color: "#205CCA",
                                                                                        textDecoration: "none",
                                                                                        padding: "0",
                                                                                    }}
                                                                                    target="_blank"
                                                                                >
                                                                                    {" "}
                                                                                    {features.website}
                                                                                </a>
                                                                            )}
                                                                        </p>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td
                                                    valign="top"
                                                    width="325"
                                                    bgcolor="#005baa"
                                                    style={{
                                                        background: "#005baa",
                                                        margin: "0",
                                                        padding: "0",
                                                        verticalAlign: "top",
                                                        msoTableLspace: "0pt !important",
                                                        msoTableRspace: "0pt !important",
                                                    }}
                                                >
                                                    <table
                                                        cellSpacing="7"
                                                        cellPadding="0"
                                                        border="0"
                                                        width="325"
                                                        bgcolor="#005baa"
                                                        style={{
                                                            margin: "0 !important",
                                                            background: "#005baa",
                                                            borderCollapse: "separate",
                                                            msoTableLspace: "0pt !important",
                                                            msoTableRspace: "0pt !important",
                                                        }}
                                                    >
                                                        <tbody>
                                                            <tr
                                                                style={{
                                                                    borderSpacing: "0 !important",
                                                                    padding: "0 !important",
                                                                    margin: "0 !important",
                                                                }}
                                                            >
                                                                {features.subTeamLogoUrl || features.franchise.logoUrl ? (
                                                                    <td
                                                                        valign="top"
                                                                        width="60"
                                                                        style={{
                                                                            margin: "0",
                                                                            padding: "0",
                                                                            verticalAlign: "top",
                                                                            msoTableLspace: "0pt !important",
                                                                            msoTableRspace: "0pt !important",
                                                                        }}
                                                                    >
                                                                        {features.subTeamLogoUrl && (
                                                                            <p
                                                                                className="MsoNormal"
                                                                                style={{
                                                                                    padding: "0 !important",
                                                                                    msoStyleUnhide: "no",
                                                                                    msoStyleQformat: "yes",
                                                                                    msoStyleParent: "",
                                                                                    margin: "0cm",
                                                                                    marginBottom: ".0001pt",
                                                                                    msoPagination: "widow-orphan",
                                                                                    msoBidiThemeFont: "minor-bidi",
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={features.subTeamLogoUrl}
                                                                                    width="60"
                                                                                    height="60"
                                                                                    border="0"
                                                                                    alt={features.subTeamName}
                                                                                    style={{
                                                                                        margin: "0",
                                                                                        display: "block",
                                                                                        outline: "none",
                                                                                        border: "none",
                                                                                        textDecoration: "none",
                                                                                        MsInterpolationMode: "bicubic",
                                                                                        verticalAlign: "middle",
                                                                                    }}
                                                                                />
                                                                            </p>
                                                                        )}

                                                                        {features.franchise.logoUrl && (
                                                                            <p
                                                                                className="MsoNormal"
                                                                                style={{
                                                                                    padding: "0 !important",
                                                                                    msoStyleUnhide: "no",
                                                                                    msoStyleQformat: "yes",
                                                                                    msoStyleParent: "",
                                                                                    margin: "0cm",
                                                                                    marginBottom: ".0001pt",
                                                                                    msoPagination: "widow-orphan",
                                                                                    msoBidiThemeFont: "minor-bidi",
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={features.franchise.logoUrl}
                                                                                    width="60"
                                                                                    height="60"
                                                                                    border="0"
                                                                                    alt={user.info.franchise.name}
                                                                                    style={{
                                                                                        margin: features.subTeamLogoUrl ? "5px 0 0 0" : "0",
                                                                                        display: "block",
                                                                                        outline: "none",
                                                                                        border: "none",
                                                                                        textDecoration: "none",
                                                                                        MsInterpolationMode: "bicubic",
                                                                                        verticalAlign: "middle",
                                                                                    }}
                                                                                />
                                                                            </p>
                                                                        )}
                                                                    </td>
                                                                ) : null}
                                                                <td
                                                                    valign="top"
                                                                    width="251"
                                                                    style={{
                                                                        margin: "0",
                                                                        padding: "0",
                                                                        verticalAlign: "top",
                                                                        msoTableLspace: "0pt !important",
                                                                        msoTableRspace: "0pt !important",
                                                                    }}
                                                                >
                                                                    <p
                                                                        className="MsoNormal"
                                                                        style={{
                                                                            fontSize: "12pt",
                                                                            fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                            color: "#ffffff",
                                                                            textTransform: "capitalize",
                                                                            fontWeight: "900",
                                                                            padding: "0 !important",
                                                                            msoStyleUnhide: "no",
                                                                            msoStyleQformat: "yes",
                                                                            msoStyleParent: "",
                                                                            margin: "0cm",
                                                                            marginBottom: ".0001pt",
                                                                            msoPagination: "widow-orphan",
                                                                            msoBidiThemeFont: "minor-bidi",
                                                                        }}
                                                                    >
                                                                        {features.subTeamName ? (
                                                                            <Fragment>
                                                                                <b>{features.subTeamName}</b>
                                                                                <br />
                                                                            </Fragment>
                                                                        ) : (
                                                                            <Fragment>
                                                                                <b>{user.info.franchise.name}</b>
                                                                                <br />
                                                                            </Fragment>
                                                                        )}

                                                                        {features.subTeamName && (
                                                                            <Fragment>
                                                                                <b
                                                                                    style={{
                                                                                        fontSize: "10pt",
                                                                                        fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                                        color: "#fff",
                                                                                        margin: "0",
                                                                                        fontWeight: "normal",
                                                                                    }}
                                                                                >
                                                                                    {user.info.franchise.name}
                                                                                </b>
                                                                                <br />
                                                                            </Fragment>
                                                                        )}

                                                                        {user.info.franchise.licenseNumber && (
                                                                            <b
                                                                                style={{
                                                                                    fontSize: "8.5pt",
                                                                                    fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                                    color: "#fff",
                                                                                    margin: "0",
                                                                                    fontWeight: "900",
                                                                                }}
                                                                            >
                                                                                {user.info.franchise.licenseNumber}
                                                                            </b>
                                                                        )}
                                                                    </p>

                                                                    {template.features.office && (
                                                                        <p
                                                                            className="MsoNormal"
                                                                            style={{
                                                                                fontSize: "8.5pt",
                                                                                fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                                color: "#fff",
                                                                                padding: "0 !important",
                                                                                fontWeight: "normal",
                                                                                msoStyleUnhide: "no",
                                                                                msoStyleQformat: "yes",
                                                                                msoStyleParent: "",
                                                                                margin: "0.2cm 0cm 0cm",
                                                                                marginBottom: "0.2cm",
                                                                                msoPagination: "widow-orphan",
                                                                                msoBidiThemeFont: "minor-bidi",
                                                                            }}
                                                                        >
                                                                            {features.office.addressLineTwo && features.office.addressLineTwo + "-"}
                                                                            {features.office.streetAddress}
                                                                            <br />
                                                                            {features.office.city}, {features.office.provinceCode}
                                                                            <br />
                                                                            {features.office.postalCode}
                                                                        </p>
                                                                    )}

                                                                    <p
                                                                        className="MsoNormal"
                                                                        style={{
                                                                            fontSize: "8.5pt",
                                                                            fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                            padding: "0 !important",
                                                                            fontWeight: "normal",
                                                                            msoStyleUnhide: "no",
                                                                            msoStyleQformat: "yes",
                                                                            msoStyleParent: "",
                                                                            margin: "0.2cm 0cm 0cm",
                                                                            marginBottom: ".0001pt",
                                                                            msoPagination: "widow-orphan",
                                                                            msoBidiThemeFont: "minor-bidi",
                                                                        }}
                                                                    >
                                                                        {features.franchise.website || user.info.franchise.website ? (
                                                                            <Fragment>
                                                                                <a
                                                                                    href={
                                                                                        features.franchise.website ? "https://" + features.franchise.website : "https://" + user.info.franchise.website
                                                                                    }
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                    style={{
                                                                                        color: "#24C4F0",
                                                                                        textDecoration: "none",
                                                                                        padding: "0",
                                                                                    }}
                                                                                >
                                                                                    {features.franchise.website ? features.franchise.website : user.info.franchise.website}
                                                                                </a>
                                                                                <br />
                                                                            </Fragment>
                                                                        ) : null}
                                                                        <i
                                                                            style={{
                                                                                fontSize: "8pt",
                                                                                fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                                color: "#fff",
                                                                                margin: "0",
                                                                                fontWeight: "200",
                                                                            }}
                                                                        >
                                                                            {language.locale === "fr" ? "Chaque franchise est autonome et indépendante" : "Independently Owned & Operated"}
                                                                        </i>
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr
                                                style={{
                                                    borderSpacing: "0 !important",
                                                    padding: "0 !important",
                                                    margin: "0 !important",
                                                    fontSize: "0px",
                                                    lineHeight: "0px",
                                                    borderCollapse: "collapse",
                                                    background: "#005baa",
                                                }}
                                            >
                                                <td
                                                    valign="top"
                                                    width="325"
                                                    bgcolor="#ffffff"
                                                    style={{
                                                        margin: "0",
                                                        padding: "0",
                                                        verticalAlign: "top",
                                                        msoTableLspace: "0pt !important",
                                                        msoTableRspace: "0pt !important",
                                                        background: "#ffffff",
                                                    }}
                                                >
                                                    <table
                                                        cellSpacing="7"
                                                        cellPadding="0"
                                                        border="0"
                                                        width="252"
                                                        bgcolor="#ffffff"
                                                        style={{
                                                            margin: "0 !important",
                                                            borderCollapse: "separate",
                                                            msoTableLspace: "0pt !important",
                                                            msoTableRspace: "0pt !important",
                                                        }}
                                                    >
                                                        <tbody>
                                                            <tr
                                                                style={{
                                                                    borderSpacing: "0 !important",
                                                                    padding: "0 !important",
                                                                    margin: "0 !important",
                                                                }}
                                                            >
                                                                {features.applicationUrl ? (
                                                                    <td
                                                                        valign="middle"
                                                                        width="100"
                                                                        style={{
                                                                            margin: "0",
                                                                            padding: "0",
                                                                            msoTableLspace: "0pt !important",
                                                                            msoTableRspace: "0pt !important",
                                                                        }}
                                                                    >
                                                                        <a
                                                                            href={features.applicationUrl}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                            style={{
                                                                                padding: "0",
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={
                                                                                    language.locale === "fr"
                                                                                        ? "https://centralhost.dominionlending.ca/signatures/broker/template-1/apply-now-btn-fr.png"
                                                                                        : "https://centralhost.dominionlending.ca/signatures/broker/template-1/apply-now-btn-en.png"
                                                                                }
                                                                                width="95"
                                                                                height="25"
                                                                                border="0"
                                                                                alt={language.locale === "fr" ? "Présenter une Demande" : "Apply Now"}
                                                                                style={{
                                                                                    display: "block",
                                                                                    border: "none",
                                                                                    outline: "none",
                                                                                    textDecoration: "none",
                                                                                    MsInterpolationMode: "bicubic",
                                                                                    verticalAlign: "middle",
                                                                                }}
                                                                            />
                                                                        </a>
                                                                    </td>
                                                                ) : null}
                                                                {features.mortgageToolboxUrl ? (
                                                                    <td
                                                                        valign="middle"
                                                                        width="137"
                                                                        style={{
                                                                            margin: "0",
                                                                            padding: "0",
                                                                            msoTableLspace: "0pt !important",
                                                                            msoTableRspace: "0pt !important",
                                                                        }}
                                                                    >
                                                                        <a
                                                                            href={features.mortgageToolboxUrl}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                            style={{
                                                                                padding: "0",
                                                                            }}
                                                                        >
                                                                            <img
                                                                                src={
                                                                                    language.locale === "fr"
                                                                                        ? "https://centralhost.dominionlending.ca/signatures/broker/template-1/pre-qualified-btn-fr.png"
                                                                                        : "https://centralhost.dominionlending.ca/signatures/broker/template-1/pre-qualified-btn-en.png"
                                                                                }
                                                                                width="136"
                                                                                height="25"
                                                                                border="0"
                                                                                alt={language.locale === "fr" ? "Obtenir une Préapprobation" : "Get Pre-qualified"}
                                                                                style={{
                                                                                    display: "block",
                                                                                    border: "none",
                                                                                    outline: "none",
                                                                                    textDecoration: "none",
                                                                                    MsInterpolationMode: "bicubic",
                                                                                    verticalAlign: "middle",
                                                                                }}
                                                                            />
                                                                        </a>
                                                                    </td>
                                                                ) : null}
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td
                                                    valign="top"
                                                    width="325"
                                                    bgcolor="#005baa"
                                                    style={{
                                                        background: "#005baa",
                                                        margin: "0",
                                                        padding: "0",
                                                        verticalAlign: "top",
                                                    }}
                                                >
                                                    <table
                                                        cellSpacing="7"
                                                        cellPadding="0"
                                                        border="0"
                                                        width="325"
                                                        bgcolor="#005baa"
                                                        style={{
                                                            background: "#005baa",
                                                            borderCollapse: "separate",
                                                            msoTableLspace: "0pt !important",
                                                            msoTableRspace: "0pt !important",
                                                        }}
                                                    >
                                                        <tbody>
                                                            <tr
                                                                style={{
                                                                    borderSpacing: "0 !important",
                                                                    padding: "0 !important",
                                                                    margin: "0 !important",
                                                                }}
                                                            >
                                                                <td
                                                                    key="dominion"
                                                                    width="60"
                                                                    style={{
                                                                        margin: "0",
                                                                        padding: "0",
                                                                        msoTableLspace: "0pt !important",
                                                                        msoTableRspace: "0pt !important",
                                                                    }}
                                                                >
                                                                    <a
                                                                        href={language.locale === "fr" ? "https://www.hypothecairesdominion.ca/" : "https://dominionlending.ca/"}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        style={{
                                                                            padding: "0",
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={
                                                                                language.locale === "fr"
                                                                                    ? "https://centralhost.dominionlending.ca/signatures/broker/template-1/dlc-logo-fr.png"
                                                                                    : "https://centralhost.dominionlending.ca/signatures/broker/template-1/dlc-logo-en.png"
                                                                            }
                                                                            width="58"
                                                                            height="25"
                                                                            border="0"
                                                                            alt={language.locale === "fr" ? "Centres Hypothécaires Dominion" : "Dominion Lending Centres"}
                                                                            style={{
                                                                                display: "block",
                                                                                border: "none",
                                                                                outline: "none",
                                                                                textDecoration: "none",
                                                                                MsInterpolationMode: "bicubic",
                                                                                verticalAlign: "middle",
                                                                            }}
                                                                        />
                                                                    </a>
                                                                </td>
                                                                <td style={{ width: "100%" }}></td>
                                                                {["facebook", "twitter", "instagram", "linkedin", "youtube", "pinterest"].map((socialMedia) =>
                                                                    features.socialMediaUrls[socialMedia] ? (
                                                                        <td
                                                                            key={socialMedia}
                                                                            width="26"
                                                                            height="26"
                                                                            style={{
                                                                                margin: "0",
                                                                                padding: "0",
                                                                                msoTableLspace: "0pt !important",
                                                                                msoTableRspace: "0pt !important",
                                                                            }}
                                                                        >
                                                                            <a
                                                                                href={features.socialMediaUrls[socialMedia]}
                                                                                target="_blank"
                                                                                rel="noopener noreferrer"
                                                                                style={{
                                                                                    padding: "0",
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={"https://centralhost.dominionlending.ca/signatures/broker/template-1/" + socialMedia + ".png"}
                                                                                    width="25"
                                                                                    height="25"
                                                                                    border="0"
                                                                                    alt={socialMedia}
                                                                                    style={{
                                                                                        display: "block",
                                                                                        border: "none",
                                                                                        outline: "none",
                                                                                        textDecoration: "none",
                                                                                        MsInterpolationMode: "bicubic",
                                                                                        verticalAlign: "middle",
                                                                                    }}
                                                                                />
                                                                            </a>
                                                                        </td>
                                                                    ) : null
                                                                )}
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>

                            <tr
                                style={{
                                    margin: "0",
                                    padding: "0",
                                    fontSize: "0px",
                                    lineHeight: "0px",
                                    borderCollapse: "collapse",
                                    background: "#F6F8F9",
                                }}
                            >
                                <td
                                    width="650"
                                    style={{
                                        margin: "0",
                                        padding: "0",
                                        msoTableLspace: "0pt !important",
                                        msoTableRspace: "0pt !important",
                                    }}
                                >
                                    <table
                                        cellSpacing="10"
                                        cellPadding="0"
                                        border="0"
                                        width="650"
                                        style={{
                                            margin: "0 !important",
                                            background: "#F6F8F9",
                                            borderCollapse: "collapse",
                                            border: "none",
                                            msoTableLspace: "0pt !important",
                                            msoTableRspace: "0pt !important",
                                        }}
                                    >
                                        <tbody>
                                            <tr
                                                style={{
                                                    borderSpacing: "0 !important",
                                                    padding: "0 !important",
                                                    margin: "0 !important",
                                                    display: "block",
                                                    fontSize: "0px",
                                                    lineHeight: "0px",
                                                    borderCollapse: "collapse",
                                                }}
                                            >
                                                <td
                                                    width="650"
                                                    valign="left"
                                                    style={{
                                                        fontSize: "7.5pt",
                                                        fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                        color: "#1f1f1f",
                                                        fontWeight: "400",
                                                        margin: "0",
                                                        padding: "0",
                                                        msoTableLspace: "0pt !important",
                                                        msoTableRspace: "0pt !important",
                                                    }}
                                                >
                                                    {features.bannerUrl && (
                                                        <a
                                                            href={features.customBannerUrl}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            style={{
                                                                textDecoration: "none",
                                                            }}
                                                        >
                                                            <img
                                                                src={features.bannerUrl}
                                                                width="650"
                                                                border="0"
                                                                alt={language.locale === "fr" ? "Bannière Personnalisée" : "Custom Banner"}
                                                                style={{
                                                                    width: "100%",
                                                                    display: "block",
                                                                    outline: "none",
                                                                    textDecoration: "none",
                                                                    MsInterpolationMode: "bicubic",
                                                                    verticalAlign: "middle",
                                                                }}
                                                            />
                                                        </a>
                                                    )}
                                                    <p
                                                        className="MsoNormal"
                                                        style={{
                                                            padding: "0 !important",
                                                            fontSize: "7.5pt",
                                                            fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                            color: "#1f1f1f",
                                                            fontWeight: "400",
                                                            lineHeight: "9pt",
                                                            msoStyleUnhide: "no",
                                                            msoStyleQformat: "yes",
                                                            msoStyleParent: "",
                                                            margin: "10px",
                                                            msoPagination: "widow-orphan",
                                                            msoBidiThemeFont: "minor-bidi",
                                                        }}
                                                    >
                                                        {features.confidentialityWarning ? (
                                                            <Fragment>
                                                                <b>{language.locale === "fr" ? "Avertissement : " : "Confidentiality Warning: "}</b>
                                                                {features.confidentialityWarning}{" "}
                                                            </Fragment>
                                                        ) : null}
                                                        {language.locale === "fr"
                                                            ? "À noter : Cet e-mail a été envoyé après la Canadienne législation antispam directives, si vous souhaitez ne plus recevoir la correspondance électronique à partir de cette adresse e-mail, veuillez cliquer ici pour"
                                                            : "Please Note: This email was sent following the Canadian Anti-Spam Legislation guidelines. If you wish to no longer receive electronic correspondence from this email address, please confirm by clicking here to"}{" "}
                                                        <a
                                                            href={"mailto:" + features.email + "?subject=unsubscribe"}
                                                            rel="noopener noreferrer"
                                                            style={{
                                                                color: "#205CCA",
                                                                textDecoration: "none",
                                                                padding: "0",
                                                            }}
                                                        >
                                                            {language.locale === "fr" ? "annuler un abonnement" : "unsubscribe"}
                                                        </a>
                                                        .
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                )}
            </LanguageContext.Consumer>
        </Fragment>
    );
};
