import React from "react";

let UserContext = React.createContext({
    user: {
        type: "franchise-user",
        id: null,
        lang: "en",
        info: {},
    },
    updateUser: () => {},
});

export default UserContext;
