import React, { Fragment, useContext } from "react";
import TemplateContext from "../../../contexts/TemplateContext";
import UserContext from "../../../contexts/UserContext";
import { LanguageContext } from "../../../contexts/LanguageProvider";

export const FranchiseTemplateTwo = () => {
    const { template } = useContext(TemplateContext);
    const { user } = useContext(UserContext);
    const features = template.features;
    const franchiseLogo = user.type === "broker-user" ? features.franchise.logoUrl : features.logoUrl;
    const franchiseWebsite = user.type === "broker-user" ? features.franchise.website : features.website;
    const franchiseLicenseNumber = user.type === "broker-user" ? user.info.franchise.licenseNumber : user.info.licenseNumber;
    const member = user.type === "broker-user" ? features : template.member;
    let colorScheme = null;
    if (user.type === "broker-user") {
        colorScheme = features.franchise.colorScheme;
    } else {
        colorScheme = features.colorScheme;
    }

    return (
        <Fragment>
            <LanguageContext.Consumer>
                {(language) => (
                    <table
                        id="main-table"
                        cellSpacing="0"
                        cellPadding="0"
                        border="0"
                        width="650"
                        style={{
                            borderTop: "8px solid",
                            borderColor: `${colorScheme.primary ? colorScheme.primary : "#9654aa"}`,
                            margin: "0 !important",
                            padding: "0",
                            borderCollapse: "separate",
                            lineHeight: "100%",
                            wordBreak: "break-word",
                        }}
                    >
                        <tbody>
                            <tr style={{ borderSpacing: "0 !important", padding: "0 !important", margin: "0 !important" }}>
                                <td style={{ margin: "0", padding: "0" }}>
                                    <table cellSpacing="10" cellPadding="0" border="0" width="650" bgcolor="#ffffff" style={{ borderCollapse: "separate", background: "#ffffff" }}>
                                        <tbody>
                                            <tr style={{ padding: "0 !important", margin: "0 !important" }}>
                                                <td width="380" style={{ margin: "0", padding: "0" }}>
                                                    <p className="MsoNormal" style={{ margin: "0" }}>
                                                        <b
                                                            style={{
                                                                fontSize: "14pt",
                                                                fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                color: `${colorScheme.headers ? colorScheme.headers : "#9654aa"}`,
                                                                textTransform: "capitalize",
                                                                fontWeight: "900",
                                                                padding: "0 !important",
                                                            }}
                                                        >
                                                            {member.fullName ? member.fullName : language.locale === "fr" ? "Nommé" : "Full Name"}{" "}
                                                            <span
                                                                style={{
                                                                    fontSize: "10pt",
                                                                    fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                    color: `${colorScheme.text ? colorScheme.text : "#404040"}`,
                                                                    margin: "0",
                                                                    fontWeight: "normal",
                                                                }}
                                                            >
                                                                / {member.jobTitle ? member.jobTitle : language.locale === "fr" ? "Titre du Poste" : "Position Title"}
                                                            </span>
                                                        </b>

                                                        {template.features.office && (
                                                            <Fragment>
                                                                <br />
                                                                <span
                                                                    style={{
                                                                        fontSize: "9pt",
                                                                        fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                        color: `${colorScheme.text ? colorScheme.text : "#404040"}`,
                                                                        fontWeight: "normal",
                                                                    }}
                                                                >
                                                                    {features.office.addressLineTwo && features.office.addressLineTwo + "-"} {features.office.streetAddress} {features.office.city},
                                                                    {features.office.provinceCode}, {features.office.postalCode}
                                                                </span>
                                                            </Fragment>
                                                        )}
                                                    </p>

                                                    {member.phone || member.mobile || member.fax || member.email || member.website || features.website || member.id !== "" ? (
                                                        <p
                                                            className="MsoNormal"
                                                            style={{
                                                                margin: "0 0 10px 0",
                                                                fontSize: "8.5pt",
                                                                fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                color: `${colorScheme.text ? colorScheme.text : "#404040"}`,
                                                                padding: "0 !important",
                                                                lineHeight: "12pt",
                                                            }}
                                                        >
                                                            <br />
                                                            {member.phone ? (
                                                                <Fragment>
                                                                    <b style={{ fontWeight: "bold" }}>{language.locale === "fr" ? "T" : "O"}: </b>
                                                                    <a
                                                                        href={"tel:" + member.phone}
                                                                        rel="noopener noreferrer"
                                                                        style={{ color: `${colorScheme.links ? colorScheme.links : "#9654aa"}`, textDecoration: "none" }}
                                                                    >
                                                                        {member.phone}
                                                                        {""}
                                                                    </a>
                                                                </Fragment>
                                                            ) : null}

                                                            {member.mobile ? (
                                                                <Fragment>
                                                                    <b style={{ fontWeight: "bold" }}> {language.locale === "fr" ? "C" : "M"}: </b>
                                                                    <a
                                                                        href={"tel:" + member.mobile}
                                                                        rel="noopener noreferrer"
                                                                        style={{ color: `${colorScheme.links ? colorScheme.links : "#9654aa"}`, textDecoration: "none" }}
                                                                    >
                                                                        {member.mobile}
                                                                    </a>
                                                                </Fragment>
                                                            ) : null}

                                                            {member.fax ? (
                                                                <Fragment>
                                                                    <br />
                                                                    <b style={{ fontWeight: "bold" }}>{language.locale === "fr" ? "Téléc" : "F"}: </b>
                                                                    {member.fax}
                                                                </Fragment>
                                                            ) : null}

                                                            {member.email ? (
                                                                <Fragment>
                                                                    <br />
                                                                    <b style={{ fontWeight: "bold" }}>E: </b>
                                                                    <a
                                                                        href={"mailto:" + member.email}
                                                                        rel="noopener noreferrer"
                                                                        style={{ color: `${colorScheme.links ? colorScheme.links : "#9654aa"}`, textDecoration: "none" }}
                                                                    >
                                                                        {member.email}
                                                                    </a>
                                                                </Fragment>
                                                            ) : null}

                                                            {member.website ? (
                                                                <Fragment>
                                                                    <br />
                                                                    <b style={{ fontWeight: "bold" }}>W: </b>
                                                                    <a
                                                                        href={"https://" + member.website}
                                                                        style={{ color: `${colorScheme.links ? colorScheme.links : "#9654aa"}`, textDecoration: "none" }}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                    >
                                                                        {member.website}
                                                                    </a>
                                                                </Fragment>
                                                            ) : null}

                                                            {franchiseWebsite ? (
                                                                <Fragment>
                                                                    <b style={{ fontWeight: "bold" }}> W: </b>
                                                                    <a
                                                                        href={"https://" + franchiseWebsite}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        style={{ color: `${colorScheme.links ? colorScheme.links : "#9654aa"}`, textDecoration: "none" }}
                                                                    >
                                                                        {franchiseWebsite}
                                                                    </a>
                                                                </Fragment>
                                                            ) : null}
                                                        </p>
                                                    ) : null}

                                                    {member.applicationUrl || member.mortgageToolboxUrl ? (
                                                        <p className="MsoNormal" style={{ margin: "10px 0 0 0", padding: "0 !important" }}>
                                                            {member.applicationUrl && (
                                                                <a href={member.applicationUrl} style={{ padding: "0", margin: "0", textDecoration: "none" }} rel="noopener noreferrer">
                                                                    <img
                                                                        src={
                                                                            language.locale === "fr"
                                                                                ? "https://centralhost.dominionlending.ca/signatures/franchise/template-2/apply-now-btn-fr.jpg"
                                                                                : "https://centralhost.dominionlending.ca/signatures/franchise/template-2/apply-now-btn-en.jpg"
                                                                        }
                                                                        style={{
                                                                            outline: "none",
                                                                            textDecoration: "none",
                                                                            MsInterpolationMode: "bicubic",
                                                                            verticalAlign: "middle",
                                                                            border: "0",
                                                                        }}
                                                                        width="96"
                                                                        height="30"
                                                                        alt={language.locale === "fr" ? "Présenter une Demande" : "Apply Now"}
                                                                    />
                                                                </a>
                                                            )}

                                                            {member.mortgageToolboxUrl && (
                                                                <a href={member.mortgageToolboxUrl} style={{ padding: "0", margin: "0", textDecoration: "none" }} rel="noopener noreferrer">
                                                                    <img
                                                                        src={
                                                                            language.locale === "fr"
                                                                                ? "https://centralhost.dominionlending.ca/signatures/franchise/template-2/pre-qualified-btn-fr.jpg"
                                                                                : "https://centralhost.dominionlending.ca/signatures/franchise/template-2/pre-qualified-btn-en.jpg"
                                                                        }
                                                                        width="136"
                                                                        height="30"
                                                                        alt={language.locale === "fr" ? "Obtenir une Préapprobation" : "Get Pre-qualified"}
                                                                        style={{
                                                                            outline: "none",
                                                                            textDecoration: "none",
                                                                            MsInterpolationMode: "bicubic",
                                                                            verticalAlign: "middle",
                                                                            border: "0",
                                                                        }}
                                                                    />
                                                                </a>
                                                            )}
                                                        </p>
                                                    ) : null}

                                                    {member.licenseNumber ? (
                                                        <p className="MsoNormal" style={{ margin: "0 0 5px 0", padding: "0 !important" }}>
                                                            <b
                                                                style={{
                                                                    fontSize: "8pt",
                                                                    fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                    color: `${colorScheme.text ? colorScheme.text : "#404040"}`,
                                                                    margin: "0",
                                                                    fontWeight: "600",
                                                                }}
                                                            >
                                                                {language.locale === "fr" ? "Permis" : "License"} #{member.licenseNumber}
                                                            </b>
                                                        </p>
                                                    ) : null}
                                                </td>

                                                {member.subTeamLogoUrl ? (
                                                    <td width="60" valign="middle" style={{ msoTableLspace: "0pt !important", msoTableRspace: "0pt !important", verticalAlign: "middle" }}>
                                                        <img
                                                            alt={member.subTeamName}
                                                            border="0"
                                                            width="60"
                                                            height="60"
                                                            src={member.subTeamLogoUrl}
                                                            style={{
                                                                display: "block",
                                                                msoLineHeightRule: "exactly",
                                                                lineHeight: "100%",
                                                                outline: "none",
                                                                textDecoration: "none",
                                                                margin: "0px",
                                                                border: "0",
                                                                MsInterpolationMode: "bicubic",
                                                                verticalAlign: "middle",
                                                            }}
                                                        />
                                                    </td>
                                                ) : null}

                                                {member.awardImageUrl ? (
                                                    <td width="60" valign="middle" style={{ msoTableLspace: "0pt !important", msoTableRspace: "0pt !important", verticalAlign: "middle" }}>
                                                        <img
                                                            src={member.awardImageUrl}
                                                            style={{
                                                                float: "left",
                                                                display: "block",
                                                                msoLineHeightRule: "exactly",
                                                                lineHeight: "100%",
                                                                outline: "none",
                                                                textDecoration: "none",
                                                                margin: "0px",
                                                                border: "0",
                                                                MsInterpolationMode: "bicubic",
                                                                verticalAlign: "middle",
                                                            }}
                                                            width="60"
                                                            alt={language.locale === "fr" ? "Prix du Courtier" : "Broker Award"}
                                                        />
                                                    </td>
                                                ) : null}

                                                {member.profileImageUrl ? (
                                                    <td width="120" valign="middle" style={{ msoTableLspace: "0pt !important", msoTableRspace: "0pt !important" }}>
                                                        <img
                                                            src={member.profileImageUrl}
                                                            style={{
                                                                display: "block",
                                                                margin: "0 auto",
                                                                msoLineHeightRule: "exactly",
                                                                lineHeight: "100%",
                                                                outline: "none",
                                                                textDecoration: "none",
                                                                border: "0",
                                                                MsInterpolationMode: "bicubic",
                                                                verticalAlign: "middle",
                                                            }}
                                                            width="120"
                                                            alt={member.fullName ? member.fullName : language.locale === "fr" ? "Nommé" : "Full Name"}
                                                        />
                                                    </td>
                                                ) : null}
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr style={{ borderSpacing: "0 !important", padding: "0 !important", margin: "0 !important", background: `${colorScheme.secondary ? colorScheme.secondary : "#9654aa"}` }}>
                                <td style={{ msoTableLspace: "0pt !important", msoTableRspace: "0pt !important" }}>
                                    <table
                                        cellSpacing="10"
                                        cellPadding="0"
                                        bgcolor={colorScheme.secondary ? colorScheme.secondary : "#9654aa"}
                                        border="0"
                                        width="650"
                                        style={{
                                            margin: "0 !important",
                                            msoTableLspace: "0pt !important",
                                            msoTableRspace: "0pt !important",
                                            borderCollapse: "separate",
                                            background: `${colorScheme.secondary ? colorScheme.secondary : "#9654aa"}`,
                                        }}
                                    >
                                        <tbody>
                                            <tr style={{ borderSpacing: "0 !important", padding: "0 !important", margin: "0 !important" }}>
                                                {franchiseLogo ? (
                                                    <td width="60" style={{ msoTableLspace: "0pt !important", msoTableRspace: "0pt !important" }}>
                                                        <img
                                                            alt={user.type === "broker-user" ? user.info.franchise.name : user.info.name}
                                                            border="0"
                                                            width="60"
                                                            height="60"
                                                            src={franchiseLogo}
                                                            style={{ display: "block", outline: "none", textDecoration: "none", MsInterpolationMode: "bicubic", verticalAlign: "middle" }}
                                                        />
                                                    </td>
                                                ) : null}
                                                <td width="320" valign="middle" style={{ msoTableLspace: "0pt !important", msoTableRspace: "0pt !important", verticalAlign: "middle" }}>
                                                    <p className="MsoNormal" style={{ margin: "0", padding: "0", verticalAlign: "middle" }}>
                                                        {member.subTeamName ? (
                                                            <Fragment>
                                                                <b
                                                                    style={{
                                                                        fontSize: "10pt",
                                                                        fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                        color: "#fff",
                                                                        textTransform: "capitalize",
                                                                        margin: "2px 0 5px 0",
                                                                        fontWeight: "900",
                                                                    }}
                                                                >
                                                                    {member.subTeamName}
                                                                </b>
                                                                <br />
                                                            </Fragment>
                                                        ) : (
                                                            <Fragment>
                                                                <b
                                                                    style={{
                                                                        fontSize: "10pt",
                                                                        fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                        color: "#fff",
                                                                        textTransform: "capitalize",
                                                                        margin: "2px 0 5px 0",
                                                                        fontWeight: "900",
                                                                    }}
                                                                >
                                                                    {user.type === "broker-user" ? user.info.franchise.name : user.info.name}
                                                                </b>
                                                                <br />
                                                            </Fragment>
                                                        )}
                                                        {member.subTeamName ? (
                                                            <Fragment>
                                                                <b
                                                                    style={{
                                                                        fontSize: "9pt",
                                                                        fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                        color: "#fff",
                                                                        margin: "0",
                                                                        fontWeight: "900",
                                                                    }}
                                                                >
                                                                    {user.type === "broker-user" ? user.info.franchise.name : user.info.name}
                                                                </b>
                                                                <br />
                                                            </Fragment>
                                                        ) : null}
                                                        {franchiseLicenseNumber ? (
                                                            <Fragment>
                                                                <b
                                                                    style={{
                                                                        fontSize: "7pt",
                                                                        fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                        color: "#fff",
                                                                        margin: "0",
                                                                        fontWeight: "900",
                                                                    }}
                                                                >
                                                                    {member.subTeamName && "/"} {franchiseLicenseNumber}
                                                                </b>
                                                                <br />
                                                            </Fragment>
                                                        ) : null}
                                                        <i
                                                            style={{
                                                                fontSize: "8.5pt",
                                                                fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                color: "#fff",
                                                                margin: "0",
                                                                fontWeight: "normal",
                                                            }}
                                                        >
                                                            {language.locale === "fr" ? "Chaque franchise est autonome et indépendante" : "Independently Owned & Operated"}
                                                        </i>
                                                    </p>
                                                </td>
                                                <td width="230" style={{ msoTableLspace: "0pt !important", msoTableRspace: "0pt !important" }}>
                                                    <table
                                                        cellSpacing="0"
                                                        cellPadding="3"
                                                        border="0"
                                                        width="230"
                                                        valign="middle"
                                                        style={{
                                                            margin: "0 !important",
                                                            msoTableLspace: "0pt !important",
                                                            msoTableRspace: "0pt !important",
                                                            borderCollapse: "separate",
                                                        }}
                                                    >
                                                        <tbody>
                                                            <tr style={{ borderSpacing: "0 !important", padding: "0 !important", margin: "0 !important" }}>
                                                                <td style={{ width: "100%" }}></td>
                                                                {["facebook", "twitter", "instagram", "linkedin", "youtube", "pinterest"].map((socialMedia) =>
                                                                    features.socialMediaUrls[socialMedia] ? (
                                                                        <td width="31" valign="middle" key={socialMedia} style={{ msoTableLspace: "0pt !important", msoTableRspace: "0pt !important" }}>
                                                                            <a href={features.socialMediaUrls[socialMedia]} target="_blank" rel="noopener noreferrer">
                                                                                <img
                                                                                    src={"https://centralhost.dominionlending.ca/signatures/franchise/template-2/" + socialMedia + ".png"}
                                                                                    width="25"
                                                                                    height="25"
                                                                                    style={{
                                                                                        lineHeight: "100%",
                                                                                        outline: "none",
                                                                                        textDecoration: "none",
                                                                                        border: "0",
                                                                                        display: "block",
                                                                                        margin: "0",
                                                                                        MsInterpolationMode: "bicubic",
                                                                                        verticalAlign: "middle",
                                                                                    }}
                                                                                    alt={socialMedia}
                                                                                />
                                                                            </a>
                                                                        </td>
                                                                    ) : null
                                                                )}
                                                                <td width="70" valign="middle" style={{ msoTableLspace: "0pt !important", msoTableRspace: "0pt !important", verticalAlign: "middle" }}>
                                                                    <a
                                                                        href={language.locale === "fr" ? "https://www.hypothecairesdominion.ca/" : "https://dominionlending.ca/"}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                    >
                                                                        <img
                                                                            alt={language.locale === "fr" ? "Centres Hypothécaires Dominion" : "Dominion Lending Centres"}
                                                                            border="0"
                                                                            width="70"
                                                                            height="30"
                                                                            src={
                                                                                language.locale === "fr"
                                                                                    ? "https://centralhost.dominionlending.ca/signatures/franchise/template-2/dlc-logo-fr.png"
                                                                                    : "https://centralhost.dominionlending.ca/signatures/franchise/template-2/dlc-logo-en.png"
                                                                            }
                                                                            style={{
                                                                                lineHeight: "100%",
                                                                                outline: "none",
                                                                                textDecoration: "none",
                                                                                border: "0",
                                                                                display: "block",
                                                                                margin: "0",
                                                                                MsInterpolationMode: "bicubic",
                                                                                verticalAlign: "middle",
                                                                            }}
                                                                        />
                                                                    </a>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>

                            <tr style={{ margin: "0", padding: "0", fontSize: "0px", lineHeight: "0px", borderCollapse: "collapse" }}>
                                <td width="650" style={{ margin: "0", padding: "0" }}>
                                    <table
                                        cellSpacing="10"
                                        cellPadding="0"
                                        border="0"
                                        width="650"
                                        style={{
                                            margin: "0 !important",
                                            msoTableLspace: "0pt !important",
                                            msoTableRspace: "0pt !important",
                                            borderCollapse: "collapse",
                                            borderSpacing: "0",
                                            border: "none",
                                        }}
                                    >
                                        <tbody>
                                            <tr
                                                style={{
                                                    borderSpacing: "0 !important",
                                                    padding: "0 !important",
                                                    margin: "0 !important",
                                                    display: "block",
                                                    fontSize: "0px",
                                                    lineHeight: "0px",
                                                    borderCollapse: "collapse",
                                                }}
                                            >
                                                <td
                                                    width="650"
                                                    valign="left"
                                                    style={{
                                                        fontSize: "7.5pt",
                                                        fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                        color: `${colorScheme.text ? colorScheme.text : "#404040"}`,
                                                        fontWeight: "400",
                                                        margin: "0",
                                                        padding: "0",
                                                    }}
                                                >
                                                    {(member.bannerUrl || features.bannerUrl) && (
                                                        <a
                                                            href={features.customBannerUrl ? features.customBannerUrl : member.customBannerUrl}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            style={{
                                                                textDecoration: "none",
                                                            }}
                                                        >
                                                            <img
                                                                src={features.bannerUrl ? features.bannerUrl : member.bannerUrl}
                                                                width="650"
                                                                border="0"
                                                                alt={language.locale === "fr" ? "Bannière Personnalisée" : "Custom Banner"}
                                                                style={{
                                                                    width: "100%",
                                                                    display: "block",
                                                                    outline: "none",
                                                                    textDecoration: "none",
                                                                    MsInterpolationMode: "bicubic",
                                                                    verticalAlign: "middle",
                                                                }}
                                                            />
                                                        </a>
                                                    )}

                                                    <p
                                                        className="MsoNormal"
                                                        style={{
                                                            margin: "10px",
                                                            fontSize: "7.5pt",
                                                            fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                            color: `${colorScheme.text ? colorScheme.text : "#404040"}`,
                                                            fontWeight: "400",
                                                            lineHeight: "9pt",
                                                            padding: "0 !important",
                                                        }}
                                                    >
                                                        {features.confidentialityWarning ? (
                                                            <Fragment>
                                                                <b>{language.locale === "fr" ? "Avertissement : " : "Confidentiality Warning: "}</b>
                                                                {features.confidentialityWarning}{" "}
                                                            </Fragment>
                                                        ) : null}
                                                        {language.locale === "fr"
                                                            ? "À noter : Cet e-mail a été envoyé après la Canadienne législation antispam directives, si vous souhaitez ne plus recevoir la correspondance électronique à partir de cette adresse e-mail, veuillez cliquer ici pour"
                                                            : "Please Note: This email was sent following the Canadian Anti-Spam Legislation guidelines. If you wish to no longer receive electronic correspondence from this email address, please confirm by clicking here to"}{" "}
                                                        <a
                                                            href={"mailto:" + member.email + "?subject=unsubscribe"}
                                                            rel="noopener noreferrer"
                                                            style={{
                                                                color: `${colorScheme.links ? colorScheme.links : "#9654aa"}`,
                                                                textDecoration: "none",
                                                                padding: "0",
                                                            }}
                                                        >
                                                            {language.locale === "fr" ? "annuler un abonnement" : "unsubscribe"}
                                                        </a>
                                                        .
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                )}
            </LanguageContext.Consumer>
        </Fragment>
    );
};
