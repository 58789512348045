import React, { Component, Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import MaskedInput from "react-text-mask";

// My Components
import UserContext from "../../../contexts/UserContext";
import TemplateContext from "../../../contexts/TemplateContext";
import HelperToolTip from "../../utilities/HelperToolTip";

//Masked Input Regex
const phoneNumberMask = ["(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
const faxNumberMask = ["+", "1", " ", "(", /[1-9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];

class ContactInfoFormBroker extends Component {
    static contextType = TemplateContext;

    constructor(props) {
        super(props);
        this.handleErrorChange = this.handleErrorChange.bind(this);
    }

    handleFeatureChange(e) {
        let { template, updateTemplate } = this.context;
        template.features[e.target.name] = e.target.value;
        updateTemplate(template);
    }

    handleErrorChange(e) {
        let { template, updateTemplate } = this.context;
        template.features[e.target.name] = "";
        updateTemplate(template);
    }

    render() {
        let { template } = this.context;
        const features = template.features;
        const { intl } = this.props;

        return (
            <UserContext.Consumer>
                {({ user }) => (
                    <Fragment>
                        <Row className="sub-header">
                            <Col>
                                <FormattedMessage id="contactInfoForm.contactInfoSubHeader" defaultMessage="Contact Information" />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Formik
                                    initialValues={{
                                        fullName: template.features.fullName ? template.features.fullName : "",
                                        jobTitle: template.features.jobTitle ? template.features.jobTitle : "",
                                        licenseNumber: template.features.licenseNumber ? template.features.licenseNumber : "",
                                        phone: template.features.phone ? template.features.phone : "",
                                        extension: template.features.extension ? template.features.extension : "",
                                        mobile: template.features.mobile ? template.features.mobile : "",
                                        fax: template.features.fax ? template.features.fax : "",
                                        email: template.features.email ? template.features.email : "",
                                        website: template.features.website ? template.features.website : "",
                                    }}
                                    //enableReinitialize={true}
                                    onSubmit={(values) => {}}
                                    validationSchema={Yup.object().shape({
                                        fullName: Yup.string()
                                            .matches(
                                                /^[a-zA-ZÀ-ÿ-\s!@#$&()\\-`.+,\"]*$/,
                                                intl.formatMessage({
                                                    id: "contactInfoForm.numbersValidation",
                                                    defaultMessage: "No numbers",
                                                })
                                            )
                                            .required(
                                                intl.formatMessage({
                                                    id: "contactInfoForm.requiredValidation",
                                                    defaultMessage: "Required",
                                                })
                                            ),
                                        jobTitle: Yup.string()
                                            .matches(
                                                /^[a-zA-ZÀ-ÿ-\s!@#$&()\\-`.+,\"]*$/,
                                                intl.formatMessage({
                                                    id: "contactInfoForm.numbersValidation",
                                                    defaultMessage: "No numbers",
                                                })
                                            )
                                            .required(
                                                intl.formatMessage({
                                                    id: "contactInfoForm.requiredValidation",
                                                    defaultMessage: "Required",
                                                })
                                            ),
                                        phone: Yup.string()
                                            .matches(
                                                /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                                                intl.formatMessage({
                                                    id: "contactInfoForm.phoneValidation",
                                                    defaultMessage: "Invalid phone number",
                                                })
                                            )
                                            .notRequired(),
                                        extension: Yup.string()
                                            .matches(
                                                /^\d+$/,
                                                intl.formatMessage({
                                                    id: "contactInfoForm.lettersSpecialCharactersValidation",
                                                    defaultMessage: "No letters or special characters",
                                                })
                                            )
                                            .notRequired(),
                                        mobile: Yup.string()
                                            .matches(
                                                /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                                                intl.formatMessage({
                                                    id: "contactInfoForm.phoneValidation",
                                                    defaultMessage: "Invalid phone number",
                                                })
                                            )
                                            .notRequired(),
                                        fax: Yup.string()
                                            .matches(
                                                /^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
                                                intl.formatMessage({
                                                    id: "contactInfoForm.faxValidation",
                                                    defaultMessage: "Invalid fax number",
                                                })
                                            )
                                            .notRequired(),
                                        website: Yup.string()
                                            .matches(
                                                /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                                                intl.formatMessage({
                                                    id: "contactInfoForm.websiteValidation",
                                                    defaultMessage: "Invalid website url",
                                                })
                                            )
                                            .notRequired(),
                                        email: Yup.string()
                                            .email(
                                                intl.formatMessage({
                                                    id: "contactInfoForm.emailValidation",
                                                    defaultMessage: "Email must be a valid email",
                                                })
                                            )
                                            .notRequired(),
                                    })}
                                >
                                    {(props) => {
                                        const { values, touched, errors, handleChange, handleBlur, handleSubmit } = props;
                                        return (
                                            <form className="mb-4" onSubmit={handleSubmit}>
                                                <label className="mt-2" htmlFor="full-name">
                                                    <FormattedMessage id="contactInfoForm.fullNameLabel" defaultMessage="Full Name" />
                                                </label>
                                                <input
                                                    id="full-name"
                                                    type="text"
                                                    name="fullName"
                                                    maxLength="40"
                                                    value={values.fullName}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        this.handleFeatureChange(e);
                                                    }}
                                                    onBlur={(e) => {
                                                        handleBlur(e);

                                                        if (errors.fullName) {
                                                            this.handleErrorChange(e);
                                                        }
                                                    }}
                                                    className={errors.fullName && touched.fullName ? "text-input error" : "text-input"}
                                                />
                                                {errors.fullName && touched.fullName ? <div className="input-feedback">{errors.fullName}</div> : null}

                                                <label className="mt-4" htmlFor="job-title">
                                                    <FormattedMessage id="contactInfoForm.jobTitleLabel" defaultMessage="Job Title" />
                                                </label>

                                                <input
                                                    id="job-title"
                                                    type="text"
                                                    name="jobTitle"
                                                    maxLength="50"
                                                    value={values.jobTitle}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        this.handleFeatureChange(e);
                                                    }}
                                                    onBlur={(e) => {
                                                        handleBlur(e);

                                                        if (errors.jobTitle) {
                                                            this.handleErrorChange(e);
                                                        }
                                                    }}
                                                    className={errors.jobTitle && touched.jobTitle ? "text-input error" : "text-input"}
                                                />
                                                {errors.jobTitle && touched.jobTitle ? <div className="input-feedback">{errors.jobTitle}</div> : null}

                                                {user.type === "broker-user" && (features.office.provinceCode === "ON" || features.office.provinceCode === "SK") && (
                                                    <Fragment>
                                                        <label className="mt-4" htmlFor="broker-license">
                                                            <FormattedMessage id="contactFranchiseInfoForm.licenseNameLabel" defaultMessage="License #" />
                                                            <HelperToolTip translationId="contactFranchiseInfoForm.licenseNameLabel" defaultMessage="License #">
                                                                <FormattedMessage
                                                                    id="helperToolTip.franchiseContactFormLicense"
                                                                    defaultMessage="Please Note: Mortgage professional's license can not be editited in the email signature tool. Please change in the intranet profile if required."
                                                                />
                                                            </HelperToolTip>
                                                        </label>

                                                        <input
                                                            id="broker-license"
                                                            type="text"
                                                            name="licenseNumber"
                                                            maxLength="20"
                                                            readOnly={true}
                                                            value={values.licenseNumber}
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                this.handleFeatureChange(e);
                                                            }}
                                                            onBlur={handleBlur}
                                                            className="text-input"
                                                        />
                                                    </Fragment>
                                                )}

                                                {user.type === "broker-user" && features.office.provinceCode === "BC" && (
                                                    <Fragment>
                                                        <label className="mt-4" htmlFor="broker-license">
                                                            <FormattedMessage id="contactFranchiseInfoForm.licenseNameLabel" defaultMessage="License #" />
                                                        </label>

                                                        <input
                                                            id="broker-license"
                                                            type="text"
                                                            name="licenseNumber"
                                                            maxLength="20"
                                                            value={values.licenseNumber}
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                this.handleFeatureChange(e);
                                                            }}
                                                            onBlur={handleBlur}
                                                            className="text-input"
                                                        />
                                                    </Fragment>
                                                )}

                                                <label className="mt-4" htmlFor="office-phone">
                                                    <FormattedMessage id="contactInfoForm.officePhoneLabel" defaultMessage="Office Phone" />
                                                </label>

                                                <Field name="phone">
                                                    {({ field }) => (
                                                        <MaskedInput
                                                            {...field}
                                                            mask={phoneNumberMask}
                                                            id="office-phone"
                                                            placeholder="(###) ###-####"
                                                            type="text"
                                                            value={values.phone}
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                this.handleFeatureChange(e);
                                                            }}
                                                            onBlur={(e) => {
                                                                handleBlur(e);

                                                                if (errors.phone) {
                                                                    this.handleErrorChange(e);
                                                                }
                                                            }}
                                                            className={errors.phone && touched.phone ? "text-input error" : "text-input"}
                                                        />
                                                    )}
                                                </Field>
                                                {errors.phone && touched.phone && <div className="input-feedback">{errors.phone}</div>}

                                                {user.type === "head-office-user" && (
                                                    <Fragment>
                                                        <label className="mt-4" htmlFor="extension">
                                                            <FormattedMessage id="contactInfoForm.extensionLabel" defaultMessage="Extension" />
                                                        </label>
                                                        <input
                                                            id="extension"
                                                            type="text"
                                                            name="extension"
                                                            maxLength="4"
                                                            placeholder="ext."
                                                            value={values.extension}
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                this.handleFeatureChange(e);
                                                            }}
                                                            onBlur={(e) => {
                                                                handleBlur(e);

                                                                if (errors.extension) {
                                                                    this.handleErrorChange(e);
                                                                }
                                                            }}
                                                            className={errors.extension && touched.extension ? "text-input error" : "text-input"}
                                                        />
                                                        {errors.extension && touched.extension && <div className="input-feedback">{errors.extension}</div>}
                                                    </Fragment>
                                                )}

                                                <label className="mt-4" htmlFor="cell-phone">
                                                    <FormattedMessage id="contactInfoForm.cellPhoneLabel" defaultMessage="Cell Phone" />
                                                </label>

                                                <Field name="mobile">
                                                    {({ field }) => (
                                                        <MaskedInput
                                                            {...field}
                                                            mask={phoneNumberMask}
                                                            id="cell-phone"
                                                            placeholder="(###) ###-####"
                                                            type="text"
                                                            value={values.mobile}
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                this.handleFeatureChange(e);
                                                            }}
                                                            onBlur={(e) => {
                                                                handleBlur(e);

                                                                if (errors.mobile) {
                                                                    this.handleErrorChange(e);
                                                                }
                                                            }}
                                                            className={errors.mobile && touched.mobile ? "text-input error" : "text-input"}
                                                        />
                                                    )}
                                                </Field>
                                                {errors.mobile && touched.mobile && <div className="input-feedback">{errors.mobile}</div>}

                                                {user.type === "broker-user" && (
                                                    <Fragment>
                                                        <label className="mt-4" htmlFor="fax">
                                                            <FormattedMessage id="contactInfoForm.faxLabel" defaultMessage="Fax" />
                                                        </label>
                                                        <Field name="fax">
                                                            {({ field }) => (
                                                                <MaskedInput
                                                                    {...field}
                                                                    mask={faxNumberMask}
                                                                    id="fax"
                                                                    placeholder="+1 (###) ###-####"
                                                                    type="text"
                                                                    value={values.fax}
                                                                    onChange={(e) => {
                                                                        handleChange(e);
                                                                        this.handleFeatureChange(e);
                                                                    }}
                                                                    onBlur={(e) => {
                                                                        handleBlur(e);

                                                                        if (errors.fax) {
                                                                            this.handleErrorChange(e);
                                                                        }
                                                                    }}
                                                                    className={errors.fax && touched.fax ? "text-input error" : "text-input"}
                                                                />
                                                            )}
                                                        </Field>
                                                        {errors.fax && touched.fax && <div className="input-feedback">{errors.fax}</div>}
                                                    </Fragment>
                                                )}

                                                <label className="mt-4" htmlFor="email">
                                                    <FormattedMessage id="contactInfoForm.emailLabel" defaultMessage="Email" />
                                                </label>

                                                <input
                                                    id="email"
                                                    type="email"
                                                    name="email"
                                                    maxLength="50"
                                                    value={values.email}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        this.handleFeatureChange(e);
                                                    }}
                                                    onBlur={(e) => {
                                                        handleBlur(e);

                                                        if (errors.email) {
                                                            this.handleErrorChange(e);
                                                        }
                                                    }}
                                                    className={errors.email && touched.email ? "text-input error" : "text-input"}
                                                />
                                                {errors.email && touched.email && <div className="input-feedback">{errors.email}</div>}

                                                {user.type === "broker-user" && (
                                                    <Fragment>
                                                        <label className="mt-4" htmlFor="broker-website">
                                                            <FormattedMessage id="contactFranchiseInfoForm.websiteLabel" defaultMessage="Website" />
                                                        </label>
                                                        <input
                                                            id="broker-website"
                                                            type="text"
                                                            name="website"
                                                            maxLength="100"
                                                            value={values.website}
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                this.handleFeatureChange(e);
                                                            }}
                                                            onBlur={(e) => {
                                                                handleBlur(e);

                                                                if (errors.website) {
                                                                    this.handleErrorChange(e);
                                                                }
                                                            }}
                                                            className={errors.website && touched.website ? "text-input error" : "text-input"}
                                                        />
                                                        {errors.website && touched.website && <div className="input-feedback">{errors.website}</div>}
                                                    </Fragment>
                                                )}
                                            </form>
                                        );
                                    }}
                                </Formik>
                            </Col>
                        </Row>
                    </Fragment>
                )}
            </UserContext.Consumer>
        );
    }
}

export default injectIntl(ContactInfoFormBroker);
