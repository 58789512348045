import React, { Fragment, useContext } from "react";
import TemplateContext from "../../../contexts/TemplateContext";
import UserContext from "../../../contexts/UserContext";
import { LanguageContext } from "../../../contexts/LanguageProvider";

export const FranchiseTemplateFour = () => {
    const { template } = useContext(TemplateContext);
    const { user } = useContext(UserContext);
    const features = template.features;
    const franchiseLogo = user.type === "broker-user" ? features.franchise.logoUrl : features.logoUrl;
    const franchiseWebsite = user.type === "broker-user" ? features.franchise.website : features.website;
    const franchiseLicenseNumber = user.type === "broker-user" ? user.info.franchise.licenseNumber : user.info.licenseNumber;
    const member = user.type === "broker-user" ? features : template.member;
    let colorScheme = null;
    if (user.type === "broker-user") {
        colorScheme = features.franchise.colorScheme;
    } else {
        colorScheme = features.colorScheme;
    }

    return (
        <Fragment>
            <LanguageContext.Consumer>
                {(language) => (
                    <table
                        id="main-table"
                        cellSpacing="0"
                        border="0"
                        width="650"
                        style={{
                            margin: "0",
                            padding: "0",
                            borderCollapse: "separate",
                            msoTableLspace: "0pt !important",
                            msoTableRspace: "0pt !important",
                            lineHeight: "100%",
                            wordBreak: "break-word",
                        }}
                    >
                        <tbody>
                            <tr style={{ borderSpacing: "0 !important", padding: "0 !important", margin: "0 !important" }}>
                                <td style={{ margin: "0", padding: "0", msoTableLspace: "0pt !important", msoTableRspace: "0pt !important" }}>
                                    <table
                                        cellSpacing="0"
                                        border="0"
                                        width="650"
                                        style={{ margin: "0", borderCollapse: "separate", msoTableLspace: "0pt !important", msoTableRspace: "0pt !important" }}
                                    >
                                        <tbody>
                                            <tr style={{ borderSpacing: "0 !important", padding: "0 !important", margin: "0 !important" }}>
                                                <td width="650px" valign="top" style={{ margin: "0", padding: "0", msoTableLspace: "0pt !important", msoTableRspace: "0pt !important" }}>
                                                    <table
                                                        cellSpacing="10"
                                                        cellPadding="0"
                                                        border="0"
                                                        bgcolor="#ffffff"
                                                        width="650"
                                                        style={{
                                                            margin: "0",
                                                            background: "#ffffff",
                                                            borderCollapse: "separate",
                                                            msoTableLspace: "0pt !important",
                                                            msoTableRspace: "0pt !important",
                                                        }}
                                                    >
                                                        <tbody>
                                                            <tr style={{ borderSpacing: "0 !important", padding: "0 !important", margin: "0 !important" }}>
                                                                {member.profileImageUrl || member.applicationUrl || member.mortgageToolboxUrl ? (
                                                                    <td
                                                                        width="130"
                                                                        valign="top"
                                                                        style={{
                                                                            margin: "0",
                                                                            padding: "0",
                                                                            textAlign: "center",
                                                                            msoTableLspace: "0pt !important",
                                                                            msoTableRspace: "0pt !important",
                                                                            verticalAlign: "top",
                                                                        }}
                                                                    >
                                                                        {member.profileImageUrl ? (
                                                                            <p
                                                                                className="MsoNormal"
                                                                                style={{
                                                                                    margin: "10px 0 5px 0",
                                                                                    textAlign: "center",
                                                                                    msoStyleUnhide: "no",
                                                                                    msoStyleQformat: "yes",
                                                                                    msoStyleParent: "",
                                                                                    msoPagination: "widow-orphan",
                                                                                    msoBidiThemeFont: "minor-bidi",
                                                                                }}
                                                                            >
                                                                                <img
                                                                                    src={member.profileImageUrl}
                                                                                    width="100"
                                                                                    border="0"
                                                                                    alt={member.fullName ? member.fullName : language.locale === "fr" ? "Nommé" : "Full Name"}
                                                                                    style={{
                                                                                        margin: "0 auto",
                                                                                        display: "block",
                                                                                        outline: "none",
                                                                                        textDecoration: "none",
                                                                                        MsInterpolationMode: "bicubic",
                                                                                        verticalAlign: "middle",
                                                                                    }}
                                                                                />
                                                                            </p>
                                                                        ) : (
                                                                            <table
                                                                                cellSpacing="10"
                                                                                cellPadding="0"
                                                                                border="0"
                                                                                width="130"
                                                                                bgcolor="#ffffff"
                                                                                style={{
                                                                                    margin: "0",
                                                                                    background: "#ffffff",
                                                                                    borderCollapse: "separate",
                                                                                    msoTableLspace: "0pt !important",
                                                                                    msoTableRspace: "0pt !important",
                                                                                }}
                                                                            >
                                                                                <tbody>
                                                                                    <tr
                                                                                        style={{ borderSpacing: "0 !important", padding: "0 !important", margin: "0 !important", verticalAlign: "top" }}
                                                                                    >
                                                                                        <td
                                                                                            width="110"
                                                                                            valign="top"
                                                                                            style={{
                                                                                                margin: "0",
                                                                                                padding: "0",
                                                                                                textAlign: "center",
                                                                                                verticalAlign: "top",
                                                                                                msoTableLspace: "0pt !important",
                                                                                                msoTableRspace: "0pt !important",
                                                                                            }}
                                                                                        >
                                                                                            {member.applicationUrl || member.mortgageToolboxUrl ? (
                                                                                                <Fragment>
                                                                                                    <p
                                                                                                        className="MsoNormal"
                                                                                                        style={{
                                                                                                            background: `${colorScheme.primary ? colorScheme.primary : "#1ABC9C"}`,
                                                                                                            textAlign: "center",
                                                                                                            lineHeight: "5px",
                                                                                                            msoStyleUnhide: "no",
                                                                                                            msoStyleQformat: "yes",
                                                                                                            msoStyleParent: "",
                                                                                                            verticalAlign: "top",
                                                                                                            margin: "0cm",
                                                                                                            marginBottom: ".0001pt",
                                                                                                            msoPagination: "widow-orphan",
                                                                                                            msoBidiThemeFont: "minor-bidi",
                                                                                                        }}
                                                                                                    >
                                                                                                        {member.applicationUrl ? (
                                                                                                            <a
                                                                                                                href={member.applicationUrl}
                                                                                                                target="_blank"
                                                                                                                rel="noopener noreferrer"
                                                                                                                style={{ width: "100px", padding: "0" }}
                                                                                                            >
                                                                                                                <img
                                                                                                                    src={
                                                                                                                        language.locale === "fr"
                                                                                                                            ? "https://centralhost.dominionlending.ca/signatures/franchise/template-4/apply-now-btn-fr.png"
                                                                                                                            : "https://centralhost.dominionlending.ca/signatures/franchise/template-4/apply-now-btn-en.png"
                                                                                                                    }
                                                                                                                    width="100"
                                                                                                                    height="20"
                                                                                                                    border="0"
                                                                                                                    alt={language.locale === "fr" ? "Présenter une Demande" : "Apply Now"}
                                                                                                                    style={{
                                                                                                                        margin: "0 auto",
                                                                                                                        display: "block",
                                                                                                                        border: "none",
                                                                                                                        outline: "none",
                                                                                                                        textDecoration: "none",
                                                                                                                        MsInterpolationMode: "bicubic",
                                                                                                                        verticalAlign: "middle",
                                                                                                                    }}
                                                                                                                />
                                                                                                            </a>
                                                                                                        ) : null}

                                                                                                        {member.mortgageToolboxUrl ? (
                                                                                                            <Fragment>
                                                                                                                <img
                                                                                                                    src="https://centralhost.dominionlending.ca/signatures/franchise/template-4/spacer.jpg"
                                                                                                                    width="110"
                                                                                                                    height="5"
                                                                                                                    alt={language.locale === "fr" ? "Espaceur" : "Spacer"}
                                                                                                                    style={{
                                                                                                                        margin: "0 auto",
                                                                                                                        display: "block",
                                                                                                                        outline: "none",
                                                                                                                        textDecoration: "none",
                                                                                                                        border: "none",
                                                                                                                        MsInterpolationMode: "bicubic",
                                                                                                                        verticalAlign: "middle",
                                                                                                                    }}
                                                                                                                />
                                                                                                                <a
                                                                                                                    href={member.mortgageToolboxUrl}
                                                                                                                    target="_blank"
                                                                                                                    rel="noopener noreferrer"
                                                                                                                    style={{ width: "100px", padding: "0" }}
                                                                                                                >
                                                                                                                    <img
                                                                                                                        src={
                                                                                                                            language.locale === "fr"
                                                                                                                                ? "https://centralhost.dominionlending.ca/signatures/franchise/template-4/pre-qualified-btn-fr.png"
                                                                                                                                : "https://centralhost.dominionlending.ca/signatures/franchise/template-4/pre-qualified-btn-en.png"
                                                                                                                        }
                                                                                                                        width="99"
                                                                                                                        height="20"
                                                                                                                        border="0"
                                                                                                                        alt={
                                                                                                                            language.locale === "fr"
                                                                                                                                ? "Obtenir une Préapprobation"
                                                                                                                                : "Get Pre-qualified"
                                                                                                                        }
                                                                                                                        style={{
                                                                                                                            margin: "0 auto",
                                                                                                                            display: "block",
                                                                                                                            border: "none",
                                                                                                                            outline: "none",
                                                                                                                            textDecoration: "none",
                                                                                                                            MsInterpolationMode: "bicubic",
                                                                                                                            verticalAlign: "middle",
                                                                                                                        }}
                                                                                                                    />
                                                                                                                </a>
                                                                                                            </Fragment>
                                                                                                        ) : null}
                                                                                                    </p>
                                                                                                </Fragment>
                                                                                            ) : null}
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        )}
                                                                    </td>
                                                                ) : null}

                                                                <td
                                                                    width={member.profileImageUrl || member.applicationUrl || member.mortgageToolboxUrl ? "240" : "305"}
                                                                    valign="top"
                                                                    style={{ margin: "0", padding: "0", msoTableLspace: "0pt !important", msoTableRspace: "0pt !important", verticalAlign: "top" }}
                                                                >
                                                                    <p
                                                                        className="MsoNormal"
                                                                        style={{
                                                                            fontSize: "12pt",
                                                                            fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                            color: `${colorScheme.headers ? colorScheme.headers : "#1ABC9C"}`,
                                                                            textTransform: "capitalize",
                                                                            margin: "10px 0 5px 0",
                                                                            fontWeight: "900",
                                                                            msoStyleUnhide: "no",
                                                                            msoStyleQformat: "yes",
                                                                            msoStyleParent: "",
                                                                            msoPagination: "widow-orphan",
                                                                            msoBidiThemeFont: "minor-bidi",
                                                                        }}
                                                                    >
                                                                        <b>{member.fullName ? member.fullName : language.locale === "fr" ? "Nommé" : "Full Name"}</b>
                                                                        <br />
                                                                        <span
                                                                            style={{
                                                                                fontSize: "10pt",
                                                                                fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                                color: `${colorScheme.text ? colorScheme.text : "#404040"}`,
                                                                                margin: "0",
                                                                                fontWeight: "normal",
                                                                                display: "block",
                                                                            }}
                                                                        >
                                                                            {member.jobTitle ? member.jobTitle : language.locale === "fr" ? "Titre du Poste" : "Position Title"}

                                                                            {member.licenseNumber ? (
                                                                                <Fragment>
                                                                                    <br />
                                                                                    {member.licenseNumber}
                                                                                </Fragment>
                                                                            ) : null}
                                                                        </span>
                                                                    </p>

                                                                    {member.phone || member.mobile || member.fax || member.email || member.website || member.id !== "" ? (
                                                                        <p
                                                                            className="MsoNormal"
                                                                            style={{
                                                                                margin: "5px 0 0 0",
                                                                                fontSize: "8pt",
                                                                                fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                                color: `${colorScheme.text ? colorScheme.text : "#404040"}`,
                                                                                msoStyleUnhide: "no",
                                                                                msoStyleQformat: "yes",
                                                                                msoStyleParent: "",
                                                                                msoPagination: "widow-orphan",
                                                                                msoBidiThemeFont: "minor-bidi",
                                                                            }}
                                                                        >
                                                                            {member.phone ? (
                                                                                <Fragment>
                                                                                    <b>{language.locale === "fr" ? "Téléphone" : "Phone"}: </b>
                                                                                    <a
                                                                                        href={"tel:" + member.phone}
                                                                                        rel="noopener noreferrer"
                                                                                        style={{ color: `${colorScheme.links ? colorScheme.links : "#1ABC9C"}`, textDecoration: "none", padding: "0" }}
                                                                                    >
                                                                                        {member.phone}
                                                                                    </a>
                                                                                    <br />
                                                                                </Fragment>
                                                                            ) : null}

                                                                            {member.mobile ? (
                                                                                <Fragment>
                                                                                    <b>{language.locale === "fr" ? "Cellulaire" : "Mobile"}: </b>
                                                                                    <a
                                                                                        href={"tel:" + member.mobile}
                                                                                        rel="noopener noreferrer"
                                                                                        style={{ color: `${colorScheme.links ? colorScheme.links : "#1ABC9C"}`, textDecoration: "none", padding: "0" }}
                                                                                    >
                                                                                        {member.mobile}
                                                                                    </a>
                                                                                    <br />
                                                                                </Fragment>
                                                                            ) : null}

                                                                            {member.fax ? (
                                                                                <Fragment>
                                                                                    <b>{language.locale === "fr" ? "Télécopieur" : "Fax"}: </b>
                                                                                    {member.fax}
                                                                                    <br />
                                                                                </Fragment>
                                                                            ) : null}

                                                                            {member.email ? (
                                                                                <Fragment>
                                                                                    <a
                                                                                        href={"mailto:" + member.email}
                                                                                        rel="noopener noreferrer"
                                                                                        style={{
                                                                                            color: `${colorScheme.links ? colorScheme.links : "#1ABC9C"}`,
                                                                                            textDecoration: "none",
                                                                                            padding: "0",
                                                                                        }}
                                                                                    >
                                                                                        {member.email}
                                                                                    </a>
                                                                                    <br />
                                                                                </Fragment>
                                                                            ) : null}

                                                                            {member.website ? (
                                                                                <a
                                                                                    href={"https://" + member.website}
                                                                                    rel="noopener noreferrer"
                                                                                    style={{ color: `${colorScheme.links ? colorScheme.links : "#1ABC9C"}`, textDecoration: "none", padding: "0" }}
                                                                                    target="_blank"
                                                                                >
                                                                                    {" "}
                                                                                    {member.website}
                                                                                </a>
                                                                            ) : null}
                                                                        </p>
                                                                    ) : null}
                                                                </td>

                                                                <td
                                                                    width={member.profileImageUrl || member.applicationUrl || member.mortgageToolboxUrl ? "250" : "315"}
                                                                    valign="top"
                                                                    style={{ margin: "0", padding: "0", msoTableLspace: "0pt !important", msoTableRspace: "0pt !important", verticalAlign: "top" }}
                                                                >
                                                                    <p
                                                                        className="MsoNormal"
                                                                        style={{
                                                                            fontSize: "12pt",
                                                                            fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                            color: `${colorScheme.headers ? colorScheme.headers : "#1ABC9C"}`,
                                                                            textTransform: "capitalize",
                                                                            margin: "10px 0 5px 0",
                                                                            fontWeight: "900",
                                                                            msoStyleUnhide: "no",
                                                                            msoStyleQformat: "yes",
                                                                            msoStyleParent: "",
                                                                            msoPagination: "widow-orphan",
                                                                            msoBidiThemeFont: "minor-bidi",
                                                                        }}
                                                                    >
                                                                        {member.subTeamName ? (
                                                                            <Fragment>
                                                                                <b>{member.subTeamName}</b>
                                                                                <br />
                                                                            </Fragment>
                                                                        ) : (
                                                                            <Fragment>
                                                                                <b>{user.type === "broker-user" ? user.info.franchise.name : user.info.name}</b>
                                                                                <br />
                                                                            </Fragment>
                                                                        )}

                                                                        {member.subTeamName || franchiseLicenseNumber ? (
                                                                            <span
                                                                                style={{
                                                                                    margin: "0 0 5px 0",
                                                                                    fontSize: "10pt",
                                                                                    fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                                    color: `${colorScheme.text ? colorScheme.text : "#404040"}`,
                                                                                    fontWeight: "normal",
                                                                                    display: "block",
                                                                                }}
                                                                            >
                                                                                {member.subTeamName ? (
                                                                                    <Fragment>
                                                                                        {user.type === "broker-user" ? user.info.franchise.name : user.info.name}
                                                                                        {franchiseLicenseNumber ? <br /> : null}
                                                                                    </Fragment>
                                                                                ) : null}
                                                                                {franchiseLicenseNumber ? (
                                                                                    <Fragment>
                                                                                        <span>{franchiseLicenseNumber}</span>
                                                                                    </Fragment>
                                                                                ) : null}
                                                                            </span>
                                                                        ) : null}
                                                                    </p>

                                                                    <p
                                                                        className="MsoNormal"
                                                                        style={{
                                                                            fontSize: "8pt",
                                                                            fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                            color: `${colorScheme.text ? colorScheme.text : "#404040"}`,
                                                                            margin: "5px 0 0 0",
                                                                            fontWeight: "normal",
                                                                            msoStyleUnhide: "no",
                                                                            msoStyleQformat: "yes",
                                                                            msoStyleParent: "",
                                                                            msoPagination: "widow-orphan",
                                                                            msoBidiThemeFont: "minor-bidi",
                                                                        }}
                                                                    >
                                                                        {template.features.office && (
                                                                            <Fragment>
                                                                                {features.office.addressLineTwo && features.office.addressLineTwo + "-"}
                                                                                {features.office.streetAddress}
                                                                                <br />
                                                                                {features.office.city}, {features.office.provinceCode}, {features.office.postalCode}
                                                                                <br />
                                                                            </Fragment>
                                                                        )}

                                                                        {franchiseWebsite ? (
                                                                            <Fragment>
                                                                                <a
                                                                                    href={"https://" + franchiseWebsite}
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                    style={{
                                                                                        fontSize: "8pt",
                                                                                        fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                                        color: `${colorScheme.links ? colorScheme.links : "#1ABC9C"}`,
                                                                                        textDecoration: "none",
                                                                                        padding: "0",
                                                                                    }}
                                                                                >
                                                                                    {franchiseWebsite}
                                                                                </a>
                                                                                <br />
                                                                            </Fragment>
                                                                        ) : null}
                                                                        <i>{language.locale === "fr" ? "Chaque franchise est autonome et indépendante" : "Independently Owned & Operated"}</i>
                                                                    </p>
                                                                </td>
                                                            </tr>

                                                            <tr style={{ borderSpacing: "0 !important", padding: "0 !important", margin: "0 !important" }}>
                                                                {member.profileImageUrl || member.applicationUrl || member.mortgageToolboxUrl ? (
                                                                    <td
                                                                        width="130"
                                                                        valign="top"
                                                                        style={{
                                                                            margin: "0",
                                                                            padding: "0",
                                                                            textAlign: "center",
                                                                            msoTableLspace: "0pt !important",
                                                                            msoTableRspace: "0pt !important",
                                                                            verticalAlign: "middle",
                                                                        }}
                                                                    >
                                                                        {member.profileImageUrl ? (
                                                                            <table
                                                                                cellSpacing="10"
                                                                                cellPadding="0"
                                                                                border="0"
                                                                                width="130"
                                                                                bgcolor="#ffffff"
                                                                                style={{
                                                                                    margin: "0",
                                                                                    background: "#ffffff",
                                                                                    borderCollapse: "separate",
                                                                                    msoTableLspace: "0pt !important",
                                                                                    msoTableRspace: "0pt !important",
                                                                                }}
                                                                            >
                                                                                <tbody>
                                                                                    <tr style={{ borderSpacing: "0 !important", padding: "0 !important", margin: "0 !important" }}>
                                                                                        <td
                                                                                            width="110"
                                                                                            valign="top"
                                                                                            style={{
                                                                                                margin: "0",
                                                                                                padding: "0",
                                                                                                textAlign: "center",
                                                                                                msoTableLspace: "0pt !important",
                                                                                                msoTableRspace: "0pt !important",
                                                                                            }}
                                                                                        >
                                                                                            {member.applicationUrl || member.mortgageToolboxUrl ? (
                                                                                                <Fragment>
                                                                                                    <p
                                                                                                        className="MsoNormal"
                                                                                                        style={{
                                                                                                            background: `${colorScheme.primary ? colorScheme.primary : "#1ABC9C"}`,
                                                                                                            textAlign: "center",
                                                                                                            lineHeight: "5px",
                                                                                                            msoStyleUnhide: "no",
                                                                                                            msoStyleQformat: "yes",
                                                                                                            msoStyleParent: "",
                                                                                                            margin: "0cm",
                                                                                                            marginBottom: ".0001pt",
                                                                                                            msoPagination: "widow-orphan",
                                                                                                            msoBidiThemeFont: "minor-bidi",
                                                                                                        }}
                                                                                                    >
                                                                                                        {member.applicationUrl ? (
                                                                                                            <a
                                                                                                                href={member.applicationUrl}
                                                                                                                target="_blank"
                                                                                                                rel="noopener noreferrer"
                                                                                                                style={{ width: "100px", padding: "0" }}
                                                                                                            >
                                                                                                                <img
                                                                                                                    src={
                                                                                                                        language.locale === "fr"
                                                                                                                            ? "https://centralhost.dominionlending.ca/signatures/franchise/template-4/apply-now-btn-fr.png"
                                                                                                                            : "https://centralhost.dominionlending.ca/signatures/franchise/template-4/apply-now-btn-en.png"
                                                                                                                    }
                                                                                                                    width="100"
                                                                                                                    height="20"
                                                                                                                    border="0"
                                                                                                                    alt={language.locale === "fr" ? "Présenter une Demande" : "Apply Now"}
                                                                                                                    style={{
                                                                                                                        margin: "0 auto",
                                                                                                                        display: "block",
                                                                                                                        border: "none",
                                                                                                                        outline: "none",
                                                                                                                        textDecoration: "none",
                                                                                                                        MsInterpolationMode: "bicubic",
                                                                                                                        verticalAlign: "middle",
                                                                                                                    }}
                                                                                                                />
                                                                                                            </a>
                                                                                                        ) : null}

                                                                                                        {member.mortgageToolboxUrl ? (
                                                                                                            <Fragment>
                                                                                                                <img
                                                                                                                    src="https://centralhost.dominionlending.ca/signatures/franchise/template-4/spacer.jpg"
                                                                                                                    width="110"
                                                                                                                    height="5"
                                                                                                                    alt={language.locale === "fr" ? "Espaceur" : "Spacer"}
                                                                                                                    style={{
                                                                                                                        margin: "0 auto",
                                                                                                                        display: "block",
                                                                                                                        outline: "none",
                                                                                                                        textDecoration: "none",
                                                                                                                        border: "none",
                                                                                                                        MsInterpolationMode: "bicubic",
                                                                                                                        verticalAlign: "middle",
                                                                                                                    }}
                                                                                                                />
                                                                                                                <a
                                                                                                                    href={member.mortgageToolboxUrl}
                                                                                                                    target="_blank"
                                                                                                                    rel="noopener noreferrer"
                                                                                                                    style={{ width: "100px", padding: "0" }}
                                                                                                                >
                                                                                                                    <img
                                                                                                                        src={
                                                                                                                            language.locale === "fr"
                                                                                                                                ? "https://centralhost.dominionlending.ca/signatures/franchise/template-4/pre-qualified-btn-fr.png"
                                                                                                                                : "https://centralhost.dominionlending.ca/signatures/franchise/template-4/pre-qualified-btn-en.png"
                                                                                                                        }
                                                                                                                        width="99"
                                                                                                                        height="20"
                                                                                                                        border="0"
                                                                                                                        alt={
                                                                                                                            language.locale === "fr"
                                                                                                                                ? "Obtenir une Préapprobation"
                                                                                                                                : "Get Pre-qualified"
                                                                                                                        }
                                                                                                                        style={{
                                                                                                                            margin: "0 auto",
                                                                                                                            display: "block",
                                                                                                                            border: "none",
                                                                                                                            outline: "none",
                                                                                                                            textDecoration: "none",
                                                                                                                            MsInterpolationMode: "bicubic",
                                                                                                                            verticalAlign: "middle",
                                                                                                                        }}
                                                                                                                    />
                                                                                                                </a>
                                                                                                            </Fragment>
                                                                                                        ) : null}
                                                                                                    </p>
                                                                                                </Fragment>
                                                                                            ) : null}
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        ) : null}
                                                                    </td>
                                                                ) : null}

                                                                <td
                                                                    width={member.profileImageUrl || member.applicationUrl || member.mortgageToolboxUrl ? "240" : "305"}
                                                                    align="right"
                                                                    valign="middle"
                                                                    style={{ margin: "0", padding: "0", msoTableLspace: "0pt !important", msoTableRspace: "0pt !important", verticalAlign: "middle" }}
                                                                >
                                                                    <table
                                                                        cellSpacing="2"
                                                                        cellPadding="0"
                                                                        border="0"
                                                                        align="left"
                                                                        style={{ margin: "15px 0", borderCollapse: "separate", msoTableLspace: "0pt !important", msoTableRspace: "0pt !important" }}
                                                                    >
                                                                        <tbody>
                                                                            <tr
                                                                                valign="middle"
                                                                                style={{ borderSpacing: "0 !important", padding: "0 !important", margin: "0 !important", verticalAlign: "middle" }}
                                                                            >
                                                                                {["facebook", "twitter", "instagram", "linkedin", "youtube", "pinterest"].map((socialMedia) =>
                                                                                    features.socialMediaUrls[socialMedia] ? (
                                                                                        <td
                                                                                            width="31"
                                                                                            align="left"
                                                                                            valign="middle"
                                                                                            style={{
                                                                                                margin: "0",
                                                                                                padding: "0",
                                                                                                msoTableLspace: "0pt !important",
                                                                                                msoTableRspace: "0pt !important",
                                                                                                verticalAlign: "middle",
                                                                                            }}
                                                                                        >
                                                                                            <a
                                                                                                href={features.socialMediaUrls[socialMedia]}
                                                                                                target="_blank"
                                                                                                rel="noopener noreferrer"
                                                                                                style={{ padding: "0" }}
                                                                                            >
                                                                                                <img
                                                                                                    src={
                                                                                                        "https://centralhost.dominionlending.ca/signatures/franchise/template-4/" + socialMedia + ".png"
                                                                                                    }
                                                                                                    width="30"
                                                                                                    height="30"
                                                                                                    style={{
                                                                                                        lineHeight: "100%",
                                                                                                        outline: "none",
                                                                                                        textDecoration: "none",
                                                                                                        border: "0",
                                                                                                        display: "block",
                                                                                                        margin: "0",
                                                                                                    }}
                                                                                                    alt={socialMedia}
                                                                                                />
                                                                                            </a>
                                                                                        </td>
                                                                                    ) : null
                                                                                )}
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>

                                                                <td
                                                                    width={member.profileImageUrl || member.applicationUrl || member.mortgageToolboxUrl ? "250" : "315"}
                                                                    valign="middle"
                                                                    align="right"
                                                                    style={{ margin: "0", padding: "0", verticalAlign: "middle" }}
                                                                >
                                                                    <table
                                                                        cellSpacing="0"
                                                                        cellPadding="2"
                                                                        border="0"
                                                                        align="right"
                                                                        width={member.profileImageUrl || member.applicationUrl || member.mortgageToolboxUrl ? "250" : "315"}
                                                                        style={{ margin: "12px 0", borderCollapse: "separate", msoTableLspace: "0pt !important", msoTableRspace: "0pt !important" }}
                                                                    >
                                                                        <tbody>
                                                                            <tr>
                                                                                <td style={{ width: "100%" }}></td>
                                                                                {member.awardImageUrl ? (
                                                                                    <td
                                                                                        valign="right"
                                                                                        align="right"
                                                                                        width="52"
                                                                                        style={{ msoTableLspace: "0pt !important", msoTableRspace: "0pt !important" }}
                                                                                    >
                                                                                        <img
                                                                                            src={member.awardImageUrl}
                                                                                            width="48"
                                                                                            border="0"
                                                                                            alt={language.locale === "fr" ? "Prix du Courtier" : "Broker Award"}
                                                                                            style={{
                                                                                                margin: "0",
                                                                                                display: "block",
                                                                                                border: "none",
                                                                                                outline: "none",
                                                                                                textDecoration: "none",
                                                                                                MsInterpolationMode: "bicubic",
                                                                                                verticalAlign: "middle",
                                                                                            }}
                                                                                        />
                                                                                    </td>
                                                                                ) : null}

                                                                                {member.subTeamLogoUrl ? (
                                                                                    <td
                                                                                        valign="right"
                                                                                        align="right"
                                                                                        width="44"
                                                                                        style={{ msoTableLspace: "0pt !important", msoTableRspace: "0pt !important" }}
                                                                                    >
                                                                                        <img
                                                                                            src={member.subTeamLogoUrl}
                                                                                            width="40"
                                                                                            height="40"
                                                                                            border="0"
                                                                                            alt={member.subTeamName}
                                                                                            style={{
                                                                                                margin: "0",
                                                                                                display: "block",
                                                                                                outline: "none",
                                                                                                textDecoration: "none",
                                                                                                border: "none",
                                                                                                MsInterpolationMode: "bicubic",
                                                                                                verticalAlign: "middle",
                                                                                            }}
                                                                                        />
                                                                                    </td>
                                                                                ) : null}

                                                                                {franchiseLogo ? (
                                                                                    <td
                                                                                        valign="right"
                                                                                        align="right"
                                                                                        width="44"
                                                                                        style={{ msoTableLspace: "0pt !important", msoTableRspace: "0pt !important" }}
                                                                                    >
                                                                                        <img
                                                                                            src={franchiseLogo}
                                                                                            width="40"
                                                                                            height="40"
                                                                                            border="0"
                                                                                            alt={user.type === "broker-user" ? user.info.franchise.name : user.info.name}
                                                                                            style={{
                                                                                                margin: "0",
                                                                                                display: "block",
                                                                                                outline: "none",
                                                                                                textDecoration: "none",
                                                                                                border: "none",
                                                                                                MsInterpolationMode: "bicubic",
                                                                                                verticalAlign: "middle",
                                                                                            }}
                                                                                        />
                                                                                    </td>
                                                                                ) : null}

                                                                                <td
                                                                                    valign="middle"
                                                                                    align="right"
                                                                                    width="110"
                                                                                    style={{ msoTableLspace: "0pt !important", msoTableRspace: "0pt !important", verticalAlign: "middle" }}
                                                                                >
                                                                                    <a
                                                                                        href={language.locale === "fr" ? "https://www.hypothecairesdominion.ca/" : "https://dominionlending.ca/"}
                                                                                        target="_blank"
                                                                                        rel="noopener noreferrer"
                                                                                        style={{ padding: "0" }}
                                                                                    >
                                                                                        <img
                                                                                            src={
                                                                                                language.locale === "fr"
                                                                                                    ? "https://centralhost.dominionlending.ca/signatures/franchise/template-4/dlc-logo-fr.png"
                                                                                                    : "https://centralhost.dominionlending.ca/signatures/franchise/template-4/dlc-logo-en.png"
                                                                                            }
                                                                                            width="105"
                                                                                            height="40"
                                                                                            border="0"
                                                                                            alt={language.locale === "fr" ? "Centres Hypothécaires Dominion" : "Dominion Lending Centres"}
                                                                                            style={{
                                                                                                margin: "0",
                                                                                                display: "block",
                                                                                                border: "none",
                                                                                                outline: "none",
                                                                                                textDecoration: "none",
                                                                                                MsInterpolationMode: "bicubic",
                                                                                                verticalAlign: "middle",
                                                                                            }}
                                                                                        />
                                                                                    </a>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr style={{ margin: "0", padding: "0", fontSize: "0px", lineHeight: "0px", borderCollapse: "collapse" }}>
                                <td width="650" style={{ margin: "0", padding: "0", msoTableLspace: "0pt !important", msoTableRspace: "0pt !important" }}>
                                    <table
                                        cellSpacing="10"
                                        cellPadding="0"
                                        border="0"
                                        width="650"
                                        bgcolor={colorScheme.secondary ? colorScheme.secondary : "#efefef"}
                                        style={{
                                            margin: "0",
                                            borderCollapse: "collapse",
                                            borderSpacing: "0",
                                            border: "none",
                                            msoTableLspace: "0pt !important",
                                            msoTableRspace: "0pt !important",
                                            background: `${colorScheme.secondary ? colorScheme.secondary : "#efefef"}`,
                                            borderTop: "5px solid",
                                            borderColor: `${colorScheme.primary ? colorScheme.primary : "#1ABC9C"}`,
                                        }}
                                    >
                                        <tbody>
                                            <tr
                                                style={{
                                                    borderSpacing: "0 !important",
                                                    padding: "0 !important",
                                                    margin: "0 !important",
                                                    display: "block",
                                                    fontSize: "0px",
                                                    lineHeight: "0px",
                                                    borderCollapse: "collapse",
                                                }}
                                            >
                                                <td
                                                    width="650"
                                                    valign="left"
                                                    style={{
                                                        fontSize: "7.5pt",
                                                        fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                        color: "#1f1f1f",
                                                        fontWeight: "400",
                                                        margin: "0",
                                                        padding: "0",
                                                        msoTableLspace: "0pt !important",
                                                        msoTableRspace: "0pt !important",
                                                    }}
                                                >
                                                    {member.bannerUrl || features.bannerUrl ? (
                                                        <a
                                                            href={features.customBannerUrl ? features.customBannerUrl : member.customBannerUrl}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            style={{
                                                                textDecoration: "none",
                                                            }}
                                                        >
                                                            <img
                                                                src={features.bannerUrl ? features.bannerUrl : member.bannerUrl}
                                                                width="650"
                                                                border="0"
                                                                alt={language.locale === "fr" ? "Bannière Personnalisée" : "Custom Banner"}
                                                                style={{
                                                                    width: "100%",
                                                                    display: "block",
                                                                    border: "none",
                                                                    outline: "none",
                                                                    textDecoration: "none",
                                                                    MsInterpolationMode: "bicubic",
                                                                    verticalAlign: "middle",
                                                                }}
                                                            />
                                                        </a>
                                                    ) : null}

                                                    <p
                                                        className="MsoNormal"
                                                        style={{
                                                            margin: "10px",
                                                            fontSize: "7pt",
                                                            fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                            color: `${colorScheme.text ? colorScheme.text : "#404040"}`,
                                                            fontWeight: "400",
                                                            lineHeight: "10pt",
                                                        }}
                                                    >
                                                        {features.confidentialityWarning ? (
                                                            <Fragment>
                                                                <b>{language.locale === "fr" ? "Avertissement : " : "Confidentiality Warning: "}</b>
                                                                {features.confidentialityWarning}{" "}
                                                            </Fragment>
                                                        ) : null}
                                                        {language.locale === "fr"
                                                            ? "À noter : Cet e-mail a été envoyé après la Canadienne législation antispam directives, si vous souhaitez ne plus recevoir la correspondance électronique à partir de cette adresse e-mail, veuillez cliquer ici pour"
                                                            : "Please Note: This email was sent following the Canadian Anti-Spam Legislation guidelines. If you wish to no longer receive electronic correspondence from this email address, please confirm by clicking here to"}{" "}
                                                        <a
                                                            href={"mailto:" + member.email + "?subject=unsubscribe"}
                                                            rel="noopener noreferrer"
                                                            style={{
                                                                color: `${colorScheme.links ? colorScheme.links : "#1ABC9C"}`,
                                                                textDecoration: "none",
                                                                padding: "0",
                                                            }}
                                                        >
                                                            {language.locale === "fr" ? "annuler un abonnement" : "unsubscribe"}
                                                        </a>
                                                        .
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                )}
            </LanguageContext.Consumer>
        </Fragment>
    );
};
