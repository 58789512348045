import React, { Fragment, useContext } from "react";
import TemplateContext from "../../../contexts/TemplateContext";
import UserContext from "../../../contexts/UserContext";
import { LanguageContext } from "../../../contexts/LanguageProvider";

export const BrokerTemplateThree = () => {
    const { template } = useContext(TemplateContext);
    const { user } = useContext(UserContext);
    const features = template.features;

    return (
        <Fragment>
            <LanguageContext.Consumer>
                {(language) => (
                    <table
                        id="main-table"
                        cellSpacing="0"
                        border="0"
                        width="650"
                        style={{
                            margin: "0",
                            padding: "0",
                            borderCollapse: "separate",
                            msoTableLspace: "0pt !important",
                            msoTableRspace: "0pt !important",
                            lineHeight: "100%",
                            wordBreak: "break-word",
                        }}
                    >
                        <tbody>
                            <tr
                                style={{
                                    borderSpacing: "0 !important",
                                    padding: "0 !important",
                                    margin: "0 !important",
                                }}
                            >
                                <td
                                    style={{
                                        margin: "0",
                                        padding: "0",
                                        msoTableLspace: "0pt !important",
                                        msoTableRspace: "0pt !important",
                                    }}
                                >
                                    <table
                                        cellSpacing="0"
                                        border="0"
                                        width="650"
                                        style={{
                                            msoTableLspace: "0pt !important",
                                            msoTableRspace: "0pt !important",
                                            background: "#fff",
                                            borderCollapse: "separate",
                                        }}
                                    >
                                        <tbody>
                                            <tr
                                                style={{
                                                    borderSpacing: "0 !important",
                                                    padding: "0 !important",
                                                    margin: "0 !important",
                                                }}
                                            >
                                                <td
                                                    valign="top"
                                                    width="650"
                                                    style={{
                                                        margin: "0",
                                                        padding: "0",
                                                        verticalAlign: "top",
                                                        msoTableLspace: "0pt !important",
                                                        msoTableRspace: "0pt !important",
                                                    }}
                                                >
                                                    <table
                                                        cellSpacing="0"
                                                        cellPadding="0"
                                                        border="0"
                                                        width="650"
                                                        style={{
                                                            msoTableLspace: "0pt !important",
                                                            msoTableRspace: "0pt !important",
                                                            background: "#fff",
                                                            borderCollapse: "separate",
                                                        }}
                                                    >
                                                        <tbody>
                                                            <tr
                                                                style={{
                                                                    borderSpacing: "0 !important",
                                                                    padding: "0 !important",
                                                                    margin: "0 !important",
                                                                }}
                                                            >
                                                                <td
                                                                    valign="top"
                                                                    width="650"
                                                                    style={{
                                                                        margin: "0",
                                                                        padding: "0",
                                                                        verticalAlign: "top",
                                                                        msoTableLspace: "0pt !important",
                                                                        msoTableRspace: "0pt !important",
                                                                    }}
                                                                >
                                                                    <table
                                                                        cellSpacing="10"
                                                                        cellPadding="0"
                                                                        border="0"
                                                                        width="650"
                                                                        style={{
                                                                            msoTableLspace: "0pt !important",
                                                                            msoTableRspace: "0pt !important",
                                                                            borderCollapse: "separate",
                                                                        }}
                                                                    >
                                                                        <tbody>
                                                                            <tr
                                                                                style={{
                                                                                    borderSpacing: "0 !important",
                                                                                    padding: "0 !important",
                                                                                    margin: "0 !important",
                                                                                }}
                                                                            >
                                                                                {features.profileImageUrl || features.awardImageUrl ? (
                                                                                    <td
                                                                                        valign="top"
                                                                                        width="81"
                                                                                        style={{
                                                                                            margin: "0",
                                                                                            padding: "0",
                                                                                            verticalAlign: "top",
                                                                                            msoTableLspace: "0pt !important",
                                                                                            msoTableRspace: "0pt !important",
                                                                                        }}
                                                                                    >
                                                                                        {features.profileImageUrl ? (
                                                                                            <p
                                                                                                className="MsoNormal"
                                                                                                style={{
                                                                                                    margin: "0 0 5px 0",
                                                                                                    textAlign: "center",
                                                                                                    msoStyleUnhide: "no",
                                                                                                    msoStyleQformat: "yes",
                                                                                                    msoStyleParent: "",
                                                                                                    msoPagination: "widow-orphan",
                                                                                                    msoBidiThemeFont: "minor-bidi",
                                                                                                }}
                                                                                            >
                                                                                                <img
                                                                                                    src={features.profileImageUrl}
                                                                                                    width="60"
                                                                                                    border="0"
                                                                                                    alt={features.fullName ? features.fullName : language.locale === "fr" ? "Nommé" : "Full Name"}
                                                                                                    style={{
                                                                                                        margin: "0 auto",
                                                                                                        display: "block",
                                                                                                        border: "none",
                                                                                                        outline: "none",
                                                                                                        textDecoration: "none",
                                                                                                        MsInterpolationMode: "bicubic",
                                                                                                        verticalAlign: "middle",
                                                                                                    }}
                                                                                                />
                                                                                            </p>
                                                                                        ) : null}
                                                                                        {features.awardImageUrl && (
                                                                                            <p
                                                                                                className="MsoNormal"
                                                                                                style={{
                                                                                                    margin: "5px 0 0 0",
                                                                                                    textAlign: "center",
                                                                                                    msoStyleUnhide: "no",
                                                                                                    msoStyleQformat: "yes",
                                                                                                    msoStyleParent: "",
                                                                                                    msoPagination: "widow-orphan",
                                                                                                    msoBidiThemeFont: "minor-bidi",
                                                                                                }}
                                                                                            >
                                                                                                <img
                                                                                                    src={features.awardImageUrl}
                                                                                                    width="60"
                                                                                                    border="0"
                                                                                                    alt={language.locale === "fr" ? "Prix du Courtier" : "Broker Award"}
                                                                                                    style={{
                                                                                                        display: "block",
                                                                                                        margin: "0 auto",
                                                                                                        border: "none",
                                                                                                        outline: "none",
                                                                                                        textDecoration: "none",
                                                                                                        MsInterpolationMode: "bicubic",
                                                                                                        verticalAlign: "middle",
                                                                                                    }}
                                                                                                />
                                                                                            </p>
                                                                                        )}
                                                                                    </td>
                                                                                ) : null}

                                                                                <td
                                                                                    valign="top"
                                                                                    width="210"
                                                                                    style={{
                                                                                        margin: "0",
                                                                                        padding: "0",
                                                                                        verticalAlign: "top",
                                                                                        borderRight: "1px solid #dde6e9",
                                                                                        msoTableLspace: "0pt !important",
                                                                                        msoTableRspace: "0pt !important",
                                                                                    }}
                                                                                >
                                                                                    <p
                                                                                        className="MsoNormal"
                                                                                        style={{
                                                                                            fontSize: "11pt",
                                                                                            fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                                            color: "#005baa",
                                                                                            textTransform: "capitalize",
                                                                                            margin: "2px 0 5px 0",
                                                                                            fontWeight: "900",
                                                                                            msoStyleUnhide: "no",
                                                                                            msoStyleQformat: "yes",
                                                                                            msoStyleParent: "",
                                                                                            msoPagination: "widow-orphan",
                                                                                            msoBidiThemeFont: "minor-bidi",
                                                                                        }}
                                                                                    >
                                                                                        <b>{features.fullName ? features.fullName : language.locale === "fr" ? "Nommé" : "Full Name"}</b>
                                                                                        <br />
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: "9pt",
                                                                                                fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                                                color: "#1f1f1f",
                                                                                                margin: "0",
                                                                                                fontWeight: "normal",
                                                                                            }}
                                                                                        >
                                                                                            {features.jobTitle ? features.jobTitle : language.locale === "fr" ? "Titre du Poste" : "Position Title"}
                                                                                        </span>
                                                                                        {features.licenseNumber && (
                                                                                            <Fragment>
                                                                                                <br />
                                                                                                <b
                                                                                                    style={{
                                                                                                        fontSize: "9pt",
                                                                                                        fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                                                        color: "#1f1f1f",
                                                                                                        margin: "0",
                                                                                                        fontWeight: "900",
                                                                                                    }}
                                                                                                >
                                                                                                    {language.locale === "fr" ? "Permis" : "License"} {features.licenseNumber}
                                                                                                </b>
                                                                                            </Fragment>
                                                                                        )}
                                                                                    </p>

                                                                                    <p
                                                                                        className="MsoNormal"
                                                                                        style={{
                                                                                            fontSize: "8pt",
                                                                                            fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                                            color: "#1f1f1f",
                                                                                            margin: "5px 0 0 0",
                                                                                            msoStyleUnhide: "no",
                                                                                            msoStyleQformat: "yes",
                                                                                            msoStyleParent: "",
                                                                                            msoPagination: "widow-orphan",
                                                                                            msoBidiThemeFont: "minor-bidi",
                                                                                        }}
                                                                                    >
                                                                                        {features.phone && (
                                                                                            <Fragment>
                                                                                                <b style={{ fontWeight: "bold" }}>{language.locale === "fr" ? "T" : "P"}: </b>
                                                                                                <a
                                                                                                    href={"tel:" + features.phone}
                                                                                                    rel="noopener noreferrer"
                                                                                                    style={{
                                                                                                        color: "#205CCA",
                                                                                                        textDecoration: "none",
                                                                                                    }}
                                                                                                >
                                                                                                    {features.phone}
                                                                                                </a>
                                                                                                <br />
                                                                                            </Fragment>
                                                                                        )}

                                                                                        {features.mobile && (
                                                                                            <Fragment>
                                                                                                <b style={{ fontWeight: "bold" }}>{language.locale === "fr" ? "C" : "M"}: </b>
                                                                                                <a
                                                                                                    href={"tel:" + features.mobile}
                                                                                                    rel="noopener noreferrer"
                                                                                                    style={{
                                                                                                        color: "#205CCA",
                                                                                                        textDecoration: "none",
                                                                                                    }}
                                                                                                >
                                                                                                    {features.mobile}
                                                                                                </a>
                                                                                                <br />
                                                                                            </Fragment>
                                                                                        )}

                                                                                        {features.fax && (
                                                                                            <Fragment>
                                                                                                <b style={{ fontWeight: "bold" }}>{language.locale === "fr" ? "Téléc" : "F"}: </b>
                                                                                                <a
                                                                                                    href={"tel:" + features.fax}
                                                                                                    rel="noopener noreferrer"
                                                                                                    style={{
                                                                                                        color: "#205CCA",
                                                                                                        textDecoration: "none",
                                                                                                    }}
                                                                                                >
                                                                                                    {features.fax}
                                                                                                </a>
                                                                                            </Fragment>
                                                                                        )}
                                                                                    </p>
                                                                                </td>

                                                                                <td
                                                                                    valign="top"
                                                                                    width="230"
                                                                                    style={{
                                                                                        margin: "0",
                                                                                        padding: "0",
                                                                                        verticalAlign: "top",
                                                                                        msoTableLspace: "0pt !important",
                                                                                        msoTableRspace: "0pt !important",
                                                                                    }}
                                                                                >
                                                                                    <p
                                                                                        className="MsoNormal"
                                                                                        style={{
                                                                                            msoStyleUnhide: "no",
                                                                                            msoStyleQformat: "yes",
                                                                                            msoStyleParent: "",
                                                                                            margin: "0cm",
                                                                                            marginBottom: ".0001pt",
                                                                                            msoPagination: "widow-orphan",
                                                                                            msoBidiThemeFont: "minor-bidi",
                                                                                        }}
                                                                                    >
                                                                                        {features.subTeamName && (
                                                                                            <Fragment>
                                                                                                <b
                                                                                                    style={{
                                                                                                        fontSize: "11pt",
                                                                                                        fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                                                        color: "#005baa",
                                                                                                        textTransform: "capitalize",
                                                                                                        margin: "2px 0 5px 0",
                                                                                                        fontWeight: "900",
                                                                                                    }}
                                                                                                >
                                                                                                    {features.subTeamName}
                                                                                                </b>
                                                                                                <br />
                                                                                            </Fragment>
                                                                                        )}

                                                                                        {template.features.office && (
                                                                                            <span
                                                                                                style={{
                                                                                                    fontSize: "9pt",
                                                                                                    fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                                                    color: "#1f1f1f",
                                                                                                    margin: "0",
                                                                                                    fontWeight: "normal",
                                                                                                }}
                                                                                            >
                                                                                                {features.office.addressLineTwo && features.office.addressLineTwo + "-"}
                                                                                                {features.office.streetAddress}
                                                                                                <br />
                                                                                                {features.office.city}, {features.office.provinceCode}, {features.office.postalCode}
                                                                                            </span>
                                                                                        )}
                                                                                    </p>

                                                                                    <p
                                                                                        className="MsoNormal"
                                                                                        style={{
                                                                                            fontSize: "8pt",
                                                                                            fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                                            color: "#1f1f1f",
                                                                                            margin: "5px 0 0 0",
                                                                                            msoStyleUnhide: "no",
                                                                                            msoStyleQformat: "yes",
                                                                                            msoStyleParent: "",
                                                                                            msoPagination: "widow-orphan",
                                                                                            msoBidiThemeFont: "minor-bidi",
                                                                                        }}
                                                                                    >
                                                                                        {features.email && (
                                                                                            <Fragment>
                                                                                                <a
                                                                                                    href={"mailto:" + features.email}
                                                                                                    rel="noopener noreferrer"
                                                                                                    style={{
                                                                                                        color: "#205CCA",
                                                                                                        textDecoration: "none",
                                                                                                    }}
                                                                                                >
                                                                                                    {features.email}
                                                                                                </a>
                                                                                                <br />
                                                                                            </Fragment>
                                                                                        )}

                                                                                        {features.website && (
                                                                                            <Fragment>
                                                                                                <a
                                                                                                    href={"https://" + features.website}
                                                                                                    rel="noopener noreferrer"
                                                                                                    style={{
                                                                                                        color: "#205CCA",
                                                                                                        textDecoration: "none",
                                                                                                    }}
                                                                                                    target="_blank"
                                                                                                >
                                                                                                    {features.website}
                                                                                                </a>
                                                                                                <br />
                                                                                            </Fragment>
                                                                                        )}

                                                                                        {user.info.franchise.website && (
                                                                                            <Fragment>
                                                                                                <a
                                                                                                    href={"https://" + user.info.franchise.website}
                                                                                                    target="_blank"
                                                                                                    rel="noopener noreferrer"
                                                                                                    style={{
                                                                                                        color: "#205CCA",
                                                                                                        textDecoration: "none",
                                                                                                    }}
                                                                                                >
                                                                                                    {user.info.franchise.website}
                                                                                                </a>
                                                                                                <br />
                                                                                            </Fragment>
                                                                                        )}
                                                                                    </p>
                                                                                </td>

                                                                                <td
                                                                                    valign="top"
                                                                                    width="81"
                                                                                    style={{
                                                                                        margin: "0",
                                                                                        padding: "0",
                                                                                        verticalAlign: "top",
                                                                                        msoTableLspace: "0pt !important",
                                                                                        msoTableRspace: "0pt !important",
                                                                                    }}
                                                                                >
                                                                                    {features.subTeamLogoUrl && (
                                                                                        <p
                                                                                            className="MsoNormal"
                                                                                            style={{
                                                                                                margin: "0 0 5px 0",
                                                                                                textAlign: "center",
                                                                                                msoStyleUnhide: "no",
                                                                                                msoStyleQformat: "yes",
                                                                                                msoStyleParent: "",
                                                                                                msoPagination: "widow-orphan",
                                                                                                msoBidiThemeFont: "minor-bidi",
                                                                                            }}
                                                                                        >
                                                                                            <img
                                                                                                src={features.subTeamLogoUrl}
                                                                                                width="60"
                                                                                                height="60"
                                                                                                border="0"
                                                                                                alt={features.subTeamName}
                                                                                                style={{
                                                                                                    margin: "0 auto",
                                                                                                    display: "block",
                                                                                                    border: "none",
                                                                                                    outline: "none",
                                                                                                    textDecoration: "none",
                                                                                                    MsInterpolationMode: "bicubic",
                                                                                                    verticalAlign: "middle",
                                                                                                }}
                                                                                            />
                                                                                        </p>
                                                                                    )}

                                                                                    {(features.applicationUrl || features.mortgageToolboxUrl) && (
                                                                                        <p
                                                                                            className="MsoNormal"
                                                                                            style={{
                                                                                                textAlign: "center",
                                                                                                msoStyleUnhide: "no",
                                                                                                msoStyleQformat: "yes",
                                                                                                msoStyleParent: "",
                                                                                                margin: "0cm",
                                                                                                marginBottom: ".0001pt",
                                                                                                msoPagination: "widow-orphan",
                                                                                                msoBidiThemeFont: "minor-bidi",
                                                                                            }}
                                                                                        >
                                                                                            {features.applicationUrl && (
                                                                                                <a
                                                                                                    href={features.applicationUrl}
                                                                                                    target="_blank"
                                                                                                    rel="noopener noreferrer"
                                                                                                    style={{
                                                                                                        padding: "0",
                                                                                                    }}
                                                                                                >
                                                                                                    <img
                                                                                                        src={
                                                                                                            language.locale === "fr"
                                                                                                                ? "https://centralhost.dominionlending.ca/signatures/broker/template-2/apply-now-btn-fr.png"
                                                                                                                : "https://centralhost.dominionlending.ca/signatures/broker/template-2/apply-now-btn-en.png"
                                                                                                        }
                                                                                                        width="80"
                                                                                                        height="25"
                                                                                                        border="0"
                                                                                                        alt={language.locale === "fr" ? "Présenter une Demande" : "Apply Now"}
                                                                                                        style={{
                                                                                                            margin: "0 auto",
                                                                                                            display: "block",
                                                                                                            border: "none",
                                                                                                            outline: "none",
                                                                                                            textDecoration: "none",
                                                                                                            MsInterpolationMode: "bicubic",
                                                                                                            verticalAlign: "middle",
                                                                                                        }}
                                                                                                    />
                                                                                                </a>
                                                                                            )}

                                                                                            {features.mortgageToolboxUrl && (
                                                                                                <a
                                                                                                    href={features.mortgageToolboxUrl}
                                                                                                    target="_blank"
                                                                                                    rel="noopener noreferrer"
                                                                                                    style={{
                                                                                                        padding: "0",
                                                                                                    }}
                                                                                                >
                                                                                                    <img
                                                                                                        src={
                                                                                                            language.locale === "fr"
                                                                                                                ? "https://centralhost.dominionlending.ca/signatures/broker/template-2/pre-qualified-btn-fr.png"
                                                                                                                : "https://centralhost.dominionlending.ca/signatures/broker/template-2/pre-qualified-btn-en.png"
                                                                                                        }
                                                                                                        width="80"
                                                                                                        height="25"
                                                                                                        border="0"
                                                                                                        alt={language.locale === "fr" ? "Obtenir une Préapprobation" : "Get Pre-qualified"}
                                                                                                        style={{
                                                                                                            margin: "0 auto",
                                                                                                            display: "block",
                                                                                                            border: "none",
                                                                                                            outline: "none",
                                                                                                            textDecoration: "none",
                                                                                                            MsInterpolationMode: "bicubic",
                                                                                                            verticalAlign: "middle",
                                                                                                        }}
                                                                                                    />
                                                                                                </a>
                                                                                            )}
                                                                                        </p>
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>

                            <tr
                                bgcolor="#005baa"
                                style={{
                                    borderSpacing: "0 !important",
                                    padding: "0 !important",
                                    margin: "0 !important",
                                    background: "#005baa",
                                }}
                            >
                                <td
                                    width="650"
                                    style={{
                                        margin: "0",
                                        padding: "0",
                                        msoTableLspace: "0pt !important",
                                        msoTableRspace: "0pt !important",
                                    }}
                                >
                                    <table
                                        cellSpacing="10"
                                        cellPadding="0"
                                        border="0"
                                        width="650"
                                        style={{
                                            msoTableLspace: "0pt !important",
                                            msoTableRspace: "0pt !important",
                                            borderCollapse: "separate",
                                        }}
                                    >
                                        <tbody>
                                            <tr
                                                style={{
                                                    borderSpacing: "0 !important",
                                                    padding: "0 !important",
                                                    margin: "0 !important",
                                                }}
                                            >
                                                <td
                                                    width="85"
                                                    style={{
                                                        margin: "0",
                                                        padding: "0",
                                                        msoTableLspace: "0pt !important",
                                                        msoTableRspace: "0pt !important",
                                                    }}
                                                >
                                                    <a
                                                        href={language.locale === "fr" ? "https://www.hypothecairesdominion.ca/" : "https://dominionlending.ca/"}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        style={{ padding: "0" }}
                                                    >
                                                        <img
                                                            src={
                                                                language.locale === "fr"
                                                                    ? "https://centralhost.dominionlending.ca/signatures/broker/template-2/dlc-logo-fr.png"
                                                                    : "https://centralhost.dominionlending.ca/signatures/broker/template-2/dlc-logo-en.png"
                                                            }
                                                            width="80"
                                                            height="39"
                                                            border="0"
                                                            alt={language.locale === "fr" ? "Centres Hypothécaires Dominion" : "Dominion Lending Centres"}
                                                            style={{
                                                                margin: "0 auto",
                                                                display: "block",
                                                                border: "none",
                                                                outline: "none",
                                                                textDecoration: "none",
                                                                MsInterpolationMode: "bicubic",
                                                                verticalAlign: "middle",
                                                            }}
                                                        />
                                                    </a>
                                                </td>

                                                <td
                                                    width="370"
                                                    style={{
                                                        margin: "0",
                                                        padding: "0",
                                                        msoTableLspace: "0pt !important",
                                                        msoTableRspace: "0pt !important",
                                                    }}
                                                >
                                                    <p
                                                        className="MsoNormal"
                                                        style={{
                                                            msoStyleUnhide: "no",
                                                            msoStyleQformat: "yes",
                                                            msoStyleParent: "",
                                                            margin: "0cm",
                                                            marginBottom: ".0001pt",
                                                            msoPagination: "widow-orphan",
                                                            msoBidiThemeFont: "minor-bidi",
                                                        }}
                                                    >
                                                        {user.info.franchise.name && (
                                                            <Fragment>
                                                                <b
                                                                    style={{
                                                                        fontSize: "10pt",
                                                                        fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                        color: "#fff",
                                                                        textTransform: "capitalize",
                                                                        margin: "2px 0 0 0",
                                                                        fontWeight: "900",
                                                                    }}
                                                                >
                                                                    {user.info.franchise.name}
                                                                </b>
                                                                <br />
                                                            </Fragment>
                                                        )}

                                                        <i
                                                            style={{
                                                                fontSize: "8pt",
                                                                fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                color: "#fff",
                                                                margin: "0",
                                                                fontWeight: "200",
                                                            }}
                                                        >
                                                            {language.locale === "fr" ? "Chaque franchise est autonome et indépendante" : "Independently Owned & Operated"}
                                                        </i>

                                                        {user.info.franchise.licenseNumber && (
                                                            <Fragment>
                                                                <br />
                                                                <b
                                                                    style={{
                                                                        fontSize: "8pt",
                                                                        fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                                        color: "#fff",
                                                                        margin: "0",
                                                                        fontWeight: "900",
                                                                    }}
                                                                >
                                                                    {user.info.franchise.licenseNumber}
                                                                </b>
                                                            </Fragment>
                                                        )}
                                                    </p>
                                                </td>

                                                <td
                                                    width="175"
                                                    style={{
                                                        margin: "0",
                                                        padding: "0",
                                                        msoTableLspace: "0pt !important",
                                                        msoTableRspace: "0pt !important",
                                                    }}
                                                >
                                                    <table
                                                        cellSpacing="0"
                                                        cellPadding="0"
                                                        border="0"
                                                        width="175"
                                                        style={{
                                                            msoTableLspace: "0pt !important",
                                                            msoTableRspace: "0pt !important",
                                                            borderCollapse: "separate",
                                                        }}
                                                    >
                                                        <tbody>
                                                            <tr
                                                                style={{
                                                                    borderSpacing: "0 !important",
                                                                    padding: "0 !important",
                                                                    margin: "0 !important",
                                                                }}
                                                            >
                                                                <td style={{ width: "100%" }}></td>
                                                                {["facebook", "twitter", "instagram", "linkedin", "youtube", "pinterest"].map(
                                                                    (socialMedia) =>
                                                                        features.socialMediaUrls[socialMedia] && (
                                                                            <td
                                                                                key={socialMedia}
                                                                                width="28"
                                                                                align="right"
                                                                                style={{
                                                                                    margin: "0",
                                                                                    padding: "0",
                                                                                    msoTableLspace: "0pt !important",
                                                                                    msoTableRspace: "0pt !important",
                                                                                }}
                                                                            >
                                                                                <a
                                                                                    href={features.socialMediaUrls[socialMedia]}
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                    style={{
                                                                                        padding: "0",
                                                                                    }}
                                                                                >
                                                                                    <img
                                                                                        src={"https://centralhost.dominionlending.ca/signatures/broker/template-2/" + socialMedia + ".png"}
                                                                                        width="28"
                                                                                        height="25"
                                                                                        border="0"
                                                                                        alt={socialMedia}
                                                                                        style={{
                                                                                            display: "block",
                                                                                            border: "none",
                                                                                            outline: "none",
                                                                                            textDecoration: "none",
                                                                                            MsInterpolationMode: "bicubic",
                                                                                            verticalAlign: "middle",
                                                                                        }}
                                                                                    />
                                                                                </a>
                                                                            </td>
                                                                        )
                                                                )}
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>

                            <tr
                                style={{
                                    borderSpacing: "0 !important",
                                    padding: "0 !important",
                                    margin: "0 !important",
                                }}
                            >
                                <td
                                    width="650"
                                    style={{
                                        margin: "0",
                                        padding: "0",
                                        msoTableLspace: "0pt !important",
                                        msoTableRspace: "0pt !important",
                                    }}
                                >
                                    <table
                                        cellSpacing="5"
                                        cellPadding="0"
                                        border="0"
                                        width="650"
                                        bgcolor="#F6F8F9"
                                        style={{
                                            msoTableLspace: "0pt !important",
                                            msoTableRspace: "0pt !important",
                                            background: "#F6F8F9",
                                            borderCollapse: "collapse",
                                            borderSpacing: "0",
                                            border: "none",
                                        }}
                                    >
                                        <tbody>
                                            {features.bannerUrl ? (
                                                <tr
                                                    style={{
                                                        padding: "0",
                                                        display: "block",
                                                        whiteSpace: "nowrap",
                                                    }}
                                                >
                                                    <td
                                                        valign="left"
                                                        width="650"
                                                        style={{
                                                            padding: "0",
                                                            msoTableLspace: "0pt !important",
                                                            msoTableRspace: "0pt !important",
                                                        }}
                                                    >
                                                        <a
                                                            href={features.customBannerUrl}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            style={{
                                                                textDecoration: "none",
                                                            }}
                                                        >
                                                            <img
                                                                src={features.bannerUrl}
                                                                width="650"
                                                                border="0"
                                                                alt={language.locale === "fr" ? "Bannière Personnalisée" : "Custom Banner"}
                                                                style={{
                                                                    display: "block",
                                                                    border: "none",
                                                                    outline: "none",
                                                                    textDecoration: "none",
                                                                    MsInterpolationMode: "bicubic",
                                                                    verticalAlign: "middle",
                                                                }}
                                                            />
                                                        </a>
                                                    </td>
                                                </tr>
                                            ) : null}
                                            <tr
                                                style={{
                                                    borderSpacing: "0 !important",
                                                    padding: "0 !important",
                                                    margin: "0 !important",
                                                }}
                                            >
                                                <td
                                                    width="650"
                                                    style={{
                                                        fontSize: "8pt",
                                                        fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                        color: "#1f1f1f",
                                                        fontWeight: "400",
                                                        msoTableLspace: "0pt !important",
                                                        msoTableRspace: "0pt !important",
                                                    }}
                                                    valign="left"
                                                >
                                                    <p
                                                        className="MsoNormal"
                                                        style={{
                                                            margin: "10px",
                                                            fontSize: "8pt",
                                                            fontFamily: "Arial, 'Helvetica Neue', Helvetica, 'sans-serif'",
                                                            color: "#1f1f1f",
                                                            fontWeight: "400",
                                                            lineHeight: "10pt",
                                                        }}
                                                    >
                                                        {features.confidentialityWarning ? (
                                                            <Fragment>
                                                                <b>{language.locale === "fr" ? "Avertissement : " : "Confidentiality Warning: "}</b>
                                                                {features.confidentialityWarning}{" "}
                                                            </Fragment>
                                                        ) : null}
                                                        {language.locale === "fr"
                                                            ? "À noter : Cet e-mail a été envoyé après la Canadienne législation antispam directives, si vous souhaitez ne plus recevoir la correspondance électronique à partir de cette adresse e-mail, veuillez cliquer ici pour"
                                                            : "Please Note: This email was sent following the Canadian Anti-Spam Legislation guidelines. If you wish to no longer receive electronic correspondence from this email address, please confirm by clicking here to"}{" "}
                                                        <a
                                                            href={"mailto:" + features.email + "?subject=unsubscribe"}
                                                            rel="noopener noreferrer"
                                                            style={{
                                                                color: "#205CCA",
                                                                textDecoration: "none",
                                                                padding: "0",
                                                            }}
                                                        >
                                                            {language.locale === "fr" ? "annuler un abonnement" : "unsubscribe"}
                                                        </a>
                                                        .
                                                    </p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                )}
            </LanguageContext.Consumer>
        </Fragment>
    );
};
