import React from "react";

export const MobileHeader = () => {
    return (
        <div id="mobile-header" className="d-block d-sm-block d-md-block d-lg-none">
            <div id="company-logo"></div>
        </div>
    );
};

export default MobileHeader;
