import axios from "axios";

/**
 * Retrieve the members (users/brokers) of given office or franchise and update the User context.
 */
export function updateMembersRequest(entityId, entityType, user, updateUser) {
    let url = process.env.REACT_APP_BACKEND_URL + "/signature/";
    url += (entityType === "office" ? "office-users/" : "franchise-users/") + entityId;
    axios
        .get(url)
        .then((response) => {
            let memberData = initializeMemberData(response.data, user.lang);

            user.info.members = memberData;
            updateUser(user);
        })
        .catch((error) => console.log(error));
}

function initializeMemberData(members, lang) {
    for (var key in members) {
        members[key].applicationUrl = "https://secure.dominionlending.ca/?app=" + members[key].brokerLegacyId + "&lang=" + lang;
        members[key].mortgageToolboxUrl = "https://dlcapp.ca/id/" + members[key].legacyId + "?lang=" + lang;
    }

    return members;
}
