import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";

//My Components
import HowToModal from "./utilities/HowToModal";
import { CopySourceCode } from "./utilities/HelperFunctions";
import { CopyAsSelect, DownloadHtml } from "./utilities/HelperFunctions";
import UserContext from "../contexts/UserContext";
import TemplateContext from "../contexts/TemplateContext";
import { TemplateTypes } from "./utilities/TemplateTypes";

class EmailSignatureDemo extends Component {
    static contextType = TemplateContext;

    constructor(props) {
        super(props);
        this.state = {
            toggle: true,
            errorMsg: "",
            modal: false,
        };
        this.handleClick = this.handleClick.bind(this);
        this.howToModalClick = this.howToModalClick.bind(this);
        this.saveSignatureTemplate = this.saveSignatureTemplate.bind(this);
    }

    handleClick() {
        this.setState((state) => ({
            toggle: !state.toggle,
        }));
    }

    howToModalClick() {
        this.setState((state) => ({
            modal: !state.modal,
        }));
    }

    copySourceCodeClick() {
        CopySourceCode("email-signature-demo");
        toast.success(<FormattedMessage id="emailSignatureDemo.copySoruceCodeNotificationSuccess" defaultMessage="Source code copied to clipboard." />, { className: "notification" });
    }

    copySelectClick() {
        CopyAsSelect("email-signature-demo");
        toast.success(<FormattedMessage id="emailSignatureDemo.copySoruceNotificationSuccess" defaultMessage="Copied source to clipboard." />, { className: "notification" });
    }

    downloadHtmlClick() {
        DownloadHtml("email-signature-demo");
        toast.success(<FormattedMessage id="emailSignatureDemo.downloadHtmlNotificationSuccess" defaultMessage="Email signature downloaded." />, { className: "notification" });
    }

    saveSignatureTemplate(user, updateUser) {
        this.setState({ errorMsg: "" });
        let { template, updateTemplate } = this.context;
        let formData = new FormData();
        const toastSave = toast.loading("Saving...", { className: "notification" });
        formData.append("signatureFeatures", JSON.stringify(template.features));
        let url = process.env.REACT_APP_BACKEND_URL + "/signature/template";
        if (template.id) {
            formData.append("id", template.id);
            url += "/update";
        } else {
            url += "/create";
            formData.append("signerId", user.legacyId);
            formData.append("signerType", user.type);
        }

        axios({
            url: url,
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            data: formData,
            isLoading: toastSave,
        })
            .then((response) => {
                if (response.data.error) {
                    let errors = [];
                    for (const field in response.data.error) {
                        errors.push(response.data.error[field]);
                    }
                    toast.error(errors.join, {
                        id: toastSave,
                        className: "notification",
                        duration: 6000,
                    });
                } else {
                    const newTemplate = response.data;
                    template.id = newTemplate.id;
                    updateTemplate(template);
                    user.info.signatureTemplates[newTemplate.id] = newTemplate;
                    updateUser(user);
                    toast.success(<FormattedMessage id="emailSignatureDemo.saveTemplateNotificationSuccess" defaultMessage="Template was saved successfully!" />, {
                        id: toastSave,
                        className: "notification",
                        duration: 6000,
                    });
                }
            })
            .catch((error) => console.log(error));
    }

    render() {
        let { template } = this.context;
        const TemplateType = TemplateTypes[template.features.type];
        return (
            <UserContext.Consumer>
                {({ user, updateUser }) => (
                    <div id="email-signature-container" className="mx-auto">
                        <div id="email-signature-demo" className="mt-3">
                            <TemplateType />
                        </div>
                        <div id="signature-options" className="mt-4">
                            <Row>
                                <Col sm={{ span: "10", offset: "1" }}>
                                    <Row className="justify-content-center">
                                        <Col sm="3" className="no-gutters">
                                            <button onClick={this.copySelectClick}>
                                                <FormattedMessage id="emailSignatureDemo.selectButton" defaultMessage="Copy as Select" />
                                            </button>
                                        </Col>

                                        <Col sm="3" className="no-gutters d-none d-sm-none d-md-block">
                                            <button onClick={this.downloadHtmlClick}>
                                                <FormattedMessage id="emailSignatureDemo.downloadButton" defaultMessage="Download HTML" />
                                            </button>
                                        </Col>

                                        <Col sm="3" className="no-gutters">
                                            <button onClick={this.copySourceCodeClick}>
                                                <FormattedMessage id="emailSignatureDemo.sourceButton" defaultMessage="Copy Source Code" />
                                            </button>
                                        </Col>

                                        {(user.type === "franchise-user" || user.type === "broker-user") && (
                                            <Col sm="3" className="no-gutters">
                                                <button onClick={this.saveSignatureTemplate.bind(this, user, updateUser)}>
                                                    <FormattedMessage id="emailSignatureDemo.saveButton" defaultMessage="Save Template" />
                                                </button>
                                            </Col>
                                        )}
                                    </Row>
                                </Col>
                                <Col sm={{ span: "10", offset: "1" }}>
                                    <div
                                        ref={(div) => {
                                            this.saveTemplateMessageDiv = div;
                                        }}
                                    ></div>
                                </Col>
                            </Row>
                            <HowToModal show={this.state.modal ? true : false} />
                            <Toaster
                                position="bottom-center"
                                reverseOrder={false}
                                toastOptions={{
                                    style: {
                                        padding: "15px",
                                    },
                                }}
                            />
                        </div>

                        <div id="mobile-circle-menu" className={this.state.toggle ? null : "active"}>
                            <button className="floating-btn d-block text-center" onClick={this.handleClick}>
                                <i className="fa fa-plus" />
                            </button>

                            <div className="items-wrapper">
                                <button onClick={this.copySourceCodeClick} className="menu-item fas fa-file-code" />
                                <button onClick={this.copySelectClick} className="menu-item fas fa-copy" />
                                {(user.type === "franchise-user" || user.type === "broker-user") && (
                                    <button onClick={this.saveSignatureTemplate.bind(this, user, updateUser)} className="menu-item fas fa-save" />
                                )}
                                <button id={this.state.modal ? "true" : "false"} onClick={this.howToModalClick} className="menu-item fas fa-question-square" />
                            </div>
                        </div>
                    </div>
                )}
            </UserContext.Consumer>
        );
    }
}

export default EmailSignatureDemo;
