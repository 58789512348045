import React, { Component, Fragment } from "react";
import { Row, Col } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl";
import { Formik } from "formik";
import * as Yup from "yup";

//My Components
import UserContext from "../../../contexts/UserContext";
import TemplateContext from "../../../contexts/TemplateContext";
import HelperToolTip from "../../utilities/HelperToolTip";

class FranchiseInfoForm extends Component {
    static contextType = TemplateContext;

    constructor(props) {
        super(props);
        this.handleErrorChange = this.handleErrorChange.bind(this);
    }

    handleFeatureChange(e) {
        let { template, updateTemplate } = this.context;
        template.features[e.target.name] = e.target.value;
        updateTemplate(template);
    }

    handleErrorChange(e) {
        let { template, updateTemplate } = this.context;
        template.features[e.target.name] = "";
        updateTemplate(template);
    }

    render() {
        let { template } = this.context;
        const features = template.features;
        const { intl } = this.props;
        return (
            <UserContext.Consumer>
                {({ user }) => (
                    <Fragment>
                        <Row className="sub-header">
                            <Col>
                                <FormattedMessage id="franchiseInfoForm.franchiseInfoSubHeader" defaultMessage="Franchise Information" />
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Formik
                                    initialValues={{
                                        name: user.info.name ? user.info.name : "",
                                        license: user.info.licenseNumber ? user.info.licenseNumber : "",
                                        website: user.info.website ? user.info.website : "",
                                    }}
                                    enableReinitialize={true}
                                    validationSchema={Yup.object().shape({
                                        website: Yup.string()
                                            .matches(
                                                /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                                                intl.formatMessage({
                                                    id: "contactInfoForm.websiteValidation",
                                                    defaultMessage: "Invalid website url",
                                                })
                                            )
                                            .notRequired(),
                                    })}
                                >
                                    {(props) => {
                                        const { values, touched, errors, handleChange, handleBlur, handleSubmit, isSubmitting } = props;
                                        return (
                                            <form className="mb-4" onSubmit={handleSubmit}>
                                                <label className="mt-2" htmlFor="franchise-name">
                                                    <FormattedMessage id="franchiseInfoForm.franchiseNameLabel" defaultMessage="Franchise Name" />
                                                </label>
                                                <textarea id="franchise-name" type="text" readOnly={true} disabled={isSubmitting} name="name" value={values.name} rows="2" cols="30"></textarea>

                                                {(features.office.provinceCode === "ON" || features.office.provinceCode === "SK") && (
                                                    <Fragment>
                                                        <label className="mt-2" htmlFor="franchise-license">
                                                            <FormattedMessage id="contactFranchiseInfoForm.licenseNameLabel" defaultMessage="License #" />
                                                            <HelperToolTip translationId="contactFranchiseInfoForm.licenseNameLabel" defaultMessage="License #">
                                                                <FormattedMessage id="helperToolTip.franchiseInfoFormLicense" defaultMessage="Please Note: This license cannot be edited on the email signature tool. If a change is required please contact support." />
                                                            </HelperToolTip>
                                                        </label>
                                                        <input id="franchise-license" type="text" name="license" maxLength="30" readOnly={true} value={values.license} />
                                                    </Fragment>
                                                )}

                                                <label className="mt-4" htmlFor="franchise-website">
                                                    <FormattedMessage id="contactFranchiseInfoForm.websiteLabel" defaultMessage="Website" />
                                                </label>
                                                <input
                                                    id="franchise-website"
                                                    type="text"
                                                    name="website"
                                                    maxLength="50"
                                                    value={values.website}
                                                    onChange={(e) => {
                                                        handleChange(e);
                                                        this.handleFeatureChange(e);
                                                    }}
                                                    onBlur={(e) => {
                                                        handleBlur(e);

                                                        if (errors.website) {
                                                            this.handleErrorChange(e);
                                                        }
                                                    }}
                                                    className={errors.website && touched.website ? "text-input error" : "text-input"}
                                                />
                                                {errors.website && touched.website && <div className="input-feedback">{errors.website}</div>}
                                            </form>
                                        );
                                    }}
                                </Formik>
                            </Col>
                        </Row>
                    </Fragment>
                )}
            </UserContext.Consumer>
        );
    }
}

export default injectIntl(FranchiseInfoForm);
