import React, { Component, Fragment } from "react";
import { Col, Row, Alert } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

//My Components
import Upload from "../Upload";
import TemplateContext from "../../contexts/TemplateContext";
import UserContext from "../../contexts/UserContext";
import { LanguageContext } from "../../contexts/LanguageProvider";
import FranchiseInfoForm from "../forms/franchise/FranchiseInfoForm";
import FranchiseAddressInfoForm from "../forms/franchise/FranchiseAddressInfoForm";

//Default Images
import DefaultFranchiseLogoEn from "../../images/franchise-logo-default.png";
import DefaultFranchiseLogoFr from "../../images/franchise-logo-default-fr.png";

class FranchiseInfoTab extends Component {
    static contextType = TemplateContext;

    constructor(props) {
        super(props);
        this.state = {
            toggle: false,
        };
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(user, language) {
        this.setState((state) => ({
            toggle: !state.toggle,
        }));
        let { template, updateTemplate } = this.context;

        if (this.state.toggle !== true) {
            if (language.locale === "fr") {
                template.features.logoUrl = DefaultFranchiseLogoFr;
            } else {
                template.features.logoUrl = DefaultFranchiseLogoEn;
            }
        } else {
            template.features.logoUrl = null;
        }
        updateTemplate(template);
    }

    render() {
        return (
            <UserContext.Consumer>
                {({ user, updateUser }) => (
                    <LanguageContext.Consumer>
                        {(language) => (
                            <Fragment>
                                <Row className="sub-header">
                                    <Col>
                                        <FormattedMessage id="imageForm.franchiseLogoSubHeader" defaultMessage="Franchise Logo" />
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                defaultChecked={this.state.toggle}
                                                onClick={(e) => {
                                                    this.handleClick(user, language);
                                                }}
                                            />
                                            <span className="slider round" />
                                        </label>
                                    </Col>
                                </Row>
                                <Row className={this.state.toggle ? null : "d-none"}>
                                    <Col>
                                        <Alert variant="info">
                                            <FormattedMessage id="helperToolTip.fileFormats" defaultMessage="Accepted file formats are JPG, JPEG, or PNG with an upload limit of 5MB." />
                                        </Alert>
                                        <Upload imageType="logoUrl" />
                                    </Col>
                                </Row>
                                <FranchiseInfoForm />
                                <FranchiseAddressInfoForm />
                            </Fragment>
                        )}
                    </LanguageContext.Consumer>
                )}
            </UserContext.Consumer>
        );
    }
}

export default FranchiseInfoTab;
