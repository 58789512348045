import { BrokerTemplateOne } from "../templates/broker/BrokerTemplateOne";
import { BrokerTemplateThree } from "../templates/broker/BrokerTemplateThree";
import { FranchiseTemplateOne } from "../templates/franchise/FranchiseTemplateOne";
import { FranchiseTemplateThree } from "../templates/franchise/FranchiseTemplateThree";
import { FranchiseTemplateFour } from "../templates/franchise/FranchiseTemplateFour";
import { HeadOfficeTemplate } from "../templates/head-office/HeadOfficeTemplate";
import { BrokerTemplateTwo } from "../templates/broker/BrokerTemplateTwo";
import { FranchiseTemplateTwo } from "../templates/franchise/FranchiseTemplateTwo";

import FranchiseTemplateOneImageEn from "../templates/franchise/images/franchise-template-1.svg";
import FranchiseTemplateTwoImageEn from "../templates/franchise/images/franchise-template-2.svg";
import FranchiseTemplateThreeImageEn from "../templates/franchise/images/franchise-template-1.svg";
import FranchiseTemplateFourImageEn from "../templates/franchise/images/franchise-template-4.svg";
import BrokerTemplateOneImageEn from "../templates/broker/images/broker-template-1.svg";
import BrokerTemplateTwoImageEn from "../templates/broker/images/broker-template-2.svg";
import BrokerTemplateThreeImageEn from "../templates/broker/images/broker-template-3.svg";

import FranchiseTemplateOneImageFr from "../templates/franchise/images/franchise-template-1-fr.svg";
import FranchiseTemplateTwoImageFr from "../templates/franchise/images/franchise-template-2-fr.svg";
import FranchiseTemplateThreeImageFr from "../templates/franchise/images/franchise-template-1-fr.svg";
import FranchiseTemplateFourImageFr from "../templates/franchise/images/franchise-template-4-fr.svg";
import BrokerTemplateOneImageFr from "../templates/broker/images/broker-template-1-fr.svg";
import BrokerTemplateTwoImageFr from "../templates/broker/images/broker-template-2-fr.svg";
import BrokerTemplateThreeImageFr from "../templates/broker/images/broker-template-3-fr.svg";

export const TemplateTypes = {
    FranchiseTemplateOne: FranchiseTemplateOne,
    FranchiseTemplateTwo: FranchiseTemplateTwo,
    FranchiseTemplateThree: FranchiseTemplateThree,
    FranchiseTemplateFour: FranchiseTemplateFour,
    BrokerTemplateOne: BrokerTemplateOne,
    BrokerTemplateTwo: BrokerTemplateTwo,
    BrokerTemplateThree: BrokerTemplateThree,
    HeadOfficeTemplate: HeadOfficeTemplate,
};

export const TemplateTypesImagesEn = {
    FranchiseTemplateOne: FranchiseTemplateOneImageEn,
    FranchiseTemplateTwo: FranchiseTemplateTwoImageEn,
    FranchiseTemplateThree: FranchiseTemplateThreeImageEn,
    FranchiseTemplateFour: FranchiseTemplateFourImageEn,
    BrokerTemplateOne: BrokerTemplateOneImageEn,
    BrokerTemplateTwo: BrokerTemplateTwoImageEn,
    BrokerTemplateThree: BrokerTemplateThreeImageEn,
    HeadOfficeTemplate: BrokerTemplateOneImageEn,
};

export const TemplateTypesImagesFr = {
    FranchiseTemplateOne: FranchiseTemplateOneImageFr,
    FranchiseTemplateTwo: FranchiseTemplateTwoImageFr,
    FranchiseTemplateThree: FranchiseTemplateThreeImageFr,
    FranchiseTemplateFour: FranchiseTemplateFourImageFr,
    BrokerTemplateOne: BrokerTemplateOneImageFr,
    BrokerTemplateTwo: BrokerTemplateTwoImageFr,
    BrokerTemplateThree: BrokerTemplateThreeImageFr,
    HeadOfficeTemplate: BrokerTemplateOneImageFr,
};
