import React, { Fragment, Component } from "react";
import { FormattedMessage } from "react-intl";

//My Components
import UserContext from "../../contexts/UserContext";
import TemplateContext from "../../contexts/TemplateContext";

//Default Images
import DefaultCustomBanner from "../../images/custom-banner-default.jpg";

class AddOn extends Component {
    static contextType = TemplateContext;

    constructor(props) {
        super(props);
        this.state = {
            toggle: true,
            clearData: true,
        };
        this.handleClick = this.handleClick.bind(this);
        this.clearDataClick = this.clearDataClick.bind(this);
    }
    handleClick(e) {
        this.setState((state) => ({
            toggle: !state.toggle,
        }));

        if (e.target.id === "custom-banner") {
            let { template, updateTemplate } = this.context;

            if (this.state.toggle === true && template.features.bannerUrl === "") {
                template.features.bannerUrl = DefaultCustomBanner;
            }
            updateTemplate(template);
        }
    }

    clearDataClick(e, user) {
        e.stopPropagation();
        //console.log(e.target.id);
        let targetId = e.target.id;
        let { template, updateTemplate } = this.context;
        this.setState((state) => ({
            clearData: !state.clearData,
        }));

        if (template.features[targetId] === template.features.colorScheme) {
            template.features[targetId] = { primary: "", secondary: "", headers: "", text: "", links: "" };
        } else if (template.features[targetId] === template.features.socialMediaUrls) {
            template.features[targetId] = { facebook: "", instagram: "", linkedin: "", pinterest: "", twitter: "", youtube: "" };
        } else {
            template.features[targetId] = "";
        }

        if (user.type === "franchise-user") {
            if ((template.member[targetId] = template.member.applicationUrl || template.member.mortgageToolboxUrl)) {
                template.member[targetId] = "";
            }
        }
        updateTemplate(template);
    }

    render() {
        const isToggleOn = this.state.toggle;
        return (
            <UserContext.Consumer>
                {({ user, updateUser }) => (
                    <Fragment>
                        <div id={this.props.containerId} className={isToggleOn ? "add-on" : "add-on active"} onClick={this.handleClick}>
                            <span>
                                <FormattedMessage id={this.props.translateId} defaultMessage={this.props.translateMessage} />
                            </span>

                            <div
                                id={this.props.id}
                                className={this.props.status !== "active" ? "status add" : "status delete"}
                                onClick={
                                    this.props.status !== "active"
                                        ? (e) => {
                                              e.stopPropagation();
                                              this.handleClick(e);
                                          }
                                        : (e) => {
                                              this.clearDataClick(e, user);
                                          }
                                }
                            >
                                {this.props.status !== "active" ? (
                                    <FormattedMessage id="addOnComponent.addLabel" defaultMessage="Add" />
                                ) : (
                                    <FormattedMessage id="addOnComponent.deleteLabel" defaultMessage="Delete" />
                                )}

                                <i className={this.props.status !== "active" ? "fas fa-plus-circle" : "fas fa-minus-circle"}></i>
                            </div>
                        </div>

                        <div className={isToggleOn ? "panel" : "panel active"}>{this.props.children}</div>
                    </Fragment>
                )}
            </UserContext.Consumer>
        );
    }
}

export default AddOn;
